import { Spinner } from '../../../components/common';
import { useCallSummaryReport } from '../../../hooks/useCallReporting';
import { CallReportFilters } from './common/call-report-constants';

interface CallSummaryProps {
  filters: CallReportFilters;
}

export const CallsSummary: React.FC<CallSummaryProps> = ({ filters }) => {
  const { data: callSummaryReport, isLoading } = useCallSummaryReport(filters);

  return (
    <div className="p-4 max-w-4xl">
      <h1 className="text-2xl font-bold mb-4">Call Summary Report</h1>
      <div className="overflow-x-auto">
        {isLoading ? (
          <Spinner />
        ) : (
          <table className="min-w-full bg-white border border-gray-200 font-mono">
            <thead>
              <tr className="bg-gray-100 text-left">
                <th className="py-2 px-4 border-b">Date</th>
                <th className="py-2 px-4 border-b">Calls</th>
                <th className="py-2 px-4 border-b">Successful</th>
                <th className="py-2 px-4 border-b">Failed</th>
              </tr>
            </thead>
            <tbody>
              {callSummaryReport?.data.map((summary, index) => (
                <tr key={index} className="hover:bg-gray-50 text-sm whitespace-nowrap">
                  <td className="w-1/6 py-2 px-4 border-b align-top">{summary.date}</td>
                  <td className="w-1/5 py-2 px-4 border-b align-top">
                    <div className="flex justify-between">
                      <p>Total:</p>
                      <p className="font-bold">{summary.total_calls}</p>
                    </div>
                    <div className="flex justify-between">
                      <p>Now:</p>
                      <p className="font-bold">{summary.total_now_calls}</p>
                    </div>
                    <div className="flex justify-between">
                      <p>Scheduled:</p>
                      <p className="font-bold">{summary.total_scheduled_calls}</p>
                    </div>
                  </td>
                  <td className="w-1/3 py-2 px-4 border-b align-top">
                    <div className="flex justify-between">
                      <p>Total Successful Calls:</p>
                      <p className="font-bold">{summary.total_successful_calls}</p>
                    </div>
                    <div className="flex justify-between">
                      <p>Average Minutes/Call:</p>
                      <p className="font-bold">{summary.successful_call_average_minutes}</p>
                    </div>
                  </td>
                  <td className="w-1/5 py-2 px-4 border-b align-top">
                    <div className="flex justify-between">
                      <p>Total Failures:</p>
                      <p className="font-bold">{summary.total_failed_calls}</p>
                    </div>
                    <div className="flex justify-between">
                      <p>Member Failed:</p>
                      <p className="font-bold">{summary.total_member_failed}</p>
                    </div>
                    <div className="flex justify-between">
                      <p>Listener Failed:</p>
                      <p className="font-bold">{summary.total_listener_failed}</p>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
