import { ROUTE_PATH } from './paths';
import NotifyMessage from '../components/account-login/notify-message';
import InvitationExpired from '../containers/onboarding/invitation-expired';
import InvitationRevoked from '../containers/onboarding/invitation-revoked';

export const tokenErrorRoutes = [
  {
    path: ROUTE_PATH.REQUESTNEWINVITE,
    component: InvitationExpired,
    id: 'Invitation Expired',
  },
];

export const publicRoutes = [
  {
    path: '/notify-message',
    component: NotifyMessage,
    id: 'Notify Message',
  },
  {
    path: ROUTE_PATH.INVITEREVOKE,
    component: InvitationRevoked,
    id: 'Invitation Revoked',
  },
];
