import { ClientSummary } from '../../hooks/useClients';
import { useState } from 'react';
import {
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  FieldErrors,
  UseFormWatch,
  UseFormReset,
  UseFormSetValue,
} from 'react-hook-form';
import Header from '../../components/common/global-top-bar';
import Modal from '../../components/common/modal';
import { CustomInput, Spinner } from '../../components/common';
import { useClientSummaryList } from '../../hooks/useClients';
import {
  TopSearch,
  useDeleteTopSearch,
  usePostTopSearch,
  useTopSearches,
  useUpdateTopSearch,
} from '../../hooks/useSearch';

interface TopSearchFormData {
  query_text: string;
  client_ids: number[];
}

const TopSearches: React.FC = () => {
  const { data: clients } = useClientSummaryList();
  const { data: topSearches, isLoading } = useTopSearches();

  const [selectedTopSearch, setSelectedTopSearch] = useState<TopSearch | null>(null);
  const [showCreateEditModal, setShowCreateEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<TopSearchFormData>({
    defaultValues: {
      query_text: '',
      client_ids: [],
    },
  });

  const openCreateModal = () => {
    setSelectedTopSearch(null);
    setShowCreateEditModal(true);
  };

  const openEditModal = (topSearch: TopSearch) => {
    setSelectedTopSearch(topSearch);
    setValue('query_text', topSearch.query_text);
    setValue('client_ids', topSearch.client_ids);
    setShowCreateEditModal(true);
  };

  const openDeleteModal = (search: TopSearch) => {
    setSelectedTopSearch(search);
    setShowDeleteModal(true);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header heading="Top Searches" />
      <Body
        isLoading={isLoading}
        topSearches={topSearches?.data}
        clients={clients}
        onCreateClick={openCreateModal}
        onEditClick={openEditModal}
        onDeleteClick={openDeleteModal}
      />
      <CreateEditModal
        showModal={showCreateEditModal}
        setShowModal={setShowCreateEditModal}
        selectedSearch={selectedTopSearch}
        reset={reset}
        watch={watch}
        setValue={setValue}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        clients={clients || []}
      />
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        searchPhrase={selectedTopSearch?.query_text || ''}
        pendingDeleteSearch={selectedTopSearch}
        setPendingDeleteSearch={setSelectedTopSearch}
      />
    </div>
  );
};

interface BodyProps {
  isLoading: boolean;
  topSearches?: TopSearch[];
  clients?: ClientSummary[];
  onCreateClick: () => void;
  onEditClick: (search: TopSearch) => void;
  onDeleteClick: (search: TopSearch) => void;
}

const Body: React.FC<BodyProps> = ({ isLoading, topSearches, clients, onCreateClick, onEditClick, onDeleteClick }) => {
  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="px-4 py-6 sm:px-0">
        <div className="flex justify-between items-center mb-6">
          <p className="text-2xl font-bold text-gray-900">Search Phrases</p>
          <button
            onClick={onCreateClick}
            className="bg-blue-primary text-white rounded-full h-full py-1 px-2 text-center text-sm"
          >
            Add New Search Phrase
          </button>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="bg-white shadow overflow-hidden">
            <ul className="divide-y divide-gray-200">
              {topSearches?.map((search) => (
                <li key={search.id} className="px-6 py-4">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center justify-between w-full">
                      <p className="text-sm text-gray-900">"{search.query_text}"</p>
                      <div className="flex space-x-3">
                        <button
                          onClick={() => onEditClick(search)}
                          className="bg-blue-primary text-white text-sm px-2 py-1 rounded-md"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => onDeleteClick(search)}
                          className="bg-red text-white text-sm px-2 py-1 rounded-md"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                    <div className="text-sm">
                      {search.client_ids.length > 0 && clients ? (
                        <div className="flex flex-wrap gap-2">
                          <span className="bg-blue-300 px-2 py-1 rounded-md">{search.client_ids.length} group(s)</span>
                          {search.client_ids.map((clientId) => {
                            const client = clients.find((c) => c.id === clientId);
                            return client ? (
                              <span key={clientId} className="bg-gray-100 px-2 py-1 rounded-md">
                                {client.name}
                              </span>
                            ) : null;
                          })}
                        </div>
                      ) : (
                        <span className="bg-green-500 px-2 py-1 rounded-md">Default</span>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

interface CreateEditModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSearch: TopSearch | null;
  reset: UseFormReset<TopSearchFormData>;
  watch: UseFormWatch<TopSearchFormData>;
  setValue: UseFormSetValue<TopSearchFormData>;
  register: UseFormRegister<TopSearchFormData>;
  handleSubmit: UseFormHandleSubmit<TopSearchFormData>;
  errors: FieldErrors<TopSearchFormData>;
  clients: ClientSummary[];
}

const CreateEditModal: React.FC<CreateEditModalProps> = ({
  showModal,
  setShowModal,
  selectedSearch,
  reset,
  watch,
  setValue,
  register,
  handleSubmit,
  errors,
  clients,
}) => {
  const { mutate: postTopSearch } = usePostTopSearch();
  const { mutate: updateTopSearch } = useUpdateTopSearch();
  const [clientSearch, setClientSearch] = useState('');

  const filteredClients = clients?.filter((client) => client.name.toLowerCase().includes(clientSearch.toLowerCase()));

  const handleSave = (data: TopSearchFormData) => {
    if (selectedSearch) {
      updateTopSearch({
        id: selectedSearch.id,
        query_text: data.query_text,
        client_ids: data.client_ids || [],
      });
    } else {
      postTopSearch({
        query_text: data.query_text,
        client_ids: data.client_ids || [],
      });
    }
    setShowModal(false);
    setClientSearch('');
    reset();
  };

  return (
    <Modal
      isModel={showModal}
      InnerComponent={
        <div className="inline-block align-bottom bg-white overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full p-7">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
              <div className="flex justify-between items-center mb-4">
                <p className="text-lg font-medium leading-6 text-gray-900">
                  {selectedSearch ? 'Edit Search Phrase' : 'Add New Search Phrase'}
                </p>
                <button
                  onClick={() => {
                    setShowModal(false);
                    setClientSearch('');
                    reset();
                  }}
                  className="text-center h-8 text-sm text-white px-2 bg-blue-primary w-16 rounded-sm ml-4"
                >
                  Close
                </button>
              </div>
              <form onSubmit={handleSubmit(handleSave)} className="space-y-6">
                <div>
                  <CustomInput
                    Label="Search Phrase"
                    inputType="text"
                    register={{
                      ...register('query_text', {
                        required: 'Search phrase is required',
                        validate: {
                          notEmpty: (value) => value.trim().length > 0 || 'Search phrase cannot be empty',
                        },
                      }),
                    }}
                    Error={!!errors.query_text}
                    ErrorMessage={errors.query_text?.message}
                  />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-900 mb-2">Select Groups (Optional)</p>
                  <p className="text-sm text-gray-500 mb-4">
                    If no groups are selected, this will be a default search phrase visible to all groups.
                  </p>
                  <div className="mb-4">
                    <input
                      type="text"
                      placeholder="Search groups..."
                      value={clientSearch}
                      onChange={(e) => setClientSearch(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4 max-h-60 overflow-y-auto">
                    {filteredClients?.map((client) => (
                      <button
                        key={client.id}
                        type="button"
                        onClick={() => {
                          const currentIds = watch('client_ids') || [];
                          const newIds = currentIds.includes(client.id)
                            ? currentIds.filter((id) => id !== client.id)
                            : [...currentIds, client.id];
                          setValue('client_ids', newIds, { shouldDirty: true });
                        }}
                        className={`p-4 text-left rounded-lg ${
                          (watch('client_ids') || []).includes(client.id)
                            ? 'bg-blue-100 border-blue-500'
                            : 'bg-gray-50 border-gray-200'
                        } border`}
                      >
                        {client.name}
                      </button>
                    ))}
                    {filteredClients?.length === 0 && (
                      <div className="col-span-2 text-center py-4 text-gray-500">
                        No groups found matching "{clientSearch}"
                      </div>
                    )}
                  </div>

                  <p className="mt-4 text-sm text-gray-500">
                    {(watch('client_ids')?.length || 0) === 0
                      ? 'This will be a default search phrase (visible to all groups)'
                      : `Selected ${watch('client_ids')?.length} group(s)`}
                  </p>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2
                    bg-blue-primary text-white text-base font-medium sm:ml-3 sm:w-auto sm:text-sm ${
                      watch('query_text') === '' ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={watch('query_text') === ''}
                  >
                    {selectedSearch ? 'Save Changes' : 'Create Search Phrase'}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setClientSearch('');
                      reset();
                    }}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      }
    />
  );
};

interface DeleteModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  searchPhrase: string;
  pendingDeleteSearch: TopSearch | null;
  setPendingDeleteSearch: (search: TopSearch | null) => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  showModal,
  setShowModal,
  searchPhrase,
  pendingDeleteSearch,
  setPendingDeleteSearch,
}) => {
  const { mutate: deleteTopSearch } = useDeleteTopSearch();

  const confirmDelete = () => {
    if (pendingDeleteSearch) {
      deleteTopSearch(pendingDeleteSearch.id);
      setPendingDeleteSearch(null);
      setShowModal(false);
    }
  };
  return (
    <Modal
      isModel={showModal}
      InnerComponent={
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <p className="text-lg leading-6 font-medium text-gray-900">Delete Search Phrase</p>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Are you sure you want to delete "{searchPhrase}"? This action cannot be undone.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={confirmDelete}
            >
              Delete
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      }
    />
  );
};

export default TopSearches;
