import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';
import { AxiosResponse } from 'axios';
import { TagGroup } from './useTagGroups';
import { Tag } from './useTags';

export type TagKey =
  | 'GENDER'
  | 'PRONOUN'
  | 'FAMILY'
  | 'RELATIONSHIP'
  | 'RACE/ETHNICITY'
  | 'SPIRITUALITY'
  | 'LANGUAGE'
  | 'PROFILE'
  | 'SORTING'
  | 'BACKGROUND';

const getProfileTagGroups = async (): Promise<TagGroup[]> => {
  return await axiosGet(
    `/tag_groups/?keys=GENDER,PRONOUN,FAMILY,RELATIONSHIP,RACE/ETHNICITY,SPIRITUALITY,LANGUAGE,PROFILE,BACKGROUND,SORTING`,
    {},
  ).then((profileTagGroupsResponse: AxiosResponse<TagGroup[]>) => profileTagGroupsResponse.data);
};

export const useProfileTagGroups = () => {
  const { data, isLoading, error, refetch } = useQuery<TagGroup[]>(['profile_tag_groups'], getProfileTagGroups);

  const getProfileTagGroupByKey = (key: TagKey) => {
    return data?.filter((profileTagGroup) => profileTagGroup.key === key);
  };

  const getTagsByKey = (
    key:
      | 'GENDER'
      | 'PRONOUN'
      | 'FAMILY'
      | 'RELATIONSHIP'
      | 'RACE/ETHNICITY'
      | 'SPIRITUALITY'
      | 'LANGUAGE'
      | 'PROFILE'
      | 'SORTING'
      | 'BACKGROUND',
  ) => {
    return data
      ?.filter((profileTagGroup) => profileTagGroup.key === key)?.[0]
      ?.tags?.map((item: Tag) => {
        return { name: item.name, id: item.id };
      });
  };

  const getProfileTagGroupByName = (name: string) => {
    return data?.find((profileTagGroup) => profileTagGroup.name === name);
  };

  return {
    data,
    isLoading,
    error,
    refetch,
    getProfileTagGroupByKey,
    getProfileTagGroupByName,
    getTagsByKey,
  };
};
