import React from 'react';
import SideBarChild from '../../components/side-bar/side-child';
import Logo from '../../components/side-bar/logo';
import { UserRole, users, Clients, DashBoard, clientPortal, textBlock, admin } from './roles';
import useAuth from '../../hooks/useAuth';

type props = {
  selectedId: string;
};

const SideBar: React.FunctionComponent<props> = (props): JSX.Element => {
  const { roleType: userRole } = useAuth();

  // this is really some subset of items that are available to the user based on permissions
  let navigationItems: any = [];

  if (userRole === UserRole.admin) {
    navigationItems = [
      { name: '', child: DashBoard },
      { name: 'Clients', child: Clients },
      { name: 'Users', child: users },
      { name: 'Settings', child: textBlock },
      { name: 'Admin', child: admin },
    ];
  } else if (userRole === UserRole.client_admin) {
    navigationItems = [{ name: '', child: clientPortal }];
  } else {
  }

  return (
    <div className="fixed h-full bg-gray-background-dark text-white  w-64">
      <Logo />
      <div className="max-h-screen pb-32 overflow-y-auto">
        {navigationItems.map((item: any) => {
          return (
            <div>
              <SideBarChild heading={item.name} childArray={item.child} selected={props.selectedId} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SideBar;
