import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { SectionLabel, Spinner, ToggleButton, UseFormDropDown } from '../../components/common';
import useDebounce from '../../components/common/debounce';
import Header from '../../components/common/global-top-bar';
import { Headeroptions } from './member-constants';
import SwitchBar from '../../components/switch-bar';
import { getMemberProfileRequest } from './members-redux/actions';
import { updateListenerDetailsRequest } from '../peer-profiles/listeners-redux/actions';
import { fetchTosRequest } from '../clients/text-blocks/text-blocks-redux/actions';
import {
  fetchEmailAvailableRequest,
  fetchMobileValidRequest,
} from '../../components/account-login/login-redux/actions';
import RouteLeavingGuard from '../../components/route-leaving-prompt';
import { ROUTE_PATH } from '../../routes/paths';
import HeadBack from './redirect';
import { paramsToObjects } from '../../components/common/create-params';
import { fetchTagsGroupListRequest } from '../tags-management/redux-tags/actions';
import { useClientSummaryList } from '../../hooks/useClients';
import { Label } from '../peer-profiles/profile';
import { EditDetails } from './components/profile-edit';

const accountStatus = [
  { status: 'enabled', label: 'Enabled' },
  { status: 'disabled', label: 'Disabled' },
  { status: 'under_review', label: 'Under Review' },
  { status: 'suspended', label: 'Suspended' },
  { status: 'banned', label: 'Banned' },
];

type memberProfileProps = {
  memberProfile: any;
  memberProfileError: any;
  appSettings: any;
  mobileValid: any;
  emailAvailable: any;
  pending: boolean;
  tags: any;
};

const MembersProfile: React.FunctionComponent<memberProfileProps> = (props): JSX.Element => {
  const { memberProfile, mobileValid, emailAvailable, appSettings, pending } = props;
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      if (props.memberProfile?.length) return props.memberProfile[0];
    }, [props]),
  });

  const history = useHistory();
  const formSubmit = useRef<any>(null);

  const { data: clients } = useClientSummaryList({ show_all_clients: true });
  const [showEditModal, setShowEditModal] = useState(false);
  const [isCallerRoleStatus, setIsCallerRoleStatus] = useState<boolean>(false);
  const [isPaymentStatus, setIsPaymentStatus] = useState<boolean>(false);
  const [isTextCompatiblePhone, setIsTextCompatiblePhone] = useState<boolean>(true);
  const [language, setLanguage] = useState<any>([]);
  const [timeZones, setTimeZones] = useState<any>([]);
  const [email, setEmail] = useState<any>(getValues('email_address'));
  const [packages, setPackages] = useState<any>([]);
  const [selectedPackage, setSelectedPackage] = useState<any>();
  const [isSpinner, setIsSpinner] = useState<boolean>(true);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [isOnloadMobileRequest, setIsOnloadMobileRequest] = useState<boolean>(true);
  const [isTestUser, setIsTestUser] = useState<boolean>(false);
  const [tags, setTags] = useState<any>({
    pronouns: [],
    race: [],
    genders: [],
  });
  const renewalDate = memberProfile?.length
    ? memberProfile[0]?.caller_role?.product_credit_subscriptions?.[0]?.renews_at.split('T')[0]
    : null;
  const debouncedEmail = useDebounce(email, 500);
  const [displayValues, setDisplayValues] = useState<any>(null);

  useEffect(() => {
    if (memberProfile?.length > 0) {
      setDisplayValues(memberProfile[0]);
    }
  }, [memberProfile]);

  const onSubmit = (data: any) => {
    const packageName = memberProfile?.length ? memberProfile[0]?.caller_role?.active_subscription?.package.name : null;
    const packageCode = selectedPackage?.name !== packageName ? selectedPackage?.code : null;
    if (!data.mobile_phone || data.mobile_phone.trim() === '') {
      delete data.mobile_phone;
    }
    const payload = {
      id: data.id,
      memberId: data.caller_role.id,
      packageCode: packageCode,
      payload: {
        ...data,
      },
    };
    payload.payload['pronoun'] = getValues('pronoun') === '' ? null : getValues('pronoun');
    dispatch(updateListenerDetailsRequest(payload));
    setDisplayValues(data);
    setShowEditModal(false);
  };

  const normalizeInput = (value: string) => {
    // return nothing if no value
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    // if (cvLength > 0) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    // }
  };

  const regexPhoneNumber = (data: any) => {
    if (data?.length > 0) {
      const phone = data?.replace('+1', '');
      setValue('mobile_phone', normalizeInput(phone));
    } else {
      setValue('mobile_phone', '');
    }
    if (data.length >= 12 && data.length <= 14) {
      const phone = data?.replace('+1', '');
      dispatch(fetchMobileValidRequest({ mobile_phone: phone }));
    }
  };

  const selectLanguage = (data: any) => {
    if (language.includes(data)) {
      const filteredArray = language.filter((item: string) => item !== data);
      if (filteredArray.length >= 1) {
        setLanguage(filteredArray);
        setValue('caller_role.listener_preferences.languages', filteredArray);
      } else {
        setLanguage(['english']);
        setValue('caller_role.listener_preferences.languages', ['english']);
      }
    } else {
      setLanguage((languages: any) => [...languages, data]);
      setValue('caller_role.listener_preferences.languages', [...language, data]);
    }
  };

  const handlePackageChange = (name: any) => {
    const find = packages?.length ? packages?.find((item: any) => item.name === name) : null;
    if (find) {
      setSelectedPackage(find);
    }
  };

  const handleContinueSave = (path: string) => {
    history.push(path);
  };

  const formatDateTime = (dateString: string) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzAbbr = new Date()
      .toLocaleTimeString('en-US', { timeZone: localTimeZone, timeZoneName: 'short' })
      .split(' ')[2];
    return `${date.toLocaleString('en-US', { timeZone: localTimeZone })} ${tzAbbr}`;
  };

  useEffect(() => {
    dispatch(fetchEmailAvailableRequest({ email }));
  }, [debouncedEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (appSettings) {
      const app = appSettings?.data?.app;
      setTimeZones(app.supported_timezones);
    }
  }, [appSettings]);

  useEffect(() => {
    const getTags = (type: string) => {
      const { tags } = props?.tags?.find((tag: any) => tag.name === type);
      return tags?.map((tag: any) => tag.name);
    };

    if (props?.tags?.length > 0) {
      const pronouns = getTags('Pronoun');
      const races = getTags('Race/Ethnicity');
      const genders = getTags('Gender');
      setTags({ pronouns, races, genders });
    }
  }, [props?.tags]);

  useEffect(() => {
    if (memberProfile?.length > 0 && clients && clients?.length > 0) {
      // @ts-ignore
      reset(memberProfile[0]);
      const caller_role = memberProfile[0]?.caller_role;
      setIsCallerRoleStatus(caller_role.status === 'enabled' ? true : false);
      setIsPaymentStatus(memberProfile[0]?.stripe_payment_method_id !== null ? true : false);
      setIsTextCompatiblePhone(!!memberProfile[0]?.is_text_compatible_phone);
      setIsTestUser(memberProfile[0]?.test_user);

      const find =
        clients?.length && caller_role?.active_subscription
          ? clients.find((item: any) => item.name === caller_role?.active_subscription?.package?.client?.name)
          : null;
      if (find && find?.packages?.length) {
        setPackages(find.packages);
        setValue('caller_role.active_subscription.package.name', caller_role.active_subscription.package.name);
      }
      const language =
        caller_role?.listener_preferences?.languages?.length > 0
          ? caller_role?.listener_preferences?.languages
          : ['english'];
      setLanguage(language);
      const phone = memberProfile[0]?.mobile_phone.replace('+1', '');
      if (isOnloadMobileRequest) {
        regexPhoneNumber(memberProfile[0]?.mobile_phone);
        setIsOnloadMobileRequest(false);
      } else {
        const phoneNumber = normalizeInput(phone);
        setValue('mobile_phone', phoneNumber);
      }
      setIsSpinner(false);
      setIsModified(false);
    }
  }, [memberProfile, clients]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    try {
      const params = paramsToObjects();
      const callerRoleId = params['callerRoleId'];
      dispatch(getMemberProfileRequest({ memberId: callerRoleId }));
      dispatch(fetchTosRequest({ type: 'app' }));
      dispatch(fetchTagsGroupListRequest({ keys: 'PRONOUN,RACE/ETHNICITY,GENDER' }));
    } catch (error) {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {pending || isSpinner ? <Spinner /> : null}
      <Header heading={<HeadBack memberName={displayValues?.first_name} />} />
      <SwitchBar
        heading={Headeroptions}
        position={0}
        forwardParams
        optionalButton={
          <button
            id="form-submit"
            type="submit"
            form="member-profile-form"
            className={`${
              isModified ? 'stacked-bar-blue-400' : 'bg-gray-300 cursor-not-allowed'
            }  rounded-full w-1/12 text-center h-6 text-sm text-white px-3`}
            disabled={!isModified}
            ref={formSubmit}
          >
            SAVE
          </button>
        }
      />
      <form onSubmit={handleSubmit(onSubmit)} id="member-profile-form">
        <div className="max-window-height-group overflow-y-auto flex justify-between ">
          <div className="w-1/2">
            <div className="flex justify-between px-7 pt-4">
              <div className="w-1/2 px-2 text-gray-dark font-bold text-lg">
                {displayValues?.first_name} {displayValues?.last_name}
              </div>
              <div className="w-1/2 px-2 text-gray-dark font-bold text-lg">#{displayValues?.id}</div>
            </div>
            <div className="flex justify-end px-7 py-2">
              <div className="w-1/2 px-2">
                <button
                  data-testid="edit-details-button"
                  type="button"
                  onClick={() => setShowEditModal(true)}
                  className="text-sm text-white px-2 bg-blue-primary flex items-center justify-center w-24 h-8 rounded-sm"
                >
                  Edit Details
                </button>
              </div>
            </div>
            <div className="px-7 ">
              <SectionLabel Label={'Plan Details'} />
            </div>
            <div className="flex justify-between px-7 py-2">
              <div className="w-full px-2">
                <Label
                  Label="Group"
                  value={displayValues?.caller_role?.active_subscription?.package?.client?.name}
                  dataTestId="group"
                />
              </div>
              <div className="w-full px-2">
                <Label
                  Label="Plan"
                  value={displayValues?.caller_role?.active_subscription?.package?.name}
                  dataTestId="plan"
                />
              </div>
            </div>
            <div className="flex justify-between px-7 py-2">
              <div className="px-2">
                <Label
                  Label="Minutes Remaining"
                  value={(memberProfile?.length && memberProfile[0]?.user_subscription?.remaining_minutes) || 'N/A'}
                  dataTestId="minutes-remaining"
                />
              </div>
              {renewalDate && (
                <div className="w-full px-2">
                  <Label Label="Plan Renewal Date" value={renewalDate} dataTestId="renewal-date" />
                </div>
              )}
            </div>
            <div className="px-7 ">
              <SectionLabel Label={'Contact'} />
            </div>
            <div className="flex justify-between px-7 py-2">
              <div className="w-full px-2">
                <Label Label="Email" value={displayValues?.email_address} dataTestId="email" />
              </div>
            </div>
            <div className="flex justify-between px-7 py-2">
              <div className="w-full px-2">
                <Label
                  Label="Eligibility Email"
                  value={displayValues?.eligibility_email}
                  dataTestId="eligibility-email"
                />
              </div>
            </div>
            <div className="flex justify-between px-7 py-2">
              <div className="w-1/2 px-2">
                <Label Label="Phone" value={displayValues?.mobile_phone} dataTestId="phone" />
              </div>
              <div className="w-1/2 px-2">
                <Label Label="Time Zone" value={displayValues?.timezone} dataTestId="timezone" />
              </div>
            </div>
            <div className="flex justify-between px-7 py-2">
              <div className="w-full px-2">
                <Label
                  Label="Receive SMS Text Comms"
                  value={displayValues?.is_text_compatible_phone ? 'Yes' : 'No'}
                  dataTestId="sms-comms"
                />
              </div>
            </div>
            <div className="px-7 ">
              <SectionLabel Label={'Matching Demographics'} />
            </div>
            <div className="flex justify-between px-7 py-2">
              <div className="w-1/2 px-2">
                <Label Label="DOB (Age)" value={displayValues?.date_of_birth} dataTestId="dob" />
              </div>
              <div className="w-1/2 px-2">
                <Label Label="Gender" value={displayValues?.gender || 'N/A'} dataTestId="gender" />
              </div>
            </div>
            <div className="flex justify-between px-7 py-2">
              <div className="w-1/2 px-2">
                <Label Label="Pronouns" value={displayValues?.pronoun || 'N/A'} dataTestId="pronouns" />
              </div>
              <div className="w-1/2 px-2">
                <Label Label="Race" value={displayValues?.race || 'N/A'} dataTestId="race" />
              </div>
            </div>
            <div className="flex justify-between px-7 py-2">
              <div className="w-full px-2">
                <Label
                  Label="Preferred Language"
                  value={
                    displayValues?.caller_role?.listener_preferences?.languages?.length > 0
                      ? displayValues?.caller_role?.listener_preferences?.languages
                          .map((lang: string) => lang.charAt(0).toUpperCase() + lang.slice(1))
                          .join(', ')
                      : 'N/A'
                  }
                  dataTestId="preferred-language"
                />
              </div>
            </div>
            <div className="flex flex-col justify-between px-7 py-2">
              <label className="input-label">Internal Notes</label>
              <textarea
                {...register('caller_role.notes', {
                  onBlur: (e: any) => setValue('caller_role.notes', e.target.value.trim()),
                  onChange: () => setIsModified(true),
                })}
                className="py-2 px-3 rounded-md h-28 w-full text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none"
              />
            </div>
          </div>
          <div className="w-1/2 ">
            <div className="px-7 ">
              <SectionLabel Label={'Status'} />
            </div>
            <div className="flex justify-between px-7 py-2">
              <div className="w-1/2 px-2">
                <div className="">
                  <UseFormDropDown label={'User Account'}>
                    <select
                      className="select-box w-full"
                      {...register('status', {
                        onChange: () => setIsModified(true),
                      })}
                    >
                      {accountStatus.length > 0 &&
                        accountStatus.map((item) => (
                          <option key={item.status} value={item.status}>
                            {item.label}
                          </option>
                        ))}
                    </select>
                  </UseFormDropDown>
                </div>
              </div>
              <div className="w-1/2 px-2 pt-6">
                <div className="flex items-center">
                  <label className="block leading-4 pr-4 text-gray-dark"> Test User </label>
                  <ToggleButton
                    id={'TestUser'}
                    status={isTestUser}
                    setStatus={(data) => {
                      setIsTestUser(data);
                      const status = data ? true : false;
                      setValue('test_user', status);
                      setIsModified(true);
                    }}
                    initialstatus={true}
                  ></ToggleButton>
                </div>
              </div>
            </div>
            <div className="flex justify-between px-7 py-2">
              <div className="w-1/2 px-2">
                <div className="flex items-center">
                  <label className="block leading-4 pr-4 text-gray-dark"> Caller Role </label>
                  <ToggleButton
                    id={'CallerRole'}
                    status={isCallerRoleStatus}
                    setStatus={(data) => {
                      setIsCallerRoleStatus(data);
                      const status = data ? 'enabled' : 'disabled';
                      setValue('caller_role.status', status);
                      setIsModified(true);
                    }}
                    initialstatus={true}
                  ></ToggleButton>
                </div>
              </div>
              <div className="w-1/2 px-2">
                <div className="flex items-center">
                  <label className="block leading-4 pr-4 text-gray-dark"> Payment Setup </label>
                  <ToggleButton
                    id={'Paymentsetup'}
                    status={isPaymentStatus}
                    setStatus={(data) => {}}
                    disabled={true}
                    initialstatus={true}
                  ></ToggleButton>
                </div>
              </div>
            </div>
            <div className="px-7 ">
              <SectionLabel Label={'Subscription Details'} />
            </div>
            <div className="flex justify-between px-7 py-2">
              <div className="w-full px-2">
                <Label
                  Label="Eligible as of"
                  value={formatDateTime(props.memberProfile?.[0]?.caller_role?.active_subscription?.created_at)}
                  dataTestId="group"
                />
              </div>
              <div className="w-full px-2">
                <Label
                  Label="Activated"
                  value={formatDateTime(props.memberProfile?.[0]?.caller_role?.onboarding_completed)}
                  dataTestId="group"
                />
              </div>
            </div>
          </div>
        </div>
        <EditDetails
          showEditModal={showEditModal}
          onClose={() => setShowEditModal(false)}
          onSave={handleSubmit(onSubmit)}
          emailAvailable={emailAvailable}
          tags={tags}
          timeZones={timeZones}
          clients={clients || []}
          packages={packages || []}
          language={language}
          selectLanguage={selectLanguage}
          setPackages={setPackages}
          handlePackageChange={handlePackageChange}
          setIsModified={setIsModified}
          register={register}
          setValue={setValue}
          errors={errors}
          setEmail={setEmail}
          regexPhoneNumber={regexPhoneNumber}
          mobileValid={mobileValid}
          isTextCompatiblePhone={isTextCompatiblePhone}
          setIsTextCompatiblePhone={setIsTextCompatiblePhone}
          initialValues={displayValues}
        />
      </form>
      <RouteLeavingGuard
        when={isModified}
        navigate={(path: string) => history.push(path)}
        shouldBlockNavigation={(location: any) => {
          if (location.pathname !== ROUTE_PATH.MEMBERS_PROFILE) {
            return true;
          }
          return false;
        }}
        titleText={'Alert'}
        contentText={'You have unsaved changes. Do you want to leave this page and lose your changes?'}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Disregard changes'}
        confirmSaveButtonText="Continue and Save changes"
        handleContinueSaveChange={(path) => {
          formSubmit?.current.click();
          setIsModified(false);
          setTimeout(() => {
            handleContinueSave(path);
          }, 100);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    memberProfile: state.Members?.memberProfile?.data || null,
    memberProfileError: state.Members?.memberProfileError,
    appSettings: state.Tos.clientAdmin?.data || null,
    mobileValid: state.login.mobileValid?.mobileValid || false,
    emailAvailable: state.login.emailAvailable?.data,
    tags: state.tags.TagsGroupList?.data || null,
    pending: state.Members?.pending || state.Client.pending || state.Tos.pending || state.Listeners?.pending,
  };
};

export default connect(mapStateToProps)(MembersProfile);
