import { Spinner } from '../../../components/common';
import { EngagementSummaryReport, useEngagementSummaryReport } from '../../../hooks/useEngagementReporting';
import { EngagementReportFilters } from '../common/reports-constants';

interface EngagementSummaryFilters {
  filters: EngagementReportFilters;
}

export const EngagementSummary: React.FC<EngagementSummaryFilters> = ({ filters }) => {
  const { data: engagementSummaryReport, isLoading } = useEngagementSummaryReport(filters);

  return (
    <div className="p-4 max-w-6xl">
      <h1 className="text-2xl font-bold mb-4">Engagements Summary Report</h1>
      <div className="overflow-x-auto">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <table className="min-w-full bg-white border border-gray-200 font-mono">
              <thead>
                <tr className="bg-gray-100 text-left">
                  <th className="py-2 px-4 border-b">Date</th>
                  <th className="py-2 px-4 border-b">Peer Support</th>
                  <th className="py-2 px-4 border-b">External Support</th>
                </tr>
              </thead>
              <tbody>
                {engagementSummaryReport?.data.map((summary: EngagementSummaryReport, index: any) => (
                  <tr key={index} className={`text-sm ${!summary.date && 'bg-gray-200'}`}>
                    <td className="w-1/6 py-2 px-4 border-b align-top">
                      <div className={`${!summary.date && 'font-bold'}`}>{summary.date || 'Rollup'}</div>
                      <div className="flex justify-between">
                        <p> Total: </p>
                        <p className="font-bold">{summary.engagements}</p>
                      </div>
                    </td>
                    <td className="w-1/4 py-2 px-4 border-b align-top">
                      <div className="flex justify-between">
                        <p>Audio Plays:</p>
                        <p className="font-bold">{summary.audio_play}</p>
                      </div>
                      <div className="flex justify-between">
                        <p>Video Plays:</p>
                        <p className="font-bold">{summary.video_play}</p>
                      </div>
                      <div className="flex justify-between">
                        <p>Calls Completed:</p>
                        <p className="font-bold">{summary.calls_completed}</p>
                      </div>
                    </td>
                    <td className="w-3/5 py-2 pl-4 pr-12 border-b align-top whitespace-nowrap">
                      <div className="flex items-center space-x-2">
                        <div className="flex justify-between w-1/2">
                          <p>+Care Engagements:</p>
                          <p className="font-bold">{summary.provider_engagement}</p>
                        </div>
                        <div className="flex w-1/2 pl-6 space-x-2 text-sm">
                          <p>
                            (Views: <span className="font-bold">{summary.provider_view}</span>
                          </p>
                          <p>
                            Impressions: <span className="font-bold">{summary.provider_impression}</span>)
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="flex justify-between w-1/2">
                          <p>Program Engagements:</p>
                          <p className="font-bold">{summary.client_resource_engagement}</p>
                        </div>
                        <div className="flex w-1/2 pl-6 space-x-2 text-sm">
                          <p>
                            (Views: <span className="font-bold">{summary.client_resource_view}</span>
                          </p>
                          <p>
                            Impressions: <span className="font-bold">{summary.client_resource_impression}</span>)
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="flex justify-between w-1/2">
                          <p>Resource Engagements:</p>
                          <p className="font-bold">{summary.global_resource_engagement}</p>
                        </div>
                        <div className="flex w-1/2 pl-6 space-x-2 text-sm">
                          <p>
                            (Views: <span className="font-bold">{summary.global_resource_view}</span>
                          </p>
                          <p>
                            Impressions: <span className="font-bold">{summary.global_resource_impression}</span>)
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};
