import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import Header from '../../components/common/global-top-bar';
import { Button, Pagination, Spinner } from '../../components/common';
import { MemberTypes } from './member-constants';
import { connect, useDispatch } from 'react-redux';
import { fetchMembersCountRequest, fetchMembersRequest } from './members-redux/actions';
import moment from 'moment';
import Searchbar from '../../components/common/searchbar';
import { sortArrow } from '../../assets/images';
import { convertObjectToParams } from '../../components/common/create-params';
import { ROUTE_PATH } from '../../routes/paths';
import { Paginator } from '../../components/common/paginator';

type props = {
  memebers: any;
  memebersCount: any;
  pending: boolean;
};

type FilterProps = {
  count: number | undefined;
  fetchData: (data: any, from: string) => void;
};

const Filters: React.FunctionComponent<FilterProps> = ({ count, fetchData }): JSX.Element => {
  const [filters, setfilters] = useState({
    page: 1,
    limit: 25,
    search: '',
    type: 'ALL',
    order_by_direction: 'desc',
    order_by: 'date',
  });

  // useEffect(() => {
  //     fetchData(filters);
  // }, [filters]);

  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7  h-10 ">
      <div className="w-1/4">
        <div className="w-full h-8">
          <Searchbar
            search={(data) => {
              setfilters((prev) => {
                fetchData({ ...prev, page: 1, search: data }, 'filters');
                return { ...prev, page: 1, search: data };
              });
            }}
          />
        </div>
      </div>
      <div className="w-3/4">
        <Paginator
          count={count!}
          limit={25}
          currentPage={filters.page}
          handlePageChange={(page) =>
            setfilters((filter) => {
              fetchData({ ...filter, page: page }, 'filters');
              return { ...filter, page: page };
            })
          }
        />
      </div>
      <div className="w-1/3">
        <div className=" flex justify-end">
          <div className="flex justify-between items-center py-4  gray-background-dark">
            {Object.keys(MemberTypes).map((item) => (
              <button
                className={
                  ' text-sm  px-5 py-1 ' +
                  //@ts-ignore
                  (MemberTypes[item] === filters.type
                    ? 'bg-blue-primary text-white'
                    : 'bg-gray-background-dark text-gray-dark')
                }
                onClick={() => {
                  setfilters((filter) => {
                    //@ts-ignore
                    fetchData({ ...filter, type: MemberTypes[item], page: 1 });

                    //@ts-ignore
                    return { ...filter, type: MemberTypes[item], page: 1 };
                  });
                }}
              >
                {/* @ts-ignore */}
                {item}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Members: React.FunctionComponent<props> = (props): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState<any>({
    page: 1,
    limit: 25,
    search: '',
    type: 'ALL',
    order_by_direction: 'desc',
    order_by: 'date',
  });

  const fetchData = (filter: any, from?: string) => {
    if (filter.type !== 'ALL') {
      //@ts-ignore
      filter = { ...filter, [filter.type]: true };
    }
    if (from === 'filters') {
      filter = {
        ...filter,
        order_by_direction: filters.order_by_direction,
        order_by: filters.order_by,
      };
    }
    setFilters(filter);
    dispatch(fetchMembersRequest(filter));
    dispatch(fetchMembersCountRequest(filter));
  };

  const handleSort = (type: string) => {
    fetchData({
      ...filters,
      order_by: type,
      order_by_direction:
        filters.order_by === type ? (filters?.order_by_direction === 'desc' ? 'asc' : 'desc') : 'desc',
    });
  };

  return (
    <div>
      {props.pending && <Spinner />}
      <Header heading={'Members'} />
      <Filters fetchData={fetchData} count={props?.memebersCount.count} />
      <div className="max-window-height overflow-y-auto px-7">
        <table className="table-fixed table-row-format w-full relative border-collapse mb-5 text-xs">
          <thead className="bg-white h-full">
            <tr className="text-left w-full px-7">
              <th
                className={`px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 ${
                  filters.order_by === 'name' && 'blue-primary'
                }`}
              >
                <div
                  className="flex items-center pl-1 "
                  role="button"
                  onClick={() => {
                    handleSort('name');
                  }}
                >
                  Name{' '}
                  {filters.order_by === 'name' && (
                    <img
                      src={sortArrow}
                      className={`w-4 h-4 ml-2 cursor-pointer ${
                        filters?.order_by_direction === 'desc' ? `transform rotate-180` : ``
                      }`}
                      alt="profile"
                    ></img>
                  )}
                </div>
              </th>
              <th
                className={`px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 ${
                  filters.order_by === 'plan_name' && 'blue-primary'
                }`}
              >
                <div
                  className="flex items-center  "
                  role="button"
                  onClick={() => {
                    handleSort('plan_name');
                  }}
                >
                  Plan Name{' '}
                  {filters.order_by === 'plan_name' && (
                    <img
                      src={sortArrow}
                      className={`w-4 h-4 ml-2 cursor-pointer ${
                        filters?.order_by_direction === 'desc' ? `transform rotate-180` : ``
                      }`}
                      alt="profile"
                    ></img>
                  )}
                </div>
              </th>
              <th
                className={`px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 ${
                  filters.order_by === 'plan_code' && 'blue-primary'
                }`}
              >
                <div
                  className="flex items-center  "
                  role="button"
                  onClick={() => {
                    handleSort('plan_code');
                  }}
                >
                  Plan Code{' '}
                  {filters.order_by === 'plan_code' && (
                    <img
                      src={sortArrow}
                      className={`w-4 h-4 ml-2 cursor-pointer ${
                        filters?.order_by_direction === 'desc' ? `transform rotate-180` : ``
                      }`}
                      alt="profile"
                    ></img>
                  )}
                </div>
              </th>
              <th className="px-2 py-2 w-3/12 sticky top-0 bg-white h-full mb-4">
                <p className="-pl-4">Activated At</p>
              </th>
              <th
                className={`px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4 ${
                  filters.order_by === 'date' && 'blue-primary'
                }`}
              >
                <div
                  className="flex items-center  "
                  role="button"
                  onClick={() => {
                    handleSort('date');
                  }}
                >
                  {' '}
                  Most Recent Engagement{' '}
                  {filters.order_by === 'date' && (
                    <img
                      src={sortArrow}
                      className={`w-4 h-4 ml-2 cursor-pointer ${
                        filters?.order_by_direction === 'desc' ? `transform rotate-180` : ``
                      }`}
                      alt="profile"
                    ></img>
                  )}
                </div>
              </th>
              <th className="px-2 py-2  w-1/12 sticky top-0 bg-white  h-full mb-4 ">
                <p className="-pl-4">Moods</p>
              </th>
              <th className="px-2 py-2  w-1/12 sticky top-0 bg-white  h-full mb-4">
                <p className="pl-1">Plays</p>
              </th>
              <th className="px-2 py-1  w-1/12 sticky top-0 bg-white  h-full mb-4">Calls</th>
              <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 z-10"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-2"></tr>
            {props.memebers.length > 0 &&
              props.memebers.map((item: any) => {
                return (
                  <tr
                    role="button"
                    className="bg-gray-200 rounded text-gray-dark text-left h-12 border-y border-white border-4 space-y-6"
                    onClick={() => {
                      history.push(
                        ROUTE_PATH.MEMBERS_PROFILE +
                          convertObjectToParams({
                            name: item.first_name,
                            id: item.id,
                            callerRoleId: item.caller_role_id,
                          }),
                      );
                    }}
                  >
                    <td className=" py-1 w-2/12 truncate pl-1">
                      <div className="flex flex-col">
                        <span className="truncate">{`${item.first_name} ${item.last_name}`}</span>
                        <span className="text-gray-500 text-xs">{`#${item.id}`}</span>
                      </div>
                    </td>
                    <td className=" py-1 w-2/12 truncate">{`${item.plan_name}`}</td>
                    <td className=" py-1 w-2/12 ">{`${item.plan_code}`}</td>
                    <td className="py-1 w-3/12">{`${
                      item.caller_role.onboarding_completed
                        ? `${moment(item.caller_role.onboarding_completed).local().format('MMM Do YYYY')} @ ${moment(
                            item.caller_role.onboarding_completed,
                          )
                            .local()
                            .format('HH:mm')}`
                        : '-'
                    }`}</td>
                    <td className={`py-1 w-3/12 ${!item.latest_engagement && 'pl-24 font-bold'}`}>
                      {' '}
                      {`${
                        item.latest_engagement
                          ? `${moment(item.latest_engagement).local().format('MMM Do YYYY')} @ ${moment(
                              item.latest_engagement,
                            )
                              .local()
                              .format('HH:mm')}`
                          : '-'
                      }`}
                    </td>
                    <td className=" py-1 w-1/12">{item.moods_count} </td>
                    <td className=" py-1 w-1/12">
                      <p className="pl-1"> {item.plays_count}</p>
                    </td>
                    <td className=" py-1 w-1/12">{item.calls_count}</td>

                    <td className="px-2 py-1 w-2/12">
                      <div className="flex items-center justify-between">
                        <div className="w-2/3 ">
                          {item.calls_count > 0 ? (
                            <button className={'stacked-bar-green-primary text-white rounded-full px-2 py-1 text-sm'}>
                              Caller
                            </button>
                          ) : (
                            <p className="font-bold w-full text-center">-</p>
                          )}
                        </div>
                        <button className="rounded-full h-8 w-8 blue-primary  transform rotate-180 ">
                          <img className="h-8 w-8" src={RightArrow} alt="left Arrow"></img>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    memebers: state.Members?.MEMBERS?.data || [],
    memebersCount: state.Members?.MEMBERS_COUNT?.data || [],
    pending: state.Members?.pending || false,
  };
};

export default connect(mapStateToProps)(Members);
