import { CallReportFilters } from '../call-reports/common/call-report-constants';

export const HeaderOptions = [
  { heading: 'Calls', path: '/reports/calls' },
  { heading: 'Engagements', path: '/reports/engagements' },
  { heading: 'Client Engagements', path: '/reports/client-engagements' },
];

export interface EngagementReportFilters {
  startDate: string;
  endDate: string;
  clientId?: number;
  testData: boolean;
  timezone?: string;
}

/*
The following section defines the filters that are shared between differing reports.
Currently, the only shared filter is the start and end date, but I've made this dynamic
to allow for future shared filters. For example, both reports use the clientId filter,
so we could add it to the array and use the updateStoredFilters function to include it
in the session storage.
*/

const sharedFilters = ['startDate', 'endDate'] as const;
type SharedFilter = (typeof sharedFilters)[number];
type ReportFilters = CallReportFilters | EngagementReportFilters;

export const getStoredFilters = <T extends ReportFilters>(
  defaultFilters: T,
  storageKey: string = 'reportingFilters',
): T => {
  const savedFilters = sessionStorage.getItem(storageKey);
  if (savedFilters) {
    const parsed = JSON.parse(savedFilters);
    return Object.keys(defaultFilters).reduce(
      (acc, key) => ({
        ...acc,
        [key]:
          sharedFilters.includes(key as SharedFilter) && parsed[key] !== undefined
            ? parsed[key]
            : defaultFilters[key as keyof T],
      }),
      {} as T,
    );
  }
  return defaultFilters;
};

export const updateStoredFilters = (key: string, value: any, storageKey: string = 'reportingFilters') => {
  if (!sharedFilters.includes(key as SharedFilter)) {
    return;
  }

  const existingFilters = JSON.parse(sessionStorage.getItem(storageKey) || '{}');
  sessionStorage.setItem(
    storageKey,
    JSON.stringify({
      ...existingFilters,
      [key]: value,
    }),
  );
};
