import React, { useEffect, useMemo, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import { useHistory } from 'react-router-dom';
import Spinner from '../../components/common/spinner';
import SwitchBar from '../../components/switch-bar';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import {
  TagGroup,
  useCreateTagGroup,
  useDeleteTagGroup,
  useTagGroups,
  useUpdateTagGroup,
} from '../../hooks/useTagGroups';
import { Paginator } from '../../components/common/paginator';
import AlertBox from '../../components/route-leaving-prompt/alert-box';
import Modal from '../../components/common/modal';
import { useForm } from 'react-hook-form';
import { Tag, TagExtended, TagType, useTags } from '../../hooks/useTags';
import { CustomInput, UseFormDropDown } from '../../components/common';
import { onBlur } from '../../components/common/useForm';
import Searchbar from '../../components/common/searchbar';

export const HeaderOptions = [
  { heading: 'Tag Groups', path: '/tag-groups' },
  { heading: 'Tags', path: '/tags' },
];

export const TagTypes = {
  All: undefined,
  Topic: 'topic',
  Trait: 'trait',
} as const;

interface TagGroupFilters {
  limit: number;
  keys: string;
  page: number;
  name: string;
  type?: TagType;
}

const TagGroups: React.FunctionComponent = (): JSX.Element => {
  const [filters, setFilters] = useState<TagGroupFilters>({
    limit: 25,
    keys: 'all',
    name: '',
    page: 1,
    type: undefined,
  });
  const { page, limit, ...filteredFilters } = filters;
  const { data: tagGroupsList, isLoading } = useTagGroups(filteredFilters);
  const { mutate: deleteTagGroup } = useDeleteTagGroup();
  const [selectedTagGroup, setSelectedTagGroup] = useState<TagGroup | undefined>(tagGroupsList?.[0] || undefined);
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
  const [showCreateTagModal, setShowCreateTagModal] = useState<boolean>(false);

  // front end pagination
  const startIndex = (filters.page - 1) * filters.limit;
  const endIndex = startIndex + filters.limit;
  const paginatedTagsList = tagGroupsList?.slice(startIndex, endIndex);

  const handleDelete = () => {
    setShowAlertModal(false);
    deleteTagGroup(selectedTagGroup?.id!);
  };

  const handleSelectedTagGroup = (tagGroup?: TagGroup) => {
    if (tagGroup) {
      setSelectedTagGroup(tagGroup);
    } else if (paginatedTagsList && paginatedTagsList.length > 0) {
      setSelectedTagGroup(paginatedTagsList[0]);
    }
  };

  if (selectedTagGroup && !tagGroupsList?.includes(selectedTagGroup)) {
    setSelectedTagGroup(undefined);
  }

  if (!selectedTagGroup && tagGroupsList && tagGroupsList?.length > 0) {
    handleSelectedTagGroup();
  }

  return (
    <div>
      <Header heading={'Tags'} />
      <SwitchBar heading={HeaderOptions} position={0} />
      <Filters
        count={tagGroupsList?.length || undefined}
        filters={filters}
        setFilters={setFilters}
        setSelectedTagGroup={setSelectedTagGroup}
        setShowCreateTagModal={setShowCreateTagModal}
      />
      {isLoading && <Spinner />}
      {tagGroupsList && selectedTagGroup && (
        <div className="flex w-full">
          <TagGroupsList
            selectedTagGroupId={selectedTagGroup.id}
            setSelectedTagGroup={setSelectedTagGroup}
            tagGroups={paginatedTagsList || []}
          />
          <TagGroupDetails
            tagGroup={selectedTagGroup}
            setShowAlertModal={setShowAlertModal}
            setSelectedTagGroup={setSelectedTagGroup}
          />
        </div>
      )}
      {showCreateTagModal && <CreateTagModal setShowCreateTagModal={setShowCreateTagModal} />}
      <AlertBox
        visible={showAlertModal}
        handlePrompt={handleDelete}
        closeModal={() => setShowAlertModal(false)}
        titleText={'Alert'}
        contentText={`Are you sure you want to remove the selected Tag Group?`}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Remove'}
      />
    </div>
  );
};

interface TagGroupsListProps {
  selectedTagGroupId: number;
  setSelectedTagGroup: (tagGroup: TagGroup) => void;
  tagGroups: TagGroup[];
}

const TagGroupsList: React.FC<TagGroupsListProps> = ({ selectedTagGroupId, setSelectedTagGroup, tagGroups }) => {
  return (
    <div className="w-1/2 px-7">
      <div className="">
        <div className="w-full  border-b-2 border-black font-bold text-gray-dark text-left h-12   grid grid-cols-12  items-center sticky mb-2 ">
          <div className=" font-bold col-span-5">
            <p className="pl-10">Tag Group</p>
          </div>
          <div className=" col-span-7 ">Type</div>
        </div>
      </div>
      <div className="pb-10">
        {tagGroups?.slice().map((tagGroup: TagGroup) => (
          <div
            className={`w-full ${selectedTagGroupId === tagGroup.id ? 'bg-blue-primary  text-white' : 'bg-gray-200 text-gray-dark'} rounded-xl text-left h-12 border-y my-2 grid grid-cols-12  items-center cursor-pointer`}
            onClick={() => {
              setSelectedTagGroup(tagGroup);
            }}
          >
            <div className=" font-bold col-span-5">
              <p className="pl-10">{tagGroup.name}</p>
            </div>
            <div className=" col-span-7 flex justify-between mr-2 items-center">
              <div className=" col-span-3 capitalize"> {tagGroup.tag_group_type} </div>
              <button className="rounded-full h-8 w-8 blue-primary  transform rotate-180 ">
                <img className="h-8 w-8" src={RightArrow} alt="left Arrow"></img>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

interface TagGroupDetailsProps {
  tagGroup: TagGroup;
  setShowAlertModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTagGroup: React.Dispatch<React.SetStateAction<TagGroup | undefined>>;
}

const TagGroupDetails: React.FC<TagGroupDetailsProps> = ({ tagGroup, setShowAlertModal, setSelectedTagGroup }) => {
  const { data: tags } = useTags({ include_hidden: true });
  const { mutate: updateTagGroup } = useUpdateTagGroup();
  const [tagsList, setTagsList] = useState<TagExtended[]>([]);
  const [tagsModal, setTagsModal] = useState<boolean>(false);

  const processTags = (tags: Tag[]) => {
    if (!tagGroup.tags) {
      setTagsList(tags.map((tag) => ({ ...tag, hasTag: false })));
      return;
    } else {
      const tagIds = tagGroup.tags.map((tag) => tag.id);
      setTagsList(tags.map((tag) => ({ ...tag, hasTag: tagIds.includes(tag.id) })));
    }
  };

  const defaultTagGroup = {
    ...tagGroup,
    children: tagGroup.children || null,
    description: tagGroup.description || null,
    key: tagGroup.key || null,
    name: tagGroup.name || null,
    parent_id: tagGroup.parent_id || null,
    tag_group_type: tagGroup.tag_group_type || null,
    tags: tagGroup?.tags || [],
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: useMemo(() => {
      if (tagGroup) {
        return defaultTagGroup;
      }
    }, [tagGroup]),
  });

  const handleTag = (hasTag: boolean, id: number) => {
    const updatedTagsList = tagsList.map((tag) => (tag.id === id ? { ...tag, hasTag } : tag));
    setTagsList(updatedTagsList);
    setValue('tags', updatedTagsList, { shouldDirty: true });
  };

  const onSubmit = (data: any) => {
    const payload = {
      ...data,
      tags: tagsList.filter((tag: TagExtended) => tag.hasTag),
    };
    updateTagGroup({ tagGroupId: tagGroup.id, tagGroup: payload });
    setSelectedTagGroup(undefined);
  };

  useEffect(() => {
    if (tagGroup && tags) {
      reset(tagGroup);
      processTags(tags);
    }
  }, [tagGroup, tags, reset]);

  return (
    <div className="w-1/2 border-2 border-gray-200 rounded-3xl my-12 mx-7">
      <div className="h-10 flex px-6 pt-2 justify-between items-center">
        <button
          className="rounded-full w-20 text-center h-6 text-sm text-white bg-red px-2"
          onClick={() => {
            setShowAlertModal(true);
          }}
        >
          REMOVE
        </button>
        <div className={` ${!isDirty && 'cursor-not-allowed'}`}>
          <button
            className={`rounded-full w-20 text-center h-6 text-sm text-white px-2 ml-2 ${
              isDirty ? 'bg-bright-blue' : 'bg-gray-background-dark pointer-events-none'
            }`}
            type="submit"
            form="update-tag-group"
          >
            SAVE
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id="update-tag-group">
        <div className="w-full px-3">
          <div className="w-full grid grid-cols-1 gap-x-6 px-7 mt-5 gap-y-6">
            <div className="flex flex-col space-y-4">
              <CustomInput
                inputType="text"
                Label="Tag Group Name"
                register={{
                  ...register('name', {
                    required: 'Please provide Tag name',
                    onBlur: (e: any) => onBlur(e, 'name', setValue),
                  }),
                }}
                Error={errors?.name?.message?.length ? true : false}
                ErrorMessage={errors.name?.message}
              />
              <>
                <p>Type</p>
                <p className="font-bold capitalize">{tagGroup.tag_group_type}</p>
              </>
            </div>
            <div>
              <div className="leading-4 text-gray-dark mb-2 border-b border-gray-dark py-1 flex justify-between h-10 items-center">
                <p>Tags Associated with Group</p>
                <button
                  className="bg-gray-dark text-white py-2 rounded-full px-3"
                  type="button"
                  onClick={() => {
                    setTagsModal(true);
                  }}
                >
                  + ADD
                </button>
              </div>
              <div className="grid grid-cols-2 gap-x-2">
                {tagsList?.filter((tag: TagExtended) => tag.hasTag).length > 0 ? (
                  tagsList
                    .filter((tag: TagExtended) => tag.hasTag)
                    .map((item: TagExtended) => {
                      return (
                        <button className={`bg-blue-primary text-white text-sm font-bold mx-2 my-1 w-full`}>
                          <div className="px-3 py-2 flex justify-between">
                            <div className="pr-2">{item.name}</div>
                            <button
                              type="button"
                              className="text-white font-bold"
                              onClick={(e) => {
                                e.preventDefault();
                                handleTag(false, item.id);
                              }}
                            >
                              X
                            </button>
                          </div>
                        </button>
                      );
                    })
                ) : (
                  <p className="m-2"> No Listener Tags </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal
        isModel={tagsModal}
        InnerComponent={
          <div className="max-w-3xl inline-block align-bottom bg-white rounded-lg text-left overflow-hidden transform transition-all">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="flex justify-between">
                    <h3 className="text-lg leading-6 text-gray-900 font-bold mb-3" id="modal-title">
                      Add Tag(s) to Group
                    </h3>
                    <button
                      className="rounded-full w-20 text-center h-6 text-sm text-white bg-bright-blue px-2"
                      onClick={() => {
                        setTagsModal(false);
                      }}
                    >
                      CLOSE
                    </button>
                  </div>
                  <h3 className="text-lg leading-6 text-gray-900 mb-3" id="modal-title">
                    Select tag(s) from the list below to add them to this group.
                  </h3>
                  <div className="grid grid-cols-2 gap-x-2 max-window-height-audio-reviews overflow-y-scroll">
                    {tagsList?.length > 0 &&
                      tagsList
                        .filter((tag: any) => tag.tag_type.toLowerCase() === tagGroup.tag_group_type)
                        .sort((a: any, b: any) => a.name.localeCompare(b.name))
                        .map((item: any) => {
                          return (
                            <button
                              className={`${
                                item.hasTag ? 'bg-blue-primary text-white' : 'bg-gray-background-light text-black'
                              } text-sm font-bold space-y-2 mt-2`}
                              onClick={(e) => {
                                e.preventDefault();
                                handleTag(!item.hasTag, item.id);
                              }}
                            >
                              <div className="px-3 py-2">{item.name}</div>
                            </button>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

interface CreateTagModalProps {
  setShowCreateTagModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateTagModal: React.FC<CreateTagModalProps> = ({ setShowCreateTagModal }) => {
  const { mutate: createTagGroup } = useCreateTagGroup();

  const defaultNewTag = {
    name: '',
    tag_group_type: 'topic',
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: defaultNewTag,
  });

  const onSubmit = (data: any) => {
    createTagGroup(data);
    setShowCreateTagModal(false);
    reset(defaultNewTag);
  };

  return (
    <>
      <Modal
        isModel={true}
        InnerComponent={
          <div className="max-w-3xl border-2 border-black inline-block  bg-white rounded-lg text-left overflow-hidden transform transition-all">
            <div className="h-10 flex px-6 pt-2 justify-between items-center">
              <div>Create New Tag Group</div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} id="create-tag-group">
              <div className="w-full px-3">
                <div className="w-full grid grid-cols-1 gap-x-6 px-7 mt-5 gap-y-6">
                  <div className="flex flex-col space-y-4">
                    <CustomInput
                      inputType="text"
                      Label="Tag Group Name"
                      register={{
                        ...register('name', {
                          required: 'Please provide Tag name',
                          onBlur: (e: any) => onBlur(e, 'name', setValue),
                        }),
                      }}
                      Error={errors?.name?.message?.length ? true : false}
                      ErrorMessage={errors.name?.message}
                    />
                    <UseFormDropDown label={'Status'}>
                      <select className="select-box w-full" defaultValue="published" {...register('tag_group_type')}>
                        <option value="topic">Topic</option>
                        <option value="trait">Trait</option>
                      </select>
                    </UseFormDropDown>
                  </div>
                </div>
              </div>
            </form>
            <div className="w-full flex justify-evenly items-center py-2">
              <button
                className="rounded-full w-20 text-center h-6 text-sm text-white bg-red px-2"
                onClick={() => {
                  reset(defaultNewTag);
                  setShowCreateTagModal(false);
                }}
              >
                CANCEL
              </button>
              <div className={` ${!isDirty && 'cursor-not-allowed'}`}>
                <button
                  className={`rounded-full w-20 text-center h-6 text-sm text-white px-2 ml-2 ${
                    isDirty ? 'bg-bright-blue' : 'bg-gray-background-dark pointer-events-none'
                  }`}
                  type="submit"
                  form="create-tag-group"
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

interface FilterProps {
  count?: number;
  filters: TagGroupFilters;
  setFilters: React.Dispatch<React.SetStateAction<TagGroupFilters>>;
  setSelectedTagGroup: React.Dispatch<React.SetStateAction<TagGroup | undefined>>;
  setShowCreateTagModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Filters: React.FunctionComponent<FilterProps> = ({
  count,
  filters,
  setFilters,
  setSelectedTagGroup,
  setShowCreateTagModal,
}): JSX.Element => {
  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7 h-10 ">
      <div className="w-1/4">
        <Searchbar
          search={(data) => {
            setFilters((filters) => {
              return { ...filters, page: 1, name: data };
            });
          }}
        />
      </div>
      <div className="w-1/2">
        <Paginator
          currentPage={filters.page}
          count={count || 0}
          limit={filters.limit}
          handlePageChange={(page: number) => {
            setSelectedTagGroup(undefined);
            setFilters((filter) => {
              return { ...filter, page: page };
            });
          }}
        />
      </div>
      <div className="w-1/3">
        <div className=" flex">
          <div className="flex justify-between items-center py-4  gray-background-dark">
            {Object.keys(TagTypes).map((item) => (
              <button
                className={
                  'text-sm  px-5 py-1 ' +
                  (TagTypes[item as keyof typeof TagTypes] === filters.type
                    ? 'bg-blue-primary text-white'
                    : 'bg-gray-background-dark text-gray-dark')
                }
                onClick={() => {
                  setFilters((filter) => {
                    return { ...filter, type: TagTypes[item as keyof typeof TagTypes], page: 1 };
                  });
                }}
              >
                {item}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="w-1/3">
        <div className="w-full h-8 flex justify-center">
          <button
            className="bg-white rounded-full h-full py-1 px-2 text-center text-sm"
            onClick={() => {
              setShowCreateTagModal(true);
            }}
          >
            Add Tag Group
          </button>
        </div>
      </div>
    </div>
  );
};

export default TagGroups;
