import React, { useEffect, useMemo, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import Spinner from '../../components/common/spinner';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import SwitchBar from '../../components/switch-bar';
import { Paginator } from '../../components/common/paginator';
import AlertBox from '../../components/route-leaving-prompt/alert-box';
import Modal from '../../components/common/modal';
import { useForm } from 'react-hook-form';
import { Tag, TagRequestParams, useCreateTag, useDeleteTag, useTagsV3, useUpdateTag } from '../../hooks/useTags';
import { CustomInput, UseFormDropDown, UseFormToggle } from '../../components/common';
import { onBlur } from '../../components/common/useForm';
import Searchbar from '../../components/common/searchbar';
import { TagTypes } from './tag-groups';

export const Headeroptions = [
  { heading: 'Tag Groups', path: '/tag-groups' },
  { heading: 'Tags', path: '/tags' },
];

const Tags: React.FunctionComponent = (): JSX.Element => {
  const [filters, setFilters] = useState<TagRequestParams>({
    include_hidden: true,
    limit: 25,
    page: 1,
    tag_type: undefined,
    search: '',
  });
  const { data: tagsList, isLoading } = useTagsV3(filters);
  const { mutate: deleteTag } = useDeleteTag();
  const [selectedTag, setSelectedTag] = useState<Tag | undefined>(tagsList?.data?.[0] || undefined);
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
  const [showCreateTagModal, setShowCreateTagModal] = useState<boolean>(false);

  const handleDelete = () => {
    setShowAlertModal(false);
    deleteTag(selectedTag?.id!);
  };

  const handleSelectedTag = (tag?: Tag) => {
    if (tag) {
      setSelectedTag(tag);
    } else {
      setSelectedTag(tagsList?.data?.[0]);
    }
  };

  useEffect(() => {
    handleSelectedTag();
  }, [tagsList]);

  return (
    <div>
      <Header heading={'Tags'} />
      <SwitchBar heading={Headeroptions} position={1} />
      <Filters
        count={tagsList?.count || undefined}
        filters={filters}
        setFilters={setFilters}
        setShowCreateTagModal={setShowCreateTagModal}
      />
      {isLoading && <Spinner />}
      {tagsList && selectedTag && (
        <div className="flex w-full">
          <TagsList selectedTagId={selectedTag.id} setSelectedTag={setSelectedTag} tags={tagsList.data || []} />
          <TagDetails tag={selectedTag} setShowAlertModal={setShowAlertModal} />
        </div>
      )}
      {showCreateTagModal && <CreateTagModal setShowCreateTagModal={setShowCreateTagModal} />}
      <AlertBox
        visible={showAlertModal}
        handlePrompt={handleDelete}
        closeModal={() => setShowAlertModal(false)}
        titleText={'Alert'}
        contentText={`Are you sure you want to remove the selected Tag Group?`}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Remove'}
      />
    </div>
  );
};

interface TagsListProps {
  selectedTagId: number;
  setSelectedTag: (tag: Tag) => void;
  tags: Tag[];
}

const TagsList: React.FC<TagsListProps> = ({ selectedTagId, setSelectedTag, tags }) => {
  return (
    <div className="w-1/2 px-7">
      <div className="">
        <div className="w-full  border-b-2 border-black font-bold text-gray-dark text-left h-12   grid grid-cols-12  items-center sticky mb-2 ">
          <div className=" font-bold col-span-5">
            <p className="pl-4">Tag Name</p>
          </div>
          <div className=" col-span-3 ">Type </div>
          <div className=" col-span-4 ">Status </div>
        </div>
      </div>
      <div className="pb-10">
        {tags?.slice().map((tag: Tag) => (
          <div
            className={`w-full ${selectedTagId === tag.id ? 'bg-blue-primary  text-white' : 'bg-gray-200 text-gray-dark'} rounded-xl text-left h-20 border-y my-2 grid grid-cols-12  items-center cursor-pointer`}
            onClick={() => {
              setSelectedTag(tag);
            }}
          >
            <div className=" font-bold col-span-5">
              <p className="pl-4 pr-2">{tag.name}</p>
            </div>
            <div className="col-span-3 capitalize"> {tag.tag_type} </div>
            <div className=" col-span-4 flex justify-between mr-2 items-center">
              <div> {`${tag.is_visible ? 'Visible' : 'Hidden'}`}</div>
              <button className="rounded-full h-8 w-8 blue-primary  transform rotate-180 ">
                <img className="h-8 w-8" src={RightArrow} alt="left Arrow"></img>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

interface TagDetailsProps {
  tag: Tag;
  setShowAlertModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const TagDetails: React.FC<TagDetailsProps> = ({ tag, setShowAlertModal }) => {
  const { mutate: updateTag } = useUpdateTag();

  const defaultTag = {
    ...tag,
    is_background: tag.is_background || false,
    is_default: tag.is_default || false,
    is_required: tag.is_required || false,
    is_visible: tag.is_visible || false,
    name: tag.name || '',
    tag_type: tag.tag_type || 'topic',
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: useMemo(() => {
      if (tag) {
        return defaultTag;
      }
    }, [tag]),
  });

  const onSubmit = (data: any) => {
    const payload = {
      ...data,
      id: tag.id,
      is_visible: data.is_visible === 'true' ? true : false,
    };
    updateTag(payload);
  };

  useEffect(() => {
    if (tag) {
      reset(tag);
    }
  }, [tag, reset]);

  return (
    <div className="w-1/2 border-2 border-gray-200 rounded-3xl my-12 mx-7 h-162.5">
      <div className="h-10 flex px-6 pt-2 justify-between items-center">
        <button
          className="rounded-full w-20 text-center h-6 text-sm text-white bg-red px-2"
          onClick={() => {
            setShowAlertModal(true);
          }}
        >
          REMOVE
        </button>
        <div className={` ${!isDirty && 'cursor-not-allowed'}`}>
          <button
            className={`rounded-full w-20 text-center h-6 text-sm text-white px-2 ml-2 ${
              isDirty ? 'bg-bright-blue' : 'bg-gray-background-dark pointer-events-none'
            }`}
            type="submit"
            form="update-tag-group"
          >
            SAVE
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id="update-tag-group">
        <div className="w-full px-3">
          <div className="w-full grid grid-cols-1 gap-x-6 px-7 mt-5 gap-y-6">
            <div className="flex flex-col space-y-4">
              <CustomInput
                inputType="text"
                Label="Tag Name"
                register={{
                  ...register('name', {
                    required: 'Please provide Tag name',
                    onBlur: (e: any) => onBlur(e, 'name', setValue),
                  }),
                }}
                Error={errors?.name?.message?.length ? true : false}
                ErrorMessage={errors.name?.message}
              />
              <div className="flex space-x-2">
                <p>Tag Type:</p>
                <p className="font-semibold capitalize">{tag.tag_type}</p>
              </div>
              <UseFormDropDown label={'Status'}>
                <select className="select-box w-full" defaultValue="unpublished" {...register('is_visible')}>
                  <option value={'true'}>Visible</option>
                  <option value={'false'}>Hidden</option>
                </select>
              </UseFormDropDown>
            </div>
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between items-center">
                <label className="block text-sm leading-4   text-blue-dark mb-2 mr-2">is Required?</label>
                <UseFormToggle
                  id={'is_required'}
                  register={{ ...register('is_required') }}
                  setStatus={(data) => {
                    setValue('is_required', data, {
                      shouldDirty: true,
                    });
                  }}
                  initialstatus={tag.is_required || false}
                ></UseFormToggle>
              </div>
              <div className="flex justify-between items-center mt-2">
                <label className="block text-sm leading-4   text-blue-dark mb-2">is Default? </label>
                <UseFormToggle
                  id={'isDefault?'}
                  register={{ ...register('is_default') }}
                  setStatus={(data: boolean) => {
                    setValue('is_default', data, {
                      shouldDirty: true,
                    });
                  }}
                  initialstatus={tag.is_default || false}
                ></UseFormToggle>
              </div>
              <div className="flex justify-between items-center mt-2">
                <label className="block text-sm leading-4   text-blue-dark mb-2">Requires Documentation</label>
                <UseFormToggle
                  id={'isDefault?'}
                  register={{ ...register('is_background') }}
                  setStatus={(data: boolean) => {
                    setValue('is_background', data, {
                      shouldDirty: true,
                    });
                  }}
                  initialstatus={tag.is_background || false}
                ></UseFormToggle>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

interface CreateTagModalProps {
  setShowCreateTagModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateTagModal: React.FC<CreateTagModalProps> = ({ setShowCreateTagModal }) => {
  const { mutate: createTag } = useCreateTag();

  const defaultNewTag = {
    is_visible: 'false',
    media: null,
    name: '',
    sort_order: 0,
    tag_type: 'topic',
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: defaultNewTag,
  });

  const onSubmit = (data: any) => {
    const payload = {
      ...data,
      is_visible: data.is_visible === 'true' ? true : false,
    };
    createTag(payload);
    setShowCreateTagModal(false);
    reset(defaultNewTag);
  };

  return (
    <>
      <Modal
        isModel={true}
        InnerComponent={
          <div className="max-w-3xl border-2 border-black inline-block  bg-white rounded-lg text-left overflow-hidden transform transition-all">
            <div className="h-10 flex px-6 pt-2 justify-between items-center">
              <div>Create New Tag</div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} id="create-tag-group">
              <div className="w-full px-3">
                <div className="w-full grid grid-cols-1 gap-x-6 px-7 mt-5 gap-y-6">
                  <div className="flex flex-col space-y-4">
                    <CustomInput
                      inputType="text"
                      Label="Tag Name"
                      register={{
                        ...register('name', {
                          required: 'Please provide Tag name',
                          onBlur: (e: any) => onBlur(e, 'name', setValue),
                        }),
                      }}
                      //@ts-ignore
                      Error={errors?.name?.message?.length ? true : false}
                      ErrorMessage={errors.name?.message}
                    />
                    <UseFormDropDown label={'Type'}>
                      <select className="select-box w-full" defaultValue="published" {...register('tag_type')}>
                        <option value="topic">Topic</option>
                        <option value="trait">Trait</option>
                      </select>
                    </UseFormDropDown>
                    <UseFormDropDown label={'Visibility'}>
                      <select className="select-box w-full" defaultValue="published" {...register('is_visible')}>
                        <option value="true">Visible</option>
                        <option value="false">Hidden</option>
                      </select>
                    </UseFormDropDown>
                  </div>
                </div>
              </div>
            </form>
            <div className="w-full flex justify-evenly items-center py-2">
              <button
                className="rounded-full w-20 text-center h-6 text-sm text-white bg-red px-2"
                onClick={() => {
                  reset(defaultNewTag);
                  setShowCreateTagModal(false);
                }}
              >
                CLOSE
              </button>
              <div className={` ${!isDirty && 'cursor-not-allowed'}`}>
                <button
                  className={`rounded-full w-20 text-center h-6 text-sm text-white px-2 ml-2 ${
                    isDirty ? 'bg-bright-blue' : 'bg-gray-background-dark pointer-events-none'
                  }`}
                  type="submit"
                  form="create-tag-group"
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

interface FilterProps {
  count?: number;
  filters: TagRequestParams;
  setFilters: React.Dispatch<React.SetStateAction<TagRequestParams>>;
  setShowCreateTagModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Filters: React.FunctionComponent<FilterProps> = ({
  count,
  filters,
  setFilters,
  setShowCreateTagModal,
}): JSX.Element => {
  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7  h-10 ">
      <div className="w-1/4">
        <Searchbar
          search={(data) => {
            setFilters((filters) => {
              return { ...filters, page: 1, search: data };
            });
          }}
        />
      </div>
      <div className="w-1/2">
        <Paginator
          currentPage={filters.page!}
          count={count || 0}
          limit={filters.limit!}
          handlePageChange={(page: number) => {
            setFilters((filters) => {
              return { ...filters, page: page };
            });
          }}
        />
      </div>
      <div className="w-1/4">
        <div className="flex items-center justify-between">
          <div className="flex justify-between items-center py-4  gray-background-dark">
            {Object.keys(TagTypes).map((item) => (
              <button
                className={
                  'text-sm  px-5 py-1 ' +
                  (TagTypes[item as keyof typeof TagTypes] === filters.tag_type
                    ? 'bg-blue-primary text-white'
                    : 'bg-gray-background-dark text-gray-dark')
                }
                onClick={() => {
                  setFilters((filters) => {
                    return { ...filters, tag_type: TagTypes[item as keyof typeof TagTypes], page: 1 };
                  });
                }}
              >
                {item}
              </button>
            ))}
          </div>
          <div className="w-20">
            <button
              className="bg-white rounded-full w-full h-8 py-1 px-2 text-center text-sm whitespace-nowrap"
              onClick={() => {
                setShowCreateTagModal(true);
              }}
            >
              Add Tag
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tags;
