import React, { useState } from 'react';
import { Spinner } from '../../components/common';
import Header from '../../components/common/global-top-bar';
import SwitchBar from '../../components/switch-bar';
import { Headeroptions, PeerHeaderoptions } from './listener-constants';
import moment from 'moment';
import { paramsToObjects } from '../../components/common/create-params';
import HeadBack from './redirect';
import {
  AvailabilityActual,
  AvailabilityActualsResponse,
  useAvailabilityActuals,
} from '../../hooks/useAvailabilityActuals';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

const OnCall: React.FunctionComponent = (): JSX.Element => {
  const params = paramsToObjects();
  const listenerId = params['listenerRoleId'];
  const listenerName = params['listenerName'];
  const listenerType = params['listenerType'];
  const [startDate, setStartDate] = useState<string | undefined>('');
  const [endDate, setEndDate] = useState<string | undefined>('');
  const { data, isLoading } = useAvailabilityActuals(listenerId, startDate, endDate);
  const timezoneAbbr = moment.tz(moment.tz.guess()).format('z');

  return (
    <div>
      <Header heading={<HeadBack listenerName={listenerName || ''} />} />
      <SwitchBar heading={listenerType === 'peer' ? PeerHeaderoptions : Headeroptions} position={8} forwardParams />
      <DateFilter startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
      <OnCallTable isLoading={isLoading} data={data} timezone={timezoneAbbr} />
    </div>
  );
};

interface DateFilterProps {
  startDate: string | undefined;
  endDate: string | undefined;
  setStartDate: (date: string | undefined) => void;
  setEndDate: (date: string | undefined) => void;
}

const DateFilter: React.FC<DateFilterProps> = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const [clearStartDate, setClearStartDate] = useState(false);
  const [clearEndDate, setClearEndDate] = useState(false);

  return (
    <div className="flex px-5 pt-4">
      <div className="w-full px-2 flex items-end space-x-2">
        <label className="">Start Date:</label>
        <DesktopDatePicker
          value={clearStartDate ? null : startDate}
          onChange={(value) => {
            setClearStartDate(false);
            setStartDate(value ? moment(value).format('YYYY-MM-DD') : undefined);
          }}
          InputProps={{
            disableUnderline: true,
            className:
              'rounded-5px text-gray-900 text-sm font-sans leading-normal h-9 px-2 bg-white border border-lightSilver w-full',
          }}
          renderInput={(params) => (
            <span>
              <TextField id="start_date" {...params} variant="standard" sx={{ mr: 2, width: '9rem' }} />
            </span>
          )}
        />
        <button
          onClick={() => {
            setStartDate(undefined);
            setClearStartDate(true);
          }}
        >
          X
        </button>
      </div>
      <div className="w-full px-2 flex items-end space-x-2">
        <label className="">End Date:</label>
        <DesktopDatePicker
          value={clearEndDate ? null : endDate}
          onChange={(value) => {
            setClearEndDate(false);
            setEndDate(value ? moment(value).format('YYYY-MM-DD') : undefined);
          }}
          InputProps={{
            disableUnderline: true,
            className:
              'rounded-5px text-gray-900 text-sm font-sans leading-normal h-9 px-2 bg-white border border-lightSilver w-full',
          }}
          renderInput={(params) => (
            <span>
              <TextField id="end_date" {...params} variant="standard" sx={{ mr: 2, width: '9rem' }} />
            </span>
          )}
        />
        <button
          onClick={() => {
            setEndDate(undefined);
            setClearEndDate(true);
          }}
        >
          X
        </button>
      </div>
    </div>
  );
};

interface OnCallTableProps {
  isLoading: boolean;
  data?: AvailabilityActualsResponse;
  timezone: string;
}

const OnCallTable: React.FC<OnCallTableProps> = ({ isLoading, data, timezone }) => (
  <div className="max-window-height-table  overflow-y-auto  px-7 pt-5 pb-10">
    <table className="table-fixed table-row-format w-full relative border-collapse">
      <thead className="bg-white h-full ">
        <tr className=" text-left w-full px-7">
          <th className="px-2 py-2 w-4/12 sticky top-0 bg-white h-full mb-4">
            When <span className="text-sm font-normal">({timezone})</span>
          </th>
          <th className="px-2 py-2 w-4/12 sticky top-0 bg-white h-full mb-4">
            Pay Duration <span className="text-sm font-normal">({timezone})</span>
          </th>
          <th className="px-2 py-2 w-4/12 sticky top-0 bg-white h-full mb-4">
            Schedule Snapshot <span className="text-sm font-normal">({timezone})</span>
          </th>
        </tr>
      </thead>
      {isLoading ? (
        <Spinner />
      ) : (
        <tbody>
          <tr className="h-2"></tr>
          {data &&
            data.data.length > 0 &&
            data.data
              .sort((a, b) => new Date(b.started_at).getTime() - new Date(a.started_at).getTime())
              .map((availability_actuals: AvailabilityActual) => {
                return (
                  <tr className="bg-white text-gray-dark text-left font-montserrat h-12 border-b border-gray-dark space-y-6">
                    <td className="py-1 w-4/12 align-top">
                      <div className="flex items-center space-x-2">
                        <div className="font-bold">Start Time:</div>
                        <div>{moment.utc(availability_actuals.started_at).local().format('MM/DD/YY, h:mmA')}</div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="font-bold">End Time:</div>
                        <div>{moment.utc(availability_actuals.ended_at).local().format('MM/DD/YY, h:mmA')}</div>
                      </div>
                    </td>
                    <td className="py-1 w-4/12 align-top">
                      <div className="flex items-center space-x-2">
                        <div className="font-bold">Pay Start:</div>
                        <div>
                          {moment.utc(availability_actuals.adjusted_started_at).local().format('MM/DD/YY, h:mmA')}
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="font-bold">Pay End:</div>
                        <div>
                          {moment.utc(availability_actuals.adjusted_ended_at).local().format('MM/DD/YY, h:mmA')}
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="font-bold">Pay Duration (hh:mm:ss):</div>
                        <div>
                          {new Date(availability_actuals.duration_during_schedule * 1000).toISOString().slice(11, 19)}
                        </div>
                      </div>
                    </td>
                    <td className="py-1 w-4/12 align-top">
                      {availability_actuals.schedule_snapshot &&
                        availability_actuals.schedule_snapshot.map((schedule_snapshot) => {
                          return (
                            <div className="flex items-center space-x-2">
                              <div className="font-bold">
                                {moment.utc(schedule_snapshot.start_utc).local().format('MM/DD/YY')}:
                              </div>
                              <div>
                                {moment.utc(schedule_snapshot.start_utc).local().format('h:mmA')} -{' '}
                                {`${moment.utc(schedule_snapshot.end_utc).local().format('h:mmA')}${
                                  moment
                                    .utc(schedule_snapshot.end_utc)
                                    .startOf('day')
                                    .diff(moment.utc(schedule_snapshot.start_utc).startOf('day'), 'days') > 0
                                    ? ` +${moment
                                        .utc(schedule_snapshot.end_utc)
                                        .startOf('day')
                                        .diff(moment.utc(schedule_snapshot.start_utc).startOf('day'), 'days')}`
                                    : ''
                                }`}
                              </div>
                            </div>
                          );
                        })}
                    </td>
                  </tr>
                );
              })}
        </tbody>
      )}
    </table>
  </div>
);

export default OnCall;
