import React, { useEffect, useState, useMemo } from 'react';
import { Spinner } from '../../../components/common';
import Header from '../../../components/common/global-top-bar';
import SwitchBar from '../../../components/switch-bar';
import { HeaderOptions } from './listener-review-constants';
import { useHistory } from 'react-router-dom';
import RouteLeavingGuard from '../../../components/route-leaving-prompt';
import { ROUTE_PATH } from '../../../routes/paths';
import Searchbar from '../../../components/common/searchbar';
import { toast } from 'react-toastify';
import { Paginator } from '../../../components/common/paginator';
import {
  ListenerAudio,
  ListenerAudioRequestParams,
  ListenerAudioResponse,
  useGradeAudioMutation,
  useAllListenerAudio,
  useUpdateAudioMutation,
} from '../../../hooks/useListenerAudio';
import { Tag, TagExtended } from '../../../hooks/useTags';
import {
  audioGrades,
  defaultExperienceData,
  ExperienceDetails,
  ExperiencePayloadData,
  ExperienceTile,
} from '../../../components/experience-review/experience-review';
import { useSearchForSupportTags } from '../../../hooks/useTagGroups';

interface UnsavedChanges {
  modalVisible: boolean;
  isDirty: boolean;
  isTopicsDirty: boolean;
  promptId?: number;
}

const defaultErrorMessages = {
  message: '',
  description: '',
};

const defaultUnsavedChanges = {
  modalVisible: false,
  isDirty: false,
  isTopicsDirty: false,
};

interface ExperienceReviewProps {
  experienceType: 'audio' | 'video';
}

const ExperienceReview: React.FunctionComponent<ExperienceReviewProps> = ({ experienceType }): JSX.Element => {
  const history = useHistory();
  const { mutate: gradeAudio } = useGradeAudioMutation();
  const { mutate: updateAudio } = useUpdateAudioMutation();
  const { data: initialTopicTags } = useSearchForSupportTags();
  const [topicTags, setTopicTags] = useState<Tag[]>([]);
  const [selectedExperience, setSelectedExperience] = useState<ListenerAudio | undefined>(undefined);
  const [experienceData, setExperienceData] = useState<ExperiencePayloadData>(defaultExperienceData);
  const [redirectPath, setRedirectPath] = useState<string>('');
  const [errors, setErrors] = useState<{ message: string; description: string }>(defaultErrorMessages);
  const [unsavedChanges, setUnsavedChanges] = useState<UnsavedChanges>(defaultUnsavedChanges);

  // Filters + Audio fetch
  const [filters, setFilters] = useState<ListenerAudioRequestParams>({
    page: 1,
    limit: 25,
    search: '',
    grade: audioGrades.Ungraded,
    experience_type: experienceType,
    hide_incomplete_peers: false,
    order_by: 'created_at',
    order_by_direction: 'desc',
  });
  const adjustedFilters = useMemo(() => {
    const { grade, ...rest } = filters;
    return grade === 'all' ? rest : filters;
  }, [filters]);
  const { data: listenerAudio, isLoading } = useAllListenerAudio(adjustedFilters);

  const processTags = (experience: ListenerAudio) => {
    /*
    This function adds the hasTag property to the topic tags based on the selected experience's existing tags.
    */
    if (initialTopicTags && initialTopicTags.length > 0 && experience?.topic_tag_ids !== undefined) {
      const transformedTags: Tag[] = initialTopicTags.map((item: Tag) => ({
        ...item,
        hasTag: !!experience.topic_tag_ids.includes(item.id),
      }));
      setTopicTags(transformedTags);
    }
  };

  const handleSelectedExperience = (experience?: ListenerAudio) => {
    /*
    This function is called when:
      1. The user changes the filters
      2. The user selects an experience from the list
      3. The user invalidates the audio data (e.g. by submitting a grade / experience update)
    It will:
      1. Try to maintain the selected experience if it still exists in the new data.
      2. If the selected experience doesn't exist, it will select the first ungraded experience.
      3. If there are no ungraded experiences, it will select the first experience.
      4. In all scenarios, it will process the tags for the selected experience and set the unsaved changes modal to the appropriate id.
    */
    if (experience) {
      setSelectedExperience(experience);
      processTags(experience);
      setUnsavedChanges((prev) => ({ ...prev, promptId: experience.id }));
    } else {
      const ungraded = listenerAudio?.data.find((audio: ListenerAudio) => audio.message_quality === 7);
      const matchingExperience = listenerAudio?.data.find(
        (audio: ListenerAudio) => audio.id === selectedExperience?.id,
      );
      if (selectedExperience && matchingExperience) {
        setSelectedExperience(matchingExperience);
        processTags(matchingExperience);
        setUnsavedChanges((prev) => ({ ...prev, promptId: matchingExperience.id }));
      } else if (listenerAudio) {
        setSelectedExperience(ungraded || listenerAudio.data[0]);
        processTags(ungraded || listenerAudio.data[0]);
        setUnsavedChanges((prev) => ({ ...prev, promptId: ungraded?.id || listenerAudio?.data[0]?.id || undefined }));
      }
    }
  };

  // Validation
  const validateGradeSubmission = (sendMessage: boolean) => {
    /*
    This essentially just validates to tell the admin if the system comm they've selected is blank, an unlikely scenario.
    */
    if ((sendMessage && experienceData.message?.trim()?.length > 0) || !sendMessage) {
      setErrors((prev: any) => ({
        ...prev,
        message: '',
      }));
      return true;
    } else {
      setErrors((prev: any) => ({
        ...prev,
        message: 'The system communication selected has no message, please select another',
      }));
      return false;
    }
  };

  const validateExperience = () => {
    const tagsToSubmit = topicTags?.filter((tag: TagExtended) => tag.hasTag && tag.name !== 'My Story');
    if (tagsToSubmit.length <= 0) {
      toast.error('Experiences must have at least 1 topic tag');
      return false;
    } else if (tagsToSubmit.length > 5) {
      console.log(tagsToSubmit);
      toast.error('Experiences must have no more than 5 topic tags');
      return false;
    } else if (experienceData.excerpt?.trim()?.length > 0) {
      setErrors((prev: any) => ({
        ...prev,
        description: '',
      }));
      return true;
    } else {
      setErrors((prev: any) => ({
        ...prev,
        description: 'Please Enter a description to proceed',
      }));
      return false;
    }
  };

  // Submission
  const submitGrade = () => {
    const sendMessage = !!(experienceData.messageId !== 'none' && experienceData.grade !== 'approved');
    if (selectedExperience && experienceData.gradeUpdated && validateGradeSubmission(sendMessage)) {
      gradeAudio({ listenerId: selectedExperience.listener_role_id, audioId: selectedExperience.id, experienceData });
    }
  };

  const submitExperience = () => {
    if (selectedExperience && experienceData.topicsUpdated && validateExperience()) {
      setErrors(defaultErrorMessages);
      updateAudio({
        listenerId: selectedExperience.listener_role_id,
        audioId: selectedExperience.id,
        selectedExperience,
        experienceData,
        topicTags,
      });
      setExperienceData((prev: any) => ({
        ...prev,
        topicsUpdated: false,
        gradeUpdated: false,
      }));
      setUnsavedChanges(defaultUnsavedChanges);
    }
  };

  useEffect(() => {
    handleSelectedExperience();
    if (redirectPath?.length > 0) {
      history.push(redirectPath);
      setRedirectPath('');
    }
    setUnsavedChanges(defaultUnsavedChanges);
  }, [filters, listenerAudio?.data, initialTopicTags]); // eslint-disable-line

  return (
    <div className="px-">
      {isLoading && <Spinner />}
      <Header heading={experienceType === 'audio' ? 'Audio' : 'Video'} />
      <SwitchBar heading={HeaderOptions} position={experienceType === 'audio' ? 0 : 1} />
      <Filters
        count={listenerAudio?.count || 0}
        filters={filters}
        setFilters={setFilters}
        isDirty={unsavedChanges.isDirty}
        setUnsavedChanges={setUnsavedChanges}
      />
      <div className="max-window-height pb-32 pt-4 overflow-y-auto">
        <div className="px-7 flex space-x-4 py-5">
          {listenerAudio && listenerAudio.count > 0 && (
            <ExperienceList
              listenerAudio={listenerAudio}
              selectedExperience={selectedExperience}
              handleSelectedExperience={handleSelectedExperience}
              unsavedChanges={unsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
              experienceType={experienceType}
            />
          )}
          <div className="w-1/2 px-3">
            {listenerAudio && listenerAudio.count > 0 && selectedExperience && topicTags && topicTags.length > 0 && (
              <ExperienceDetails
                key={selectedExperience.id || 0}
                selectedExperience={selectedExperience}
                experienceData={experienceData}
                setExperienceData={setExperienceData}
                topicTags={topicTags}
                setTags={setTopicTags}
                setIsDirty={(isDirty: boolean) => setUnsavedChanges((prev: UnsavedChanges) => ({ ...prev, isDirty }))}
                setTopicsDirty={(isTopicsDirty: boolean) =>
                  setUnsavedChanges((prev: UnsavedChanges) => ({
                    ...prev,
                    isTopicsDirty,
                  }))
                }
                submitGrade={submitGrade}
                errors={errors}
                isDirty={unsavedChanges.isDirty || unsavedChanges.isTopicsDirty}
                submit={submitExperience}
                experienceType={experienceType}
              />
            )}
          </div>
        </div>
      </div>
      <RouteLeavingGuard
        when={experienceData.gradeUpdated || experienceData.topicsUpdated}
        navigate={(path: string) => {
          history.push(path);
        }}
        shouldBlockNavigation={(location: any) => {
          if (location.pathname !== ROUTE_PATH.LISTENER_AUDIO_REVIEW) {
            return true;
          }
          return false;
        }}
        forceShowModal={unsavedChanges.modalVisible}
        titleText={'Alert'}
        contentText={'You have unsaved changes.  If you leave this screen without saving, your changes will be lost.'}
        cancelButtonText="Cancel"
        confirmSaveButtonText={'Save Changes'}
        confirmButtonText={'Disregard Changes'}
        handleContinueSaveChange={(path: string) => {
          const sendMessage = !!(experienceData.messageId !== 'none' && experienceData.grade !== 'approved');
          if (validateExperience() && validateGradeSubmission(sendMessage)) {
            if (unsavedChanges.promptId) {
              const experience = listenerAudio?.data.find(
                (audio: ListenerAudio) => audio.id === unsavedChanges.promptId,
              );
              handleSelectedExperience(experience);
            } else {
              setRedirectPath(path);
            }
            setExperienceData((prev: any) => ({
              ...prev,
              topicsUpdated: false,
              gradeUpdated: false,
            }));
            submitExperience();
            experienceData.gradeUpdated && submitGrade();
          }
        }}
        additionalHandlePrompt={() => {
          const experience = listenerAudio?.data.find((audio: ListenerAudio) => audio.id === unsavedChanges.promptId);
          handleSelectedExperience(experience);
          setExperienceData((prev: any) => ({
            ...prev,
            topicsUpdated: false,
            gradeUpdated: false,
          }));
          setUnsavedChanges(defaultUnsavedChanges);
        }}
      />
    </div>
  );
};

type FilterProps = {
  count: number | undefined;
  filters: ListenerAudioRequestParams;
  setFilters: React.Dispatch<React.SetStateAction<ListenerAudioRequestParams>>;
  isDirty: boolean;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<UnsavedChanges>>;
};

const Filters: React.FunctionComponent<FilterProps> = ({
  count,
  filters,
  setFilters,
  isDirty,
  setUnsavedChanges,
}): JSX.Element => {
  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7  h-10 ">
      <div className="w-1/4">
        <div className="w-full h-8">
          <Searchbar
            search={(data) => {
              setFilters((prev: ListenerAudioRequestParams) => {
                return { ...prev, page: 1, search: data };
              });
            }}
          />
        </div>
      </div>
      <div className="w-1/3">
        <Paginator
          count={count!}
          limit={25}
          currentPage={filters.page || 1}
          handlePageChange={(page) =>
            isDirty
              ? setUnsavedChanges((prev: UnsavedChanges) => ({
                  ...prev,
                  modalVisible: true,
                }))
              : setFilters((prev: ListenerAudioRequestParams) => {
                  return { ...prev, page: page };
                })
          }
        />
      </div>
      <div className="w-1/3">
        <div className=" flex justify-end">
          <div className="flex justify-between items-center py-4  gray-background-dark">
            {(Object.keys(audioGrades) as Array<keyof typeof audioGrades>).map((item) => (
              <button
                className={
                  ' text-sm  px-5 py-1 ' +
                  (audioGrades[item] === filters.grade
                    ? 'bg-blue-primary text-white'
                    : 'bg-gray-background-dark text-gray-dark')
                }
                onClick={() => {
                  setFilters((prev: ListenerAudioRequestParams) => {
                    const grade = audioGrades[item];
                    return {
                      ...prev,
                      grade: grade,
                      order_by:
                        grade === audioGrades.All || grade === audioGrades.Ungraded ? 'created_at' : 'graded_at',
                      page: 1,
                    };
                  });
                }}
              >
                {item}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

interface ExperienceListProps {
  listenerAudio: ListenerAudioResponse;
  selectedExperience: ListenerAudio | undefined;
  handleSelectedExperience: (experience?: ListenerAudio) => void;
  unsavedChanges: UnsavedChanges;
  setUnsavedChanges: (value: React.SetStateAction<UnsavedChanges>) => void;
  experienceType: 'audio' | 'video';
}

const ExperienceList: React.FC<ExperienceListProps> = ({
  listenerAudio,
  selectedExperience,
  handleSelectedExperience,
  unsavedChanges,
  setUnsavedChanges,
  experienceType,
}) => {
  return (
    <div className="w-1/2">
      <div className="flex justify-between border-b border-gray-500 pb-2 cursor-pointer">
        <p className="font-bold text-gray-dark text-left">
          {experienceType === 'audio' ? 'Audio' : 'Video'} Experiences
        </p>
      </div>
      <div className="max-window-height-audio-reviews overflow-y-scroll">
        {listenerAudio &&
          listenerAudio.count > 0 &&
          listenerAudio.data.map((audio: ListenerAudio) => (
            <ExperienceTile
              key={audio.id}
              experience={audio}
              selectedExperience={selectedExperience}
              handleSelectedExperience={handleSelectedExperience}
              unsavedChanges={unsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ))}
      </div>
    </div>
  );
};

export default ExperienceReview;
