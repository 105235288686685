import { useQuery, useMutation, useQueryClient, QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { axiosDelete, axiosGet, axiosPost, axiosPut } from '../api/axios';
import urls from '../api/urls';
import { toast } from 'react-toastify';

export interface User {
  administrator_role?: AdministratorRole;
  spirituality?: string;
  street_address_2: string;
  id: number;
  proposed_display_name: any;
  client_administrator_role_id: any;
  api_key: string;
  how_did_you_hear: string;
  date_of_birth: string;
  display_name_rejected: boolean;
  needs_password: boolean;
  user_subscription: any;
  first_name: string;
  mobile_phone_verification_started_at: any;
  mobile_phone_verification_token: any;
  test_user: boolean;
  zip_code: string;
  mobile_phone: string;
  listener_role: any;
  caller_role?: CallerRole;
  languages: any[];
  state?: string;
  client_administrator_role: any;
  relationship_type: string;
  pronoun?: string;
  relationship?: string;
  status: string;
  family?: string;
  // this is only used when updating a password and is not returned from the API
  password?: string;
  mobile_phone_verified: boolean;
  email_address: string;
  military_role: any;
  counseling_configuration: any;
  street_address_1: string;
  military_branch: any;
  password_reset_token?: string;
  created_at: string;
  last_active_at: string;
  access_role?: string;
  is_partial: boolean;
  city: string;
  administrator_role_id?: number;
  password_reset_token_created_at?: string;
  dialcare_processing_date: any;
  current_app_version: any;
  timezone: string;
  last_name: string;
  gender?: string;
  caller_role_id?: number;
  listener_role_id: any;
  is_text_compatible_phone: boolean;
  race?: string;
  display_name: string;
  client_name?: string;
  client_id?: number;
  hasCompletedOnboarding: boolean;
}

export interface AdministratorRole {
  type: string;
}

export interface CallerRole {
  type: string;
  notifications_resources: boolean;
  notifications_listener_online: boolean;
  id: number;
  stripe_payment_failed: boolean;
  tag_ids: any;
  stripe_payment_failure_code: any;
  notifications_incoming_call: boolean;
  tag_group_ids: any[];
  total_available_credit: number;
  listener_preferences: ListenerPreferences;
  notifications_check_ins: boolean;
  created_at: string;
  stripe_customer_id: any;
  stripe_payment_method_id: any;
  status: string;
}

export interface ListenerPreferences {
  topics: number[];
  genders: Genders;
  age_range: AgeRange;
  topic_tags: number[];
  preferred_language: string;
}

export interface Genders {
  male: boolean;
  other: boolean;
  female: boolean;
}

export interface AgeRange {
  to: number;
  from: number;
}

const getUsers = async (page: number, search: string): Promise<User[]> => {
  return await axiosGet(`/users/?limit=10&page=${page}&search=${encodeURIComponent(search)}`, null, 'v2').then(
    (userResponse) => userResponse.data,
  );
};

const getUser = async (userId?: number | undefined): Promise<User> => {
  return await axiosGet(`/users/${userId || 'me'}`, null, 'v2').then((userResponse) => userResponse.data);
};

const updateUserRequest = async (userId: number, data: any): Promise<User> => {
  return await axiosPut(`/users/${userId}`, data, 'v2').then((response: { data: any }) => response.data);
};

export interface UpdateListenerProfilePayload {
  system_message_id: number;
  send_message: boolean;
  message: string;
  subject: string;
}

const updateListenerProfileRequest = async (
  listenerId: number,
  type: string,
  payload?: UpdateListenerProfilePayload,
): Promise<User> => {
  return await axiosPost(`/users/listener_profile/${listenerId}/${type}`, payload, 'v2').then(
    (response: { data: any }) => response.data,
  );
};

export const useUpdateListenerProfile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ listenerId, type, payload }: { listenerId: number; type: string; payload?: UpdateListenerProfilePayload }) =>
      updateListenerProfileRequest(listenerId, type, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['peers']);
        toast.success('Profile updated successfully!');
      },
      onError: () => {
        queryClient.invalidateQueries(['peers']);
        toast.error('Profile update failed');
      },
    },
  );
};

export interface CompleteOnboardingPayload {
  email_address: string;
  first_name: string;
  last_name: string;
  password: string;
  userId?: number;
}

export interface ResendOnboardingLinkPayload {
  token: string;
  userId: number;
}

const resendOnboardingLink = async (input: ResendOnboardingLinkPayload): Promise<boolean> => {
  const data = {
    token: input.token,
  };
  const url = `${urls.user}/${input.userId}/resend_switchboard_onboarding_link`;
  return await axiosPost(url, data)
    .then((results: any) => {
      console.log('results', results);
      return results.data;
    })
    .catch((error: any) => {
      console.error(error);
    });
};

export const useHandleResendEmail = () => {
  return useMutation((input: ResendOnboardingLinkPayload) => resendOnboardingLink(input));
};

const completeOnboarding = async (input: CompleteOnboardingPayload): Promise<User> => {
  const url = `${urls.user}/${input.userId}/client_administrator_role/complete`;
  delete input['userId'];
  return await axiosPost(url, input).then((response: { data: User }) => {
    return response.data;
  });
};

export const useCompleteOnboarding = () => {
  const queryClient = useQueryClient();
  return useMutation<User, any, CompleteOnboardingPayload, any>(completeOnboarding, {
    onSuccess: () => {
      queryClient.invalidateQueries(['me']);
    },
    onError: () => {
      queryClient.invalidateQueries(['me']);
    },
  });
};

export const useUsers = ({ page, search }: { page: number; search: string }) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<User[]>(['Users', page, search], () =>
    getUsers(page, search),
  );

  return {
    data,
    isLoading,
    error,
    refetch,
    isFetching,
  };
};

export const useUser = (userId: number) => {
  const { data, isLoading, error, refetch } = useQuery<User>(['user', userId], () => getUser(userId));

  return {
    data,
    isLoading,
    error,
    refetch,
  };
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const updateUser = useMutation(
    ({ userId, data }: { userId: number; data: any }) => {
      if (userId) {
        return updateUserRequest(userId, data);
      }
      return Promise.reject(new Error('No userId provided'));
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['Users']);
        queryClient.invalidateQueries(['peers']);
        console.log('just updated user', data);
      },
      onError: (error) => {
        queryClient.invalidateQueries(['Users']);
        console.log(error);
      },
      onMutate: ({ userId, data }) => {
        if (userId) {
          queryClient.setQueryData(['Users'], (oldData: any) => {
            if (oldData) {
              return oldData.map((user: User) => {
                if (user.id === userId) {
                  return { ...user, ...data };
                }
                return user;
              });
            }
            return oldData;
          });
        }
      },
    },
  );

  return {
    updateUser,
  };
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const deleteUser = useMutation((userId: number) => axiosDelete(`/users/${userId}`, null, 'v2'), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Users']);
    },
    onError: () => {
      queryClient.invalidateQueries(['Users']);
    },
  });

  return deleteUser;
};

export const useMyUser = (authToken: string | null) => {
  const query = useQuery<User>(
    ['me'],
    () => {
      // onSuccess handler is deprecated and was invoked even when using a cached query,
      // so moving invalidation here reduces unnecessary invalidaton + additional requests
      return getUser();
    },
    {
      // refetch user every 30 minutes just to be safe. this could potentially be Infinity
      staleTime: 1000 * 60 * 30,
      select: (data) => {
        return {
          ...data,
          hasCompletedOnboarding:
            data && (Boolean(data.administrator_role) || data.client_administrator_role?.status === 'enabled'),
        };
      },
      //@TODO, useState is not immediately updated, so using for enabled here
      enabled: Boolean(authToken),
      onError: (err: any) => {
        // if this responds with a 403, it will keep repeating, need to shut that down
        console.error(err);
      },
    },
  );

  return query;
};

const getEmailAddressAvailable = async (email: string): Promise<boolean> => {
  const encodedEmail = encodeURIComponent(email);
  return await axiosGet(`/users/email_address_available?email_address=${encodedEmail}`, null, 'v2').then(
    (response) => response.data,
  );
};

export const useEmailAddressAvailable = (
  email: string,
  options?:
    | (Omit<UseQueryOptions<boolean, unknown, boolean, QueryKey>, 'initialData' | 'queryFn' | 'queryKey'> & {
        initialData?: () => undefined;
      })
    | undefined,
) => {
  return useQuery<boolean>(['emailAddressAvailable', email], () => getEmailAddressAvailable(email), options);
};
