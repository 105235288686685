import moment from 'moment';
import { Spinner } from '../../../components/common';
import { convertObjectToParams } from '../../../components/common/create-params';
import {
  useCallOutcomesReport,
  CallOutcomesReport,
  CallFailReason,
  CallOutcome,
} from '../../../hooks/useCallReporting';
import { ROUTE_PATH } from '../../../routes/paths';
import { CallReportFilters } from './common/call-report-constants';

const callFailReasonMap: Record<CallFailReason, string> = {
  member_cancelled: 'Member Cancelled',
  member_rescheduled: 'Member Rescheduled',
  member_missed: 'Member Missed Call',
  member_voicemail: 'Member Voicemail',
  listener_missed: 'Listener Missed Call',
  listener_passed: 'Listener Passed Call',
  listener_timeout: 'Listener Timeout',
  listener_voicemail: 'Listener Voicemail',
  short_call: 'Short Call',
  unknown: 'Unknown',
};

const callOutcomeMap: Record<CallOutcome, string> = {
  success: 'Success',
  member_failed: 'Member Failed',
  listener_failed: 'Listener Failed',
};

interface CallDetailsProps {
  filters: CallReportFilters;
}

export const CallsDetails: React.FC<CallDetailsProps> = ({ filters }) => {
  const { data: callOutcomesReport, isLoading } = useCallOutcomesReport(filters);
  const formatOutcome = (outcome: CallOutcome): string => {
    return callOutcomeMap[outcome] || 'Unknown';
  };

  const formatFailReason = (failReason: CallFailReason): string => {
    return callFailReasonMap[failReason] || 'Unknown';
  };

  const formatCallDuration = (seconds: number) => {
    const hrs = Math.floor(seconds / 3600).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    const mins = Math.floor((seconds % 3600) / 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const secs = (seconds % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    return `${hrs}:${mins}:${secs}`;
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Calls Details Report</h1>
      {isLoading && <Spinner />}
      {callOutcomesReport?.data.map((call: CallOutcomesReport) => (
        <div
          key={call.call_id_fmt}
          className="w-full bg-gray-200 rounded text-gray-dark text-left min-h-48 border-b-8 border-white space-y-6 space-x-2 flex justify-between"
        >
          <div className="w-1/4 pl-6 pt-6 space-y-1 select-none">
            <p className="text-neutral-600 text-xs uppercase">Call ID</p>
            <p className="text-black text-base font-bold">{call.call_id_fmt}</p>
            <p className="text-neutral-600 text-xs uppercase">Call Type</p>
            <p className="text-black text-base font-bold">{call.is_scheduled_fmt}</p>
            <p className="text-neutral-600 text-xs uppercase">Call Start</p>
            <div className="flex items-end space-x-2 whitespace-nowrap">
              <p className="text-black text-base font-bold">
                {moment.utc(call.call_start).local().format('MMM DD, YYYY HH:mm:ss')}
              </p>
              <p className="text-neutral-600 text-base">({moment.tz(moment.tz.guess()).format('z')})</p>
            </div>
          </div>
          <div className="w-1/4 space-y-1">
            <p className="text-neutral-600 text-xs uppercase select-none">Client</p>
            <p
              className="text-black text-base font-bold cursor-pointer"
              onClick={() => {
                localStorage.setItem('clientid', call.client_id.toString());
                window.open(ROUTE_PATH.REPORTING, '_blank');
              }}
            >
              {call.client_name}
            </p>
            <p className="text-neutral-600 text-xs uppercase select-none">Member</p>
            <p
              className="text-black text-base font-bold cursor-pointer"
              onClick={() => {
                window.open(
                  ROUTE_PATH.MEMBERS_PROFILE +
                    convertObjectToParams({
                      callerRoleId: call.caller_role_id,
                    }),
                  '_blank',
                );
              }}
            >
              {call.member_name}
            </p>
            <p className="text-neutral-600 text-xs uppercase select-none">Listener</p>
            <p
              className="text-black text-base font-bold cursor-pointer"
              onClick={() => {
                window.open(
                  ROUTE_PATH.LISTENERS_PROFILE +
                    convertObjectToParams({
                      listenerRoleId: call.listener_role_id,
                      listenerId: call.listener_role.user.id,
                    }),
                  '_blank',
                );
              }}
            >
              {call.listener_name}
            </p>
          </div>
          <div className="w-1/4 space-y-1 select-none">
            <p className="text-neutral-600 text-xs uppercase">Outcome</p>
            <div className="flex">
              <p
                className={`text-white text-base font-bold px-1 ${call.outcome === 'success' ? 'bg-green' : 'bg-red'} flex w-content rounded-sm`}
              >
                {formatOutcome(call.outcome as CallOutcome)}
              </p>
            </div>
            {call.outcome !== 'success' ? (
              <>
                <p className="text-neutral-600 text-xs uppercase">Failure Reason</p>
                <p className="text-black text-base font-bold">{formatFailReason(call.fail_reason as CallFailReason)}</p>
              </>
            ) : (
              <div className="h-11"></div>
            )}
            <p className="text-neutral-600 text-xs uppercase">Crisis</p>
            <p className="text-black text-base font-bold">{call.is_crisis_fmt}</p>
          </div>
          <div className="w-1/4 space-y-1 pr-1">
            <p className="text-neutral-600 text-xs uppercase">Duration</p>
            <p className="text-black text-base font-bold">{formatCallDuration(call.duration)}</p>
            <p className="text-neutral-600 text-xs uppercase">Member Call Topics</p>
            <p className="text-black text-base flex flex-wrap gap-1 flex-wrap">
              {call.member_tag_groups ? (
                call.member_tag_groups.map((tag, index) => {
                  return (
                    <div
                      key={index}
                      className="bg-blue-primary text-white rounded-md text-sm font-bold px-1 py-0.5 w-content whitespace-nowrap"
                    >
                      {tag}
                    </div>
                  );
                })
              ) : (
                <p className="text-black text-base font-bold">N/A</p>
              )}
            </p>
            <p className="text-neutral-600 text-xs uppercase">Listener Call Topics</p>
            <p className="text-black text-base flex flex-wrap gap-1 pb-4">
              {call.listener_topic_tags ? (
                call.listener_topic_tags.map((tag, index) => {
                  return (
                    <div
                      key={index}
                      className="bg-blue-primary text-white rounded-md text-sm font-bold px-1 py-0.5 w-content whitespace-nowrap"
                    >
                      {tag}
                    </div>
                  );
                })
              ) : (
                <p className="text-black text-base font-bold">N/A</p>
              )}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
