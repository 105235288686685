import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RejectModalDataType, SystemMessageResponseType } from './types';
import { CustomInput, DropDown, ToggleButton } from '../../../components/common';
import Modal from '../../../components/common/modal';
import { SystemMessage, useSystemMessages } from '../../../hooks/useComms';

interface RejectMessageModalProps {
  messagePopup: boolean;
  handleModalCancel: () => void;
  handleModalSave: (rejectModalData: RejectModalDataType) => void;
}

const RejectMessageModal: React.FunctionComponent<RejectMessageModalProps> = ({
  messagePopup,
  handleModalCancel,
  handleModalSave,
}): JSX.Element => {
  const { data: systemMessages } = useSystemMessages();
  const displayNameRejectedMessages = systemMessages?.filter(
    (item: SystemMessage) => item.group?.key === 'display_name_rejected',
  );
  const [rejectModalData, setRejectModalData] = useState<RejectModalDataType>();

  if (!rejectModalData && displayNameRejectedMessages) {
    setRejectModalData({
      send_message: true,
      system_message_id: displayNameRejectedMessages?.[0]?.id,
      subject: displayNameRejectedMessages?.[0]?.subject,
      message: displayNameRejectedMessages?.[0]?.message,
    });
  }

  return (
    <Modal
      isModel={messagePopup}
      InnerComponent={
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="flow-root">
              <div className="w-48 float-left sm:ml-4 sm:text-left">
                <div className="flex justify-between items-center">
                  <label className="block text-sm leading-4 text-blue-dark mb-2"> Send Message </label>

                  <ToggleButton
                    id={'Send Message'}
                    status={rejectModalData?.send_message}
                    setStatus={(data) => {
                      setRejectModalData((prev: any) => ({
                        ...prev,

                        send_message: data,
                      }));
                    }}
                    initialstatus={true}
                  ></ToggleButton>
                </div>
              </div>
              <div className="float-right w-80">
                <DropDown
                  Label=""
                  value={rejectModalData?.system_message_id || systemMessages?.[0]?.id}
                  setValue={(data) => {
                    const selectedMessage = displayNameRejectedMessages?.find(
                      (item: SystemMessageResponseType) => item.id === Number(data),
                    );
                    setRejectModalData((prev: any) => {
                      if (!selectedMessage) {
                        return prev;
                      }
                      return {
                        ...prev,
                        system_message_id: selectedMessage.id,
                        subject: selectedMessage.subject,
                        message: selectedMessage.message,
                      };
                    });
                  }}
                >
                  {displayNameRejectedMessages?.map((item: SystemMessage) => (
                    <option key={item.name} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </DropDown>
              </div>
            </div>
            <div className="sm:flex sm:items-start">
              <div className="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <div className="w-full  my-2">
                  <CustomInput
                    inputType={'text'}
                    value={rejectModalData?.subject}
                    setValue={(data) => {
                      setRejectModalData((prev: any) => ({
                        ...prev,

                        subject: data,
                      }));
                    }}
                    Label={'Subject'}
                    labelClass={'block text-sm leading-4 text-blue-dark mb-2'}
                  />
                </div>

                <h3 className="block text-sm leading-4 text-blue-dark mb-2" id="modal-title">
                  Body
                </h3>

                <div className="w-full my-2">
                  <textarea
                    className="py-2 px-3 w-full rounded-md h-28 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none"
                    value={rejectModalData?.message}
                    onChange={(evt) => {
                      setRejectModalData((prev: any) => ({
                        ...prev,

                        message: evt.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              onClick={() => rejectModalData && handleModalSave(rejectModalData)}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {rejectModalData?.send_message ? 'REJECT AND SEND MESSAGE' : 'REJECT DISPLAY NAME'}
            </button>

            <button
              type="button"
              onClick={handleModalCancel}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              CLOSE
            </button>
          </div>
        </div>
      }
    />
  );
};

export default RejectMessageModal;
