import React, { useCallback, useEffect, useState } from 'react';
import { Pagination, Spinner } from '../../components/common';
import Header from '../../components/common/global-top-bar';
import SwitchBar from '../../components/switch-bar';
import { Headeroptions, PeerHeaderoptions } from './listener-constants';
import { useHistory } from 'react-router';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import { ROUTE_PATH } from '../../routes/paths';
import { connect, useDispatch } from 'react-redux';
import { fetchLogsCountRequest, fetchLogsRequest } from './listeners-redux/actions';
import moment from 'moment';
import Searchbar from '../../components/common/searchbar';
import { paramsToObjects } from '../../components/common/create-params';
import HeadBack from './redirect';

type FilterProps = {
  count: number | undefined;
  fetchData: (data: any) => void;
};

type props = {
  logs: any;
  count: any;
  pending: boolean;
};

const Filters: React.FunctionComponent<FilterProps> = ({ count, fetchData }): JSX.Element => {
  const [filters, setfilters] = useState({
    page: 1,
    limit: 25,
    search: '',
  });

  useEffect(() => {
    fetchData(filters);
  }, [filters]);

  return (
    <div className="w-full border-b gray-border-line flex justify-start items-center bg-gray-background-light px-7  h-10 ">
      <div className="w-1/4">
        <div className="w-full h-8">
          <Searchbar
            search={(data) => {
              setfilters((prev) => {
                return { ...prev, search: data, page: 1 };
              });
            }}
          />
        </div>
      </div>
      <div className="w-1/3">
        <Pagination
          pageCount={Math.ceil((count || 10) / 25)}
          key={count}
          updatePageNumber={(pages) =>
            setfilters((filter) => {
              return { ...filter, page: pages + 1 };
            })
          }
        />
      </div>
    </div>
  );
};

const Log: React.FunctionComponent<props> = ({ logs, pending, count }): JSX.Element => {
  const params = paramsToObjects();
  const listenerId = params['listenerRoleId'];
  const listenerName = params['listenerName'];
  const listenerType = params['listenerType'];
  const history = useHistory();
  const dispatch = useDispatch();

  const fetchData = (filters: any) => {
    dispatch(fetchLogsRequest({ id: listenerId, payload: { ...filters } }));
    dispatch(fetchLogsCountRequest({ id: listenerId, payload: { search: filters.search } }));
  };

  return (
    <div>
      {pending && <Spinner />}
      <Header heading={<HeadBack listenerName={listenerName || ''} />} />

      <SwitchBar heading={listenerType === 'peer' ? PeerHeaderoptions : Headeroptions} position={7} forwardParams />

      <Filters fetchData={fetchData} count={count} />
      <div className="pt-10 bg-white"></div>
      <div className="max-window-height-table  overflow-y-auto  px-7 pb-10">
        <table className="table-fixed table-row-format w-full relative border-collapse">
          <thead className="bg-white  h-full ">
            <tr className=" text-left  w-full px-7">
              <th className="px-2 py-2 w-3/12 sticky top-0  bg-white  h-full mb-4">Time Stamp</th>
              <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4">Event Category</th>
              <th className="px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4">Event Name</th>
              <th className="px-2 py-2  w-5/12 sticky top-0 bg-white  h-full mb-4">Message</th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-2"></tr>
            {logs?.length > 0 &&
              logs.map((item: any, index: any) => {
                return (
                  <tr className="bg-white  text-gray-dark text-left h-12 border-b border-gray-dark space-y-6">
                    <td className="px-2 py-1 w-2/12">{moment(item.timestamp).format('MMMM Do YYYY, h:mm:ss a')}</td>
                    <td className="px-2 py-1 w-1/12">{item.event_category}</td>
                    <td className="px-2 py-1 w-1/12">{item.event_name}</td>
                    <td className="px-2 py-1 w-5/12">{item.message}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    logs: state.Listeners.logs?.data || null,
    count: state.Listeners.logsCount?.data?.count || null,
    pending: state.Listeners?.pending || false,
  };
};

export default connect(mapStateToProps)(Log);
