import moment from 'moment';
import { Spinner } from '../../../components/common';
import { convertObjectToParams } from '../../../components/common/create-params';
import { ROUTE_PATH } from '../../../routes/paths';
import { EngagementReportFilters } from '../common/reports-constants';
import { EngagementDetail, EngagementEvent, useEngagementReport } from '../../../hooks/useEngagementReporting';

const engagementEventMap: Record<EngagementEvent, string> = {
  call_completed: 'Call Completed',
  client_resource_view: 'Program View',
  client_resource_impression: 'Program Impression',
  global_resource_view: 'Resource View',
  global_resource_impression: 'Resource Impression',
  provider_view: 'Provider View',
  provider_impression: 'Provider Impression',
  video_play: 'Video Play',
  audio_play: 'Audio Play',
};

interface EngagementDetailsReportProps {
  filters: EngagementReportFilters;
}

export const EngagementDetailsReport: React.FC<EngagementDetailsReportProps> = ({ filters }) => {
  const { data: engagementDetailsReport, isLoading } = useEngagementReport(filters);

  const formatEventType = (engagementEvent: EngagementEvent): string => {
    return engagementEventMap[engagementEvent] || 'Unknown';
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Engagements Details Report</h1>
      {isLoading && <Spinner />}
      {engagementDetailsReport?.data.map((event: EngagementDetail) => (
        <div
          key={event.id}
          className="w-full pb-3 bg-gray-200 rounded text-gray-dark text-left border-b-8 border-white space-y-3 space-x-2 flex justify-between"
        >
          <div className="w-1/4 pl-3 pt-3 space-y-1 select-none">
            <p className="text-neutral-600 text-xs uppercase">Event Type</p>
            <p className="text-black text-sm font-bold">{formatEventType(event.engagement_type)}</p>
            <p className="text-neutral-600 text-xs uppercase">Time</p>
            <div className="flex items-center space-x-2">
              <p className="text-black text-sm font-bold">
                {moment.utc(event.created_at).local().format('MMM DD, YYYY HH:mm:ss')}
              </p>
              <p className="text-neutral-600 text-xs font-bold">({moment.tz(moment.tz.guess()).format('z')})</p>
            </div>
            <div className="flex items-end space-x-2">
              <p className="text-black text-sm font-bold"></p>
              <p className="text-neutral-600 text-sm"></p>
            </div>
          </div>
          <div className="w-1/4 space-y-1 select-none">
            {event.provider_name && (
              <>
                <p className="text-neutral-600 text-xs uppercase">Provider</p>
                <div className="flex">
                  <p className="text-black text-sm font-bold capitalize">{event.provider_name}</p>
                </div>
              </>
            )}
            {['provider_view', 'provider_impression'].includes(event.engagement_type) && (
              <>
                <p className="text-neutral-600 text-xs uppercase">Plan</p>
                <div className="flex">
                  <p className="text-black text-sm font-bold capitalize">{event.engagement_name}</p>
                </div>
              </>
            )}
            {['client_resource_view', 'client_resource_impression'].includes(event.engagement_type) && (
              <>
                <p className="text-neutral-600 text-xs uppercase">Program</p>
                <div className="flex">
                  <p className="text-black text-sm font-bold capitalize">{event.engagement_name}</p>
                </div>
              </>
            )}
            {['global_resource_view', 'global_resource_impression'].includes(event.engagement_type) && (
              <>
                <p className="text-neutral-600 text-xs uppercase">Resource</p>
                <div className="flex">
                  <p className="text-black text-sm font-bold capitalize">{event.engagement_name}</p>
                </div>
              </>
            )}
            {['video_play', 'audio_play'].includes(event.engagement_type) && (
              <>
                <p className="text-neutral-600 text-xs uppercase">Experience Name</p>
                <div className="flex">
                  <p className="text-black text-sm font-bold capitalize">{event.engagement_name}</p>
                </div>
              </>
            )}
            {event.engagement_type === 'call_completed' && (
              <>
                <p className="text-neutral-600 text-xs uppercase">Call Type</p>
                <div className="flex">
                  <p className="text-black text-sm font-bold capitalize">{event.engagement_name}</p>
                </div>
                <p className="text-neutral-600 text-xs uppercase">Duration</p>
                <div className="flex">
                  <p className="text-black text-sm font-bold capitalize">
                    {moment.utc(event.meta_data.call_duration! * 1000).format('HH:mm:ss')}
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="w-1/4 space-y-1">
            <p className="text-neutral-600 text-xs uppercase select-none">Member</p>
            <p
              className="text-black text-sm font-bold cursor-pointer"
              onClick={() => {
                window.open(
                  ROUTE_PATH.MEMBERS_PROFILE +
                    convertObjectToParams({
                      callerRoleId: event.caller_role_id,
                    }),
                  '_blank',
                );
              }}
            >
              {event.member_name}
            </p>
            <p className="text-neutral-600 text-xs uppercase select-none">Client</p>
            <p
              className="text-black text-sm font-bold cursor-pointer"
              onClick={() => {
                localStorage.setItem('clientid', event.client_id.toString());
                window.open(ROUTE_PATH.REPORTING, '_blank');
              }}
            >
              {event.client_name}
            </p>
          </div>
          <div className="w-1/4 space-y-1">
            {event.listener_role_id && (
              <>
                <p className="text-neutral-600 text-xs uppercase select-none">Listener</p>
                <p
                  className="text-black text-sm font-bold cursor-pointer"
                  onClick={() => {
                    window.open(
                      ROUTE_PATH.LISTENERS_PROFILE +
                        convertObjectToParams({
                          listenerRoleId: event.listener_role_id,
                          listenerId: event.listener_user_id,
                        }),
                      '_blank',
                    );
                  }}
                >
                  {event.listener_name}
                </p>
              </>
            )}
            {event.engagement_type === 'call_completed' && (
              <>
                <p className="text-neutral-600 text-xs uppercase">Listener Call Topics</p>
                <p className="text-black text-base flex flex-wrap gap-1 pb-4">
                  {event.listener_topic_tags ? (
                    event.listener_topic_tags.map((tag, index) => {
                      return (
                        <div
                          key={index}
                          className="bg-blue-primary text-white rounded-md text-sm font-bold px-1 py-0.5 w-content"
                        >
                          {tag}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-black text-base font-bold">N/A</p>
                  )}
                </p>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
