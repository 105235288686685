import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosDelete, axiosGet, axiosPost, axiosPut } from '../api/axios';
import { toast } from 'react-toastify';

export interface TagResponse {
  count: number;
  data: Tag[];
}

export type TagType = 'trait' | 'topic';

export interface Tag {
  id: number;
  is_background: boolean;
  is_default: boolean;
  is_required: boolean;
  is_visible: boolean;
  media?: string;
  name: string;
  sort_order?: number;
  tag_type: TagType;
}

export interface TagExtended extends Tag {
  hasTag?: boolean;
}

export interface TagRequestParams {
  include_hidden?: boolean;
  limit?: number;
  page?: number;
  search?: string;
  tag_type?: TagType;
}

const getTags = async (params: TagRequestParams): Promise<Tag[]> => {
  return await axiosGet('/tags/', { ...params }, 'v2').then((tagsResponse) => tagsResponse.data);
};

export const useTags = (params: TagRequestParams) => {
  const { data, isLoading, error } = useQuery<Tag[]>(['tags', params], () => getTags(params));

  return { data, isLoading, error };
};

const getTagsV3 = async (params: TagRequestParams): Promise<TagResponse> => {
  return await axiosGet('/tags/', { ...params }, 'v3').then((tagsResponse) => tagsResponse.data);
};

export const useTagsV3 = (params: TagRequestParams) => {
  const { data, isLoading, error } = useQuery<TagResponse>(['tags', params], () => getTagsV3(params));

  return { data, isLoading, error };
};

const updateTagRequest = async (tagId: number, tag: Tag): Promise<Tag> => {
  return await axiosPut(`/tags/${tagId}`, tag, 'v3').then((tagResponse) => tagResponse.data);
};

export const useUpdateTag = () => {
  const queryClient = useQueryClient();
  return useMutation((tag: Tag) => updateTagRequest(tag.id, tag), {
    onSuccess: () => {
      queryClient.invalidateQueries(['tags']);
      toast.success('Tag updated successfully');
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message || 'Unkown error';
      toast.error(errorMessage);
    },
  });
};

const deleteTagRequest = async (tagId: number): Promise<void> => {
  return await axiosDelete(`/tags/${tagId}`, {}, 'v3').then((tagResponse) => tagResponse.data);
};

export const useDeleteTag = () => {
  const queryClient = useQueryClient();
  return useMutation((tagId: number) => deleteTagRequest(tagId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['tags']);
      toast.success('Tag deleted successfully');
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.error || 'Unkown error';
      toast.error(errorMessage);
    },
  });
};

const createTagRequest = async (tag: Tag): Promise<Tag> => {
  return await axiosPost('/tags/', tag, 'v3').then((tagResponse) => tagResponse.data);
};

export const useCreateTag = () => {
  const queryClient = useQueryClient();
  return useMutation((tag: Tag) => createTagRequest(tag), {
    onSuccess: () => {
      queryClient.invalidateQueries(['tags']);
      toast.success('Tag created successfully');
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message || 'Unkown error';
      toast.error(errorMessage);
    },
  });
};
