import { getStoredFilters, HeaderOptions, updateStoredFilters } from '../common/reports-constants';
import Header from '../../../components/common/global-top-bar';
import SwitchBar from '../../../components/switch-bar';
import { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { EngagementReportFilters } from '../common/reports-constants';
import { EngagementSummary } from './summary';
import { EngagementDetailsReport } from './details';
import { useEngagementReportDownload, useEngagementSummaryReportDownload } from '../../../hooks/useEngagementReporting';
import { Toggle } from '@kindlyhuman/component-library';

enum EngagementReportType {
  Summary = 'Summary',
  Details = 'Details',
}

const EngagementReporting: React.FC = () => {
  const today = new Date();
  const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const firstDayOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);

  const [filters, setFilters] = useState<EngagementReportFilters>(() => {
    const defaultFilters: EngagementReportFilters = {
      startDate: firstDayOfCurrentMonth.toISOString().split('T')[0],
      endDate: firstDayOfNextMonth.toISOString().split('T')[0],
      clientId: undefined,
      testData: false,
    };
    return getStoredFilters(defaultFilters);
  });

  const [reportType, setReportType] = useState<EngagementReportType>(EngagementReportType.Summary);

  return (
    <div>
      <Header heading={'Engagement Reporting'} />
      <SwitchBar heading={HeaderOptions} position={1} />
      <Filters filters={filters} setFilters={setFilters} reportType={reportType} setReportType={setReportType} />

      <div>
        <div className="max-window-height-connections overflow-y-auto px-7 pt-7 font-['Montserrat']">
          {reportType === EngagementReportType.Summary && <EngagementSummary filters={filters} />}
          {reportType === EngagementReportType.Details && <EngagementDetailsReport filters={filters} />}
        </div>
      </div>
    </div>
  );
};

interface FilterProps {
  filters: EngagementReportFilters;
  setFilters: (filters: EngagementReportFilters) => void;
  reportType: EngagementReportType;
  setReportType: (reportType: EngagementReportType) => void;
}

const Filters: React.FC<FilterProps> = ({ filters, setFilters, reportType, setReportType }) => {
  const { isFetching: isFetchingDetails, refetch: refetchDetails } = useEngagementReportDownload(filters, false);
  const { isFetching: isFetchingSummary, refetch: refetchSummary } = useEngagementSummaryReportDownload(filters, false);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
    updateStoredFilters(name, value);
  };

  const handleInputChange = (filter: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilters({
      ...filters,
      [filter]: value && value.length ? parseInt(event.target.value) : undefined,
    });
  };

  const handleDownload = () => {
    if (filters.startDate !== '' && filters.endDate !== '') {
      const fetchData = async () => {
        (await reportType) === EngagementReportType.Details ? refetchDetails() : refetchSummary();
      };
      fetchData();
    }
  };

  return (
    <div className="w-full border-b gray-border-line flex items-center bg-gray-background-light px-4 h-10 space-x-2">
      <div className="flex items-center py-4 gray-background-dark">
        {(Object.keys(EngagementReportType) as Array<keyof typeof EngagementReportType>).map((item) => (
          <button
            key={item}
            className={
              'text-sm px-2 py-1 whitespace-nowrap' +
              (EngagementReportType[item] === reportType
                ? ' bg-blue-primary text-white'
                : ' bg-gray-background-dark text-gray-dark')
            }
            onClick={() => {
              setReportType(EngagementReportType[item]);
            }}
          >
            {EngagementReportType[item]}
          </button>
        ))}
      </div>
      <div className="flex items-center space-x-2">
        <label className="whitespace-nowrap text-sm">Start Date:</label>
        <input
          type="date"
          name="startDate"
          value={filters.startDate}
          onChange={handleDateChange}
          className="p-0.5 w-24 border rounded text-xs"
        />
        <label className="whitespace-nowrap text-sm">End Date:</label>
        <input
          type="date"
          name="endDate"
          value={filters.endDate}
          onChange={handleDateChange}
          className="p-0.5 w-24 border rounded text-xs"
        />
        <label className="flex items-center whitespace-nowrap text-sm">
          Client ID:
          <DebounceInput
            className="text-center focus:outline-none w-20 h-6 rounded ml-1 text-sm p-0.5"
            debounceTimeout={300}
            onChange={(e) => handleInputChange('clientId', e)}
            value={filters.clientId || ''}
          />
        </label>
      </div>
      <div className="flex gap-x-2 text-sm items-center">
        <div className="whitespace-nowrap">Include Test Users</div>
        <Toggle
          checked={filters.testData}
          onChange={() => {
            setFilters({ ...filters, testData: !filters.testData });
          }}
        />
      </div>
      <div className="">
        <button
          className="bg-blue-primary text-white px-4 py-1 rounded-full"
          onClick={handleDownload}
          disabled={isFetchingSummary || isFetchingDetails}
        >
          Download
        </button>
      </div>
    </div>
  );
};

export default EngagementReporting;
