import { getStoredFilters, HeaderOptions, updateStoredFilters } from '../common/reports-constants';
import Header from '../../../components/common/global-top-bar';
import SwitchBar from '../../../components/switch-bar';
import { useState } from 'react';
import { useCallOutcomesReportDownload, useCallSummaryReportDownload } from '../../../hooks/useCallReporting';
import { DebounceInput } from 'react-debounce-input';
import { CallsSummary } from './summary';
import { CallReportFilters } from './common/call-report-constants';
import { CallsDetails } from './details';

enum CallReportType {
  CallsSummary = 'Calls Summary',
  CallDetails = 'Calls Details',
}

const CallReporting: React.FC = () => {
  const today = new Date();
  const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const firstDayOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);

  const [filters, setFilters] = useState<CallReportFilters>(() => {
    const defaultFilters: CallReportFilters = {
      startDate: firstDayOfCurrentMonth.toISOString().split('T')[0],
      endDate: firstDayOfNextMonth.toISOString().split('T')[0],
      listenerRoleId: undefined,
      clientId: undefined,
    };
    return getStoredFilters(defaultFilters);
  });
  const [reportType, setReportType] = useState<CallReportType>(CallReportType.CallsSummary);

  return (
    <div>
      <Header heading={'Call Reporting'} />
      <SwitchBar heading={HeaderOptions} position={0} />
      <Filters filters={filters} setFilters={setFilters} reportType={reportType} setReportType={setReportType} />

      <div>
        <div className="max-window-height-connections overflow-y-auto px-7 pt-7 font-['Montserrat']">
          {reportType === CallReportType.CallsSummary && <CallsSummary filters={filters} />}
          {reportType === CallReportType.CallDetails && <CallsDetails filters={filters} />}
        </div>
      </div>
    </div>
  );
};

interface FilterProps {
  filters: CallReportFilters;
  setFilters: (filters: CallReportFilters) => void;
  reportType: CallReportType;
  setReportType: (reportType: CallReportType) => void;
}

const Filters: React.FC<FilterProps> = ({ filters, setFilters, reportType, setReportType }) => {
  const { isFetching: isFetchingOutcomes, refetch: refetchOutcomes } = useCallOutcomesReportDownload(filters, false);
  const { isFetching: isFetchingSummary, refetch: refetchSummary } = useCallSummaryReportDownload(filters, false);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
    updateStoredFilters(name, value);
  };

  const handleInputChange = (filter: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilters({
      ...filters,
      [filter]: value && value.length ? parseInt(event.target.value) : undefined,
    });
  };

  const handleDownload = () => {
    if (filters.startDate !== '' && filters.endDate !== '') {
      const fetchData = async () => {
        (await reportType) === CallReportType.CallDetails ? refetchOutcomes() : refetchSummary();
      };
      fetchData();
    }
  };

  return (
    <div className="w-full border-b gray-border-line flex items-center bg-gray-background-light px-4 h-10 space-x-2">
      <div className="flex items-center py-4 gray-background-dark">
        {(Object.keys(CallReportType) as Array<keyof typeof CallReportType>).map((item) => (
          <button
            key={item}
            className={
              'text-sm px-2 py-1 whitespace-nowrap' +
              (CallReportType[item] === reportType
                ? ' bg-blue-primary text-white'
                : ' bg-gray-background-dark text-gray-dark')
            }
            onClick={() => {
              setReportType(CallReportType[item]);
            }}
          >
            {CallReportType[item]}
          </button>
        ))}
      </div>
      <div className="flex items-center space-x-2">
        <label className="whitespace-nowrap text-sm">Start Date:</label>
        <input
          type="date"
          name="startDate"
          value={filters.startDate}
          onChange={handleDateChange}
          className="p-0.5 w-24 border rounded text-xs"
        />
        <label className="whitespace-nowrap text-sm">End Date:</label>
        <input
          type="date"
          name="endDate"
          value={filters.endDate}
          onChange={handleDateChange}
          className="p-0.5 w-24 border rounded text-xs"
        />
      </div>
      <div className="flex items-center space-x-1">
        <label className="flex items-center whitespace-nowrap text-sm">
          Listener ID:
          <DebounceInput
            className="text-center focus:outline-none w-20 h-6 rounded ml-1 text-sm p-0.5"
            debounceTimeout={300}
            onChange={(e) => handleInputChange('listenerRoleId', e)}
            value={filters.listenerRoleId || ''}
          />
        </label>
        <label className="flex items-center whitespace-nowrap text-sm">
          Client ID:
          <DebounceInput
            className="text-center focus:outline-none w-20 h-6 rounded ml-1 text-sm p-0.5"
            debounceTimeout={300}
            onChange={(e) => handleInputChange('clientId', e)}
            value={filters.clientId || ''}
          />
        </label>
      </div>
      <div>
        <button
          className="bg-blue-primary text-white px-4 py-1 rounded-full text-sm"
          onClick={handleDownload}
          disabled={isFetchingOutcomes || isFetchingSummary}
        >
          Download
        </button>
      </div>
    </div>
  );
};

export default CallReporting;
