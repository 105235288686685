import Modal from '../../../components/common/modal';
import UseFormDropDown from '../../../components/common/use-form-drop-down';
import { ToggleButton } from '../../../components/common';
import { ClientSummary } from '../../../hooks/useClients';
import { useEffect, useState } from 'react';
import AlertBox from '../../../components/route-leaving-prompt/alert-box';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';

interface MemberFormData {
  first_name: string;
  last_name: string;
  email_address: string;
  mobile_phone: string;
  timezone: string;
  gender: string;
  pronoun: string;
  date_of_birth: string;
  race: string;
  is_text_compatible_phone: boolean;
  caller_role: {
    active_subscription: {
      package: {
        client: {
          name: string;
        };
        name: string;
      };
    };
  };
}

interface EditDetailsProps {
  showEditModal: boolean;
  onClose: () => void;
  onSave: () => void;
  initialValues: Partial<MemberFormData> | null;
  tags: {
    genders: string[];
    pronouns: string[];
    races: string[];
  };
  timeZones: string[];
  clients: ClientSummary[];
  packages: Array<{ name: string }>;
  language: string[];
  selectLanguage: (language: string) => void;
  setPackages: (packages: Array<{ name: string }>) => void;
  handlePackageChange: (name: string) => void;
  setIsModified: (modified: boolean) => void;
  register: UseFormRegister<MemberFormData>;
  setValue: UseFormSetValue<MemberFormData>;
  errors: FieldErrors<MemberFormData>;
  emailAvailable: boolean;
  setEmail: (email: string) => void;
  regexPhoneNumber: (value: string) => void;
  mobileValid: boolean;
  isTextCompatiblePhone: boolean;
  setIsTextCompatiblePhone: (value: boolean) => void;
}

export const EditDetails: React.FC<EditDetailsProps> = ({
  showEditModal,
  onClose,
  onSave,
  initialValues,
  tags,
  timeZones,
  clients,
  packages,
  language,
  selectLanguage,
  setPackages,
  handlePackageChange,
  setIsModified,
  register,
  setValue,
  errors,
  emailAvailable,
  setEmail,
  regexPhoneNumber,
  mobileValid,
  isTextCompatiblePhone,
  setIsTextCompatiblePhone,
}) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [formValues, setFormValues] = useState<Partial<MemberFormData> | null>(null);

  useEffect(() => {
    if (showEditModal && initialValues) {
      setFormValues(initialValues);
    }
  }, [showEditModal, initialValues]);

  const handleCloseModal = () => {
    if (hasUnsavedChanges) {
      setShowAlert(true);
    } else {
      onClose();
    }
  };

  const handleDiscardChanges = () => {
    if (formValues) {
      (Object.entries(formValues) as Array<[keyof MemberFormData, any]>).forEach(([key, value]) => {
        if (value !== undefined) {
          setValue(key, value);
        }
      });
    }
    setHasUnsavedChanges(false);
    setShowAlert(false);
    onClose();
  };

  const handleFormChange = () => {
    setIsModified(true);
    setHasUnsavedChanges(true);
  };
  return (
    <>
      <Modal
        isModel={showEditModal}
        InnerComponent={
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="text-xl leading-6 font-bold mb-4">Edit Member Details</div>
              <div className="text-md leading-6 font-bold mb-4 border-b border-gray-200 pb-2">Plan Details</div>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="w-full px-2">
                  <UseFormDropDown label={'Group'}>
                    <select
                      data-testid="group-select"
                      className="select-box w-full"
                      {...register('caller_role.active_subscription.package.client.name', {
                        onChange: (e) => {
                          const find = clients?.length
                            ? clients.find((item: any) => item.name === e.target.value)
                            : null;
                          if (find && find?.packages?.length) {
                            setPackages(find.packages);
                            handlePackageChange(find.packages[0].name);
                          }
                          handleFormChange();
                        },
                      })}
                    >
                      <option value="">Select group</option>
                      {clients?.map((item: any) => (
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </UseFormDropDown>
                </div>
                <div className="w-full px-2">
                  <UseFormDropDown label={'Plan'}>
                    <select
                      data-testid="plan-select"
                      className="select-box w-full"
                      {...register('caller_role.active_subscription.package.name', {
                        onChange: (e) => {
                          handlePackageChange(e.target.value);
                          handleFormChange();
                        },
                      })}
                    >
                      {packages?.length > 0 &&
                        packages.map((item: any) => (
                          <option key={item.name} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </UseFormDropDown>
                </div>
              </div>
              <div className="text-md leading-6 font-bold mb-4 border-b border-gray-200 pb-2">Contact</div>
              <div className="grid grid-cols-3 gap-4 mb-4">
                <div className="w-full px-2">
                  <label className="input-label">First Name</label>
                  <input
                    data-testid="first-name-input"
                    {...register('first_name', {
                      onBlur: (e: any) => setValue('first_name', e.target.value.trim()),
                      onChange: () => handleFormChange(),
                    })}
                    className="input"
                  />
                </div>
                <div className="w-full px-2">
                  <label className="input-label">Last Name</label>
                  <input
                    data-testid="last-name-input"
                    {...register('last_name', {
                      onBlur: (e: any) => setValue('last_name', e.target.value.trim()),
                      onChange: () => handleFormChange(),
                    })}
                    className="input"
                  />
                </div>
                <div className="w-full px-2">
                  <UseFormDropDown label={'Time Zone'}>
                    <select
                      data-testid="timezone-select"
                      className="select-box w-full"
                      {...register('timezone', {
                        onChange: () => handleFormChange(),
                      })}
                    >
                      <option value="">Select time zone</option>
                      {timeZones.map((item: any) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </UseFormDropDown>
                </div>
                <div className="w-full px-2">
                  <label className="input-label">Email</label>
                  <input
                    data-testid="email-input"
                    {...register('email_address', {
                      required: 'Enter email address',
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: 'Enter valid email address',
                      },
                      validate: (value) => {
                        if (value !== formValues?.email_address && emailAvailable === false) return false;
                      },
                      onChange: (e) => {
                        setEmail(e.target.value);
                        handleFormChange();
                      },
                      onBlur: (e: any) => setValue('email_address', e.target.value.trim()),
                    })}
                    className="input"
                  />
                  {errors.email_address?.message && (
                    <label className="input-error">{errors.email_address.message}</label>
                  )}
                  {errors.email_address &&
                    // @ts-ignore
                    errors.email_address.type === 'validate' && (
                      <div className="input-error">Email already used, use different email.</div>
                    )}
                </div>
                <div className="w-full px-2">
                  <label className="input-label">Phone</label>
                  <input
                    data-testid="phone-input"
                    {...register('mobile_phone', {
                      validate: (value) => {
                        if (value.trim() === '') return true;
                        return mobileValid;
                      },
                      onChange: (e) => {
                        regexPhoneNumber(e.target.value);
                        handleFormChange();
                      },
                      onBlur: (e: any) => setValue('mobile_phone', e.target.value.trim()),
                    })}
                    className="input"
                  />
                  {errors.mobile_phone?.message ? (
                    <label className="input-error">{errors.mobile_phone?.message}</label>
                  ) : (errors.mobile_phone &&
                      // @ts-ignore
                      errors?.mobile_phone?.type === 'validate') ||
                    !mobileValid ? (
                    <div className="input-error">Enter valid phone number</div>
                  ) : null}
                </div>
                <div className="w-full">
                  <div className="flex flex-col gap-2 min-w-48">
                    <label className="block leading-4 text-gray-dark">Receive Text Comms</label>
                    <ToggleButton
                      data-testid="text-comms-toggle"
                      id={'is_text_compatible_phone'}
                      status={isTextCompatiblePhone}
                      setStatus={(data) => {
                        setIsTextCompatiblePhone(data);
                        setValue('is_text_compatible_phone', data);
                        handleFormChange();
                      }}
                      initialstatus={true}
                    />
                  </div>
                </div>
              </div>
              <div className="text-md leading-6 font-bold mb-4 border-b border-gray-200 pb-2">
                Matching Demographics
              </div>
              <div className="grid grid-cols-3 gap-4 mb-4">
                <div className="mb-4">
                  <UseFormDropDown label={'Gender'}>
                    <select
                      data-testid="gender-select"
                      className="select-box w-full"
                      {...register('gender', {
                        onChange: () => handleFormChange(),
                      })}
                    >
                      {tags.genders?.map((item: string) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </UseFormDropDown>
                </div>
                <div className="mb-4">
                  <UseFormDropDown label={'Pronouns'}>
                    <select
                      data-testid="pronouns-select"
                      className="select-box w-full"
                      {...register('pronoun', {
                        onChange: () => handleFormChange(),
                      })}
                    >
                      <option value="">Select pronoun</option>
                      {tags.pronouns?.map((item: any) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </UseFormDropDown>
                </div>
                <div className="mb-4">
                  <label className="input-label">DOB (Age)</label>
                  <input
                    data-testid="dob-input"
                    {...register('date_of_birth', {
                      onChange: () => handleFormChange(),
                    })}
                    type="date"
                    className="input"
                  />
                </div>
                <div className="mb-4">
                  <UseFormDropDown label={'Race'}>
                    <select
                      data-testid="race-select"
                      className="select-box w-full"
                      {...register('race', {
                        onChange: () => handleFormChange(),
                      })}
                    >
                      <option value="">Select race</option>
                      {tags.races?.map((item: any) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </UseFormDropDown>
                </div>
                <div className="mb-4 col-span-2">
                  <div className="w-full flex flex-col gap-1">
                    <div className="input-label">Preferred Language</div>
                    <div className="w-full flex gap-4">
                      <div className="flex">
                        <label className="leading-4 pr-4 text-gray-dark flex items-center"> English </label>
                        <ToggleButton
                          data-testid="english-toggle"
                          id={'english'}
                          status={language && language.includes('english')}
                          setStatus={(data) => {
                            selectLanguage('english');
                            handleFormChange();
                          }}
                          initialstatus={true}
                        ></ToggleButton>
                      </div>
                      <div className="flex">
                        <label className="leading-4 pr-4 text-gray-dark flex items-center"> Spanish </label>
                        <ToggleButton
                          data-testid="spanish-toggle"
                          id={'spanish'}
                          status={language && language.includes('spanish')}
                          setStatus={(data) => {
                            selectLanguage('spanish');
                            handleFormChange();
                          }}
                          initialstatus={true}
                        ></ToggleButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-3 px-6 flex gap-2 justify-end">
              <button
                data-testid="cancel-button"
                type="button"
                className="text-sm text-white px-2 bg-red hover:bg-opacity-80 flex items-center justify-center w-36 h-10 rounded-sm"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                data-testid="save-changes-button"
                type="button"
                className={`text-sm text-white px-2 flex items-center justify-center w-36 h-10 rounded-sm ${
                  !hasUnsavedChanges ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-primary hover:bg-blue-primary-dark'
                }`}
                onClick={onSave}
                disabled={!hasUnsavedChanges}
              >
                Save Changes
              </button>
            </div>
          </div>
        }
      />
      <AlertBox
        visible={showAlert}
        handlePrompt={handleDiscardChanges}
        handleContinueSave={onSave}
        closeModal={() => setShowAlert(false)}
        confirmButtonText={'Discard Changes'}
        titleText={'Alert'}
        contentText={'You have unsaved changes. If you leave this screen without saving, your changes will be lost.'}
        confirmSaveButtonText={'Save Changes'}
        cancelButtonText={'Cancel'}
      />
    </>
  );
};
