import { useEffect, useState, useCallback } from 'react';
import {
  AdministratorRole,
  User,
  useDeleteUser,
  useEmailAddressAvailable,
  useUpdateUser,
} from '../../../hooks/useUsers';
import debounce from '../../../components/common/debounce';
import { CustomInput, DropDown, ToggleButton } from '../../../components/common';
import Modal from '../../../components/common/modal';
import AlertBox from '../../../components/route-leaving-prompt/alert-box';
import { normalizeMobilePhone, validateMobilePhone } from '../../../components/common/phone-validation';
import { toast } from 'react-toastify';
import { useAppSettings } from '../../../hooks/useAppSettings';

type props = {
  user: User;
};

const userStatus = {
  enabled: 'Enabled',
  disabled: 'Disabled',
  under_review: 'Under Review',
  suspended: 'Suspended',
  banned: 'Banned',
};

const EditUserDetails: React.FunctionComponent<props> = ({ user }): JSX.Element => {
  const { data: appSettings } = useAppSettings();
  const { updateUser } = useUpdateUser();
  const deleteUser = useDeleteUser();
  const [modal, setModal] = useState<boolean>(false);
  const [alertModal, setAlertModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<User>();
  const [errorMessages, setErrorMessages] = useState<any>({});
  const [_enableSaveOption, setEnableSaveOption] = useState(false);
  const enableSaveOption = _enableSaveOption && Object.keys(errorMessages).length === 0;
  const emailDebounce = debounce(userDetails?.email_address, 1000);
  const { data: emailAvailable } = useEmailAddressAvailable(emailDebounce, {
    onSuccess: (result) => {
      if (!userDetails) return;
      const { email_address } = userDetails!;

      const emailRegex = /\S+@\S+\.\S+/;

      if (!emailRegex.test(email_address) || email_address === '') {
        return setErrorMessages({ email_address: 'Enter valid email address' });
      } else if (!result && user.email_address !== email_address) {
        setErrorMessages({ email_address: 'Email already used, use different email' });
      } else if (result) {
        setErrorMessages({});
      }
    },
  });

  const setDefaultOptions = useCallback(() => {
    if (!user) return;
    const {
      mobile_phone,
      first_name,
      last_name,
      display_name,
      gender,
      timezone,
      email_address,
      administrator_role,
      test_user,
    } = user;
    const mobilePhoneInput = mobile_phone.replace('+1', '');
    setUserDetails(() => ({
      ...user,
      mobile_phone: normalizeMobilePhone(mobilePhoneInput),
      first_name,
      last_name,
      display_name,
      gender,
      timezone,
      email_address,
      administrator_role,
      test_user,
    }));
    return userDetails;
  }, [user]);

  const updateUserDetails = async (name: string, value: string | number | AdministratorRole | null | boolean) => {
    await validateUserDetails();
    setEnableSaveOption(true);
    setUserDetails((prev: any) => ({ ...prev, [name]: value }));
  };

  const validateUserDetails = async () => {
    if (!userDetails) return;
    let isValid = false;
    try {
      validateEmail();
      const { mobile_phone } = userDetails;

      if ((!mobile_phone || mobile_phone.trim() === '') && validateEmail() === true) {
        setErrorMessages({});
        isValid = true;
      } else {
        const phoneErrorMessage = await validateMobilePhone(mobile_phone); // extract a return val from this guy
        if (phoneErrorMessage !== '') {
          setErrorMessages({ mobile_phone: phoneErrorMessage });
        }
      }
    } catch (e) {}

    return isValid;
  };

  const validateEmail = () => {
    if (!userDetails || !user) return;
    const { email_address } = userDetails;

    const emailRegex = /\S+@\S+\.\S+/;

    if (!emailRegex.test(email_address) || email_address === '') {
      return setErrorMessages({ email_address: 'Enter valid email address' });
    } else if (!emailAvailable && user.email_address !== email_address) {
      return setErrorMessages({
        email_address: 'Email already used, use different email',
      });
    }

    setErrorMessages({});

    return true;
  };

  const handleDelete = async () => {
    if (!userDetails) return;
    deleteUser.mutate(userDetails.id, {
      onSuccess: () => {
        toast.success('User deleted successfully');
        setDeleteModal(false);
        setModal(false);
      },
      onError: () => {
        toast.error('User deletion failed. please contact the development team for more information.');
      },
    });
  };

  const handleSave = async () => {
    const payload: Partial<User> = {
      ...userDetails,
    };

    if (payload.mobile_phone?.trim() === '') {
      payload.mobile_phone = undefined;
    }

    if (payload.password?.trim() === '') {
      payload.password = undefined;
    }

    updateUser.mutate(
      { userId: user.id, data: payload },
      {
        onSuccess: () => {
          toast.success('User updated successfully');
        },
      },
    );
    setModal(false);
    setAlertModal(false);
    setEnableSaveOption(false);
  };

  useEffect(() => {
    if (modal && user) {
      setDefaultOptions();
    }
  }, [modal, user, setDefaultOptions]);

  return (
    <>
      <button
        onClick={(event) => {
          event.stopPropagation();
          setModal(true);
        }}
        className=" text-center h-10 text-sm text-white px-2 bg-blue-primary rounded-sm "
      >
        Edit
      </button>
      <Modal
        isModel={modal}
        InnerComponent={
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="inline-block align-bottom bg-white overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          >
            <div className="bg-white px-2 pt-3 pb-2 sm:p-6 sm:pb-4">
              <div className="flex justify-start ">
                <div>
                  <h3 className="text-2xl font-normal blue-primary  py-1">Edit User</h3>
                </div>
              </div>
              <div className="py-2 flex flex-start">
                <h3 className="text-lg  font-bold text-gray-dark  py-1">Details</h3>
              </div>
              <div className="grid grid-cols-3 gap-3 gap-y-16 mr-5 mb-16">
                <div>
                  <CustomInput
                    inputType={'text'}
                    value={userDetails?.first_name}
                    setValue={(data) => updateUserDetails('first_name', data)}
                    Label={'First Name'}
                  />
                </div>
                <div>
                  <CustomInput
                    inputType={'text'}
                    value={userDetails?.last_name}
                    setValue={(data) => updateUserDetails('last_name', data)}
                    Label={'Last Name'}
                  />
                </div>
                <div>
                  <CustomInput
                    inputType={'text'}
                    value={userDetails?.display_name}
                    setValue={(data) => updateUserDetails('display_name', data)}
                    Label={'Display Name'}
                  />
                </div>
                <div>
                  <CustomInput
                    inputType={'text'}
                    value={userDetails?.mobile_phone}
                    setValue={(data) => updateUserDetails('mobile_phone', data)}
                    Label={'Phone'}
                    Error={errorMessages.mobile_phone?.length > 0 ? true : false}
                    ErrorMessage={errorMessages.mobile_phone}
                  />
                </div>
                <div>
                  <CustomInput
                    inputType={'text'}
                    value={userDetails?.email_address}
                    setValue={(data) => updateUserDetails('email_address', data)}
                    Label={'Email'}
                    Error={errorMessages.email_address?.length > 0 ? true : false}
                    ErrorMessage={errorMessages.email_address}
                  />
                </div>
                <div>
                  <CustomInput
                    inputType={'text'}
                    value={null}
                    setValue={(data) => updateUserDetails('password', data)}
                    Label={'Password'}
                    placeholder={userDetails?.needs_password ? 'No password set' : '*********'}
                  />
                </div>
                <div>
                  <DropDown
                    Label={'Timezone'}
                    labelClass="float-left block leading-4 text-gray-dark mb-2"
                    value={userDetails?.timezone}
                    setValue={(data) => {
                      setEnableSaveOption(true);
                      setUserDetails((prev: any) => ({
                        ...prev,
                        timezone: data === '' ? null : data,
                      }));
                    }}
                  >
                    <>
                      <option key={'timezone'} value={''}>
                        Select TimeZone
                      </option>
                      {appSettings?.supported_timezones &&
                        appSettings.supported_timezones?.map((timezone: string) => (
                          <option key={timezone} value={timezone}>
                            {timezone}
                          </option>
                        ))}
                    </>
                  </DropDown>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-3 mr-5 mt-2">
                <div>
                  <DropDown
                    Label={'Account Status'}
                    labelClass="float-left block leading-4 text-gray-dark mb-2"
                    value={userDetails?.status}
                    setValue={(data) => {
                      updateUserDetails('status', data);
                    }}
                  >
                    <>
                      {Object.keys(userStatus).map((item: string) => (
                        <option key={item} value={item}>
                          {/* @ts-ignore */}
                          {userStatus[item]}
                        </option>
                      ))}
                    </>
                  </DropDown>
                </div>
                <div>
                  <DropDown
                    Label={'Gender'}
                    labelClass="float-left block leading-4 text-gray-dark mb-2"
                    value={userDetails?.gender}
                    setValue={(data) => updateUserDetails('gender', data)}
                  >
                    <option value={'Male'}>Male</option>
                    <option value={'Female'}>Female</option>
                    <option value={'Non Binary'}>Non Binary</option>
                    <option value={'Agender'}>Agender</option>
                    <option value={'Other'}>Other</option>
                    <option value={'Prefer not to say'}>Prefer not to say</option>
                  </DropDown>
                </div>
                <div className="pl-4">
                  <div className="pb-2">
                    <div className="text-md text-gray-dark float-left pr-4">Administrator:</div>
                    <div className="">
                      <ToggleButton
                        id={'administratorRole'}
                        status={userDetails?.administrator_role}
                        initialstatus={false}
                        setStatus={(data: boolean) => {
                          if (data === true) {
                            updateUserDetails('administrator_role', { type: 'administrator' });
                          } else {
                            updateUserDetails('administrator_role', null);
                          }
                        }}
                      ></ToggleButton>
                    </div>
                  </div>
                  <div className="text-md text-gray-dark float-left pr-10">For testing:</div>
                  <div className="">
                    <ToggleButton
                      id={'testUser'}
                      status={userDetails?.test_user}
                      initialstatus={false}
                      setStatus={(data: boolean) => {
                        updateUserDetails('test_user', data);
                      }}
                    ></ToggleButton>
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-16">
                <button
                  className={
                    ' text-center h-8 text-sm text-white bg-red px-2  w-16 rounded-sm ' +
                    (enableSaveOption ? 'bg-blue-primary' : 'bg-gray-200')
                  }
                  onClick={() => setDeleteModal(true)}
                >
                  Delete
                </button>
                <div>
                  <button
                    className={
                      ' text-center h-8 text-sm text-white px-2  w-16 rounded-sm ' +
                      (enableSaveOption ? 'bg-blue-primary' : 'bg-gray-200')
                    }
                    onClick={handleSave}
                    disabled={!enableSaveOption}
                  >
                    Save
                  </button>
                  <button
                    className=" text-center h-8 text-sm text-white px-2 bg-blue-primary w-16 rounded-sm ml-4 "
                    onClick={() => {
                      if (enableSaveOption) {
                        setAlertModal(true);
                      } else {
                        setModal(false);
                      }
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <AlertBox
        visible={alertModal}
        handlePrompt={() => {
          setDefaultOptions();
          setModal(false);
          setAlertModal(false);
          setErrorMessages({});
          setEnableSaveOption(false);
        }}
        handleContinueSave={handleSave}
        closeModal={() => {
          setAlertModal(false);
        }}
        confirmButtonText={'Discard Changes'}
        titleText={'Alert'}
        contentText={'You have unsaved changes.  If you leave this screen without saving, your changes will be lost.'}
        confirmSaveButtonText={'Save Changes'}
        cancelButtonText={'Cancel'}
      />
      <AlertBox
        visible={deleteModal}
        handlePrompt={() => {
          handleDelete();
        }}
        closeModal={() => {
          setDeleteModal(false);
        }}
        titleText={'Alert'}
        contentText={
          "You are about to delete a user. This is not a casual action and data might be lost that you will never get back. Please don't make a choice you will regret."
        }
        confirmButtonText={'Delete User Forever'}
        cancelButtonText={'Cancel'}
      />
    </>
  );
};

export default EditUserDetails;
