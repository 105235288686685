import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, connect } from 'react-redux';
import {
  fetchEligibiltyRequest,
  processEligibilityReportRequest,
} from '../../containers/clients/eligibility-redux/actions';
type props = {
  handleEligibilityFileViewModel: (id: number, type: string) => void;
  eligibilityId: number;
  eligibility?: any;
  eligibilityValidationData?: any;
  tableType: any;
  processEligibilityReport: any;
  processEligibilityReportError: any;
  clientId: any;
};
const EligibilityFileViewModel: React.FunctionComponent<props> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [existingMemberCount, setExistingMemberCount] = useState<number>(0);
  const [newMemberCount, setNewMemberCount] = useState<number>(0);
  const [removedMemberCount, setRemovedMemberCount] = useState<number>(0);
  const [invalidRecordCount, setInvalidRecordCount] = useState<number>(0);
  const [eligibilityList, setEligibilityList] = useState<any>([]);
  useEffect(() => {
    if (props.eligibilityId) {
      const input = {
        clientId: props.clientId,
        eligibilityId: props.eligibilityId,
      };
      dispatch(fetchEligibiltyRequest(input));
    }
  }, [props.eligibilityId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.tableType === 'historical') {
      const input = {
        clientId: props.clientId,
        eligibilityId: props.eligibilityId,
      };
      dispatch(processEligibilityReportRequest(input));
    }
  }, [props.tableType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.eligibility?.data?.validation_report) {
      setTotalRecords(props.eligibility.data.validation_report.record_count);
      setExistingMemberCount(props.eligibility.data.validation_report.existing_members?.length);
      setNewMemberCount(props.eligibility.data.validation_report.members_to_add?.length);
      setRemovedMemberCount(props.eligibility.data.validation_report.members_to_remove?.length);
      setInvalidRecordCount(props.eligibility.data.validation_report.invalid_records?.length);
      let finalArray: any = [];
      props.eligibility.data.validation_report.members_to_add?.map((item: any) => {
        return finalArray.push({
          memberId: '',
          email: item,
          status: 'successful',
          message: 'New member',
        });
      });
      props.eligibility.data.validation_report.existing_members?.map((item: any) => {
        return finalArray.push({
          memberId: '',
          email: item,
          status: 'successful',
          message: 'existing member',
        });
      });
      props.eligibility.data.validation_report.members_to_remove?.map((item: any) => {
        return finalArray.push({
          memberId: '',
          email: item,
          status: 'successful',
          message: 'removing member',
        });
      });
      props.eligibility.data.validation_report.invalid_records?.map((item: any) => {
        return finalArray.push({
          memberId: item.member_id,
          email: item.email_address,
          status: 'unsuccessful',
          message: item.package_id,
        });
      });
      setEligibilityList(finalArray);
    }
  }, [props.eligibility]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(props.eligibilityValidationData)?.length) {
      setTotalRecords(props.eligibilityValidationData.record_count);
      setExistingMemberCount(props.eligibilityValidationData.existing_members.length);
      setNewMemberCount(props.eligibilityValidationData.members_to_add.length);
      setRemovedMemberCount(props.eligibilityValidationData.members_to_remove.length);
      setInvalidRecordCount(props.eligibilityValidationData.invalid_records.length);
      let finalArray: any = [];
      props.eligibilityValidationData.members_to_add.map((item: any) => {
        return finalArray.push({
          memberId: '',
          email: item,
          status: 'successful',
          message: 'New member',
        });
      });
      props.eligibilityValidationData.existing_members.map((item: any) => {
        return finalArray.push({
          memberId: '',
          email: item,
          status: 'successful',
          message: 'existing member',
        });
      });
      props.eligibilityValidationData.members_to_remove.map((item: any) => {
        return finalArray.push({
          memberId: '',
          email: item,
          status: 'successful',
          message: 'removing member',
        });
      });
      props.eligibilityValidationData.invalid_records.map((item: any) => {
        return finalArray.push({
          memberId: item.member_id,
          email: item.email_address,
          status: 'unsuccessful',
          message: item.package_id,
        });
      });
      setEligibilityList(finalArray);
    }
  }, [props.eligibilityValidationData]);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-100 outline-none focus:outline-none">
        <div className="relative w-auto  rounded-lg my-6 mx-auto w-5xl min-w-3xl max-h-182.5 overflow-y-auto">
          <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between">
              <div className="text-2xl px-10 pt-10 blue-primary">
                Process report for{' '}
                {props.tableType === 'historical' && moment(props.eligibility?.data?.completed_at).format('YYYY-MM-DD')}
              </div>
            </div>
            <div className="relative px-10 pt-2 flex-auto">
              <p className="text-sm leading-relaxed h-full text-gray-dark text-base font-normal">Report Summary:</p>
              <p className="flex items-center">
                <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                Total Records: {totalRecords}
              </p>
              <p className="flex items-center">
                <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                Existing Members: {existingMemberCount}
              </p>
              <p className="flex items-center">
                <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                New Members: {newMemberCount}
              </p>
              <p className="flex items-center">
                <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                Members to Remove: {removedMemberCount}
              </p>
              <p className="flex items-center">
                <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                Invalid Records: {invalidRecordCount}
              </p>
              <p className="flex items-center">
                <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                Invalid Headers: {props.eligibilityValidationData?.headers_errors?.length}
              </p>
            </div>
            <div>
              {props.eligibilityValidationData?.headers_errors?.length > 0 && (
                <div className="px-10 pt-2">
                  <p className="text-sm leading-relaxed h-full text-red-600 text-base font-normal">Header Errors:</p>
                  <ul className="list-disc list-inside">
                    {props.eligibilityValidationData.headers_errors.map((error: string, index: number) => (
                      <li key={index} className="text-red-600 text-sm">
                        {error}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="text-2xl px-10 pt-2 blue-primary">Process Details:</div>
            <table className="table-fixed table-row-format relative border-collapse mx-10 my-2" key={Math.random()}>
              <thead className="bg-white  h-full ">
                <tr className=" text-left  w-full px-7">
                  {props.tableType === 'historical' ? (
                    <>
                      <th>Title</th>
                      <th>Message</th>
                    </>
                  ) : (
                    <>
                      <th className="px-2 py-1 w-2/12 sticky top-0  bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                        MemberId
                      </th>
                      <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                        Email
                      </th>
                      <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                        Status
                      </th>
                      <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                        Message
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {props.tableType !== 'historical' &&
                  eligibilityList?.length &&
                  eligibilityList.map((item: any, index: any) => {
                    return (
                      <tr className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white">
                        <td className="py-1 w-2/12 text-gray-dark text-bold font-bold border-bottom text-base">
                          {item.memberId}
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2 text-base">{item.email}</p>
                          </div>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2 text-base">{item.status}</p>
                          </div>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2 text-base">{item.message}</p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                {props.tableType === 'historical' &&
                  props.processEligibilityReport?.data?.length > 0 &&
                  props.processEligibilityReport.data.map((item: any, index: number) => {
                    if (item.event_name !== 'processing_summary') {
                      return (
                        <tr
                          className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white"
                          key={Math.random()}
                        >
                          <td className="py-1 border-bottom">{item.event_name}</td>
                          <td>{item.message}</td>
                        </tr>
                      );
                    }
                    return false;
                  })}
              </tbody>
            </table>
            <div className="flex items-center justify-center pb-6">
              <button
                className="px-7 py-2 text-sm bg-gray-light text-white text-cente rounded-full"
                type="button"
                onClick={() => props.handleEligibilityFileViewModel(props.eligibilityId, '')}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    eligibility: state.eligibility.eligibilitySingle,
    processEligibilityReport: state.eligibility.processEligibilityReport,
    processEligibilityReportError: state.eligibility.processEligibilityReportError,
    pending: state.eligibility.pending,
  };
};

export default connect(mapStateToProps)(EligibilityFileViewModel);
