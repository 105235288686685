import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosDelete, axiosGet, axiosPost, axiosPut } from '../api/axios';
import { Tag, useTags } from './useTags';
import { toast } from 'react-toastify';

export type TagGroup = {
  children: TagGroup[];
  description?: string;
  id: number;
  key: string;
  name: string;
  parent_id?: number;
  tag_group_type: 'trait' | 'topic';
  tags: Tag[];
};

interface TagGroupRequestParams {
  keys?: string;
  name?: string;
  type?: string;
}

const getTagGroups = async (params: TagGroupRequestParams): Promise<TagGroup[]> => {
  return await axiosGet('/tag_groups/', { ...params }, 'v2').then((tagGroupsResponse) => tagGroupsResponse.data);
};

export const useTagGroups = (params: TagGroupRequestParams) => {
  const { data, isLoading, error } = useQuery<TagGroup[]>(['tag_groups', params], () => getTagGroups(params));

  return { data, isLoading, error };
};

const getTagGroupById = async (tagGroupId: number): Promise<TagGroup> => {
  return await axiosGet(`/tag_groups/${tagGroupId}`, {}, 'v2').then((tagGroupResponse) => tagGroupResponse.data);
};

export const useTagGroupById = (tagGroupId?: number) => {
  const { data, isLoading, error } = useQuery<TagGroup>(['tag_group', tagGroupId], () => getTagGroupById(tagGroupId!), {
    enabled: !!tagGroupId,
  });

  return { data, isLoading, error };
};

interface TagGroupRequestPayload {
  children?: TagGroup[];
  description?: string;
  key: string;
  name: string;
  parent_id?: number;
  tag_group_type: 'trait' | 'topic';
  tag_ids: number[];
}

const updateTagGroupRequest = async (tagGroupId: number, tagGroup: TagGroupRequestPayload): Promise<TagGroup> => {
  return await axiosPut(`/tag_groups/${tagGroupId}`, tagGroup, 'v2').then((tagGroupResponse) => tagGroupResponse.data);
};

export const useUpdateTagGroup = () => {
  const tags = useTags({ include_hidden: true });
  const queryClient = useQueryClient();
  return useMutation(
    ({ tagGroupId, tagGroup }: { tagGroupId: number; tagGroup: TagGroup }) => {
      const payload = {
        ...tagGroup,
        tags: undefined,
        tag_ids: tagGroup.tags?.map((tag) => tag.id) || undefined,
      };
      return updateTagGroupRequest(tagGroupId, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tag_groups']);
        toast.success('Tag group updated successfully');
      },
      onError: (error: any) => {
        const errorMessage = error?.response?.data?.description || 'Unknown error';
        const tagIdMatch = errorMessage.match(/tag_id (\d+)/);
        if (tagIdMatch && tags) {
          const tagId = parseInt(tagIdMatch[1]);
          const tag = tags?.data?.filter((tag) => tag.id === tagId);
          if (tag) {
            const updatedErrorMessage = errorMessage.replace(`tag_id ${tagId}`, `tag ${tag[0].name}`);
            toast.error(updatedErrorMessage);
          } else {
            toast.error(errorMessage);
          }
        } else {
          toast.error(errorMessage);
        }
      },
    },
  );
};

const deleteTagGroupRequest = async (tagGroupId: number): Promise<void> => {
  return await axiosDelete(`/tag_groups/${tagGroupId}`, {}, 'v2').then((tagGroupResponse) => tagGroupResponse.data);
};

export const useDeleteTagGroup = () => {
  const queryClient = useQueryClient();
  return useMutation((tagGroupId: number) => deleteTagGroupRequest(tagGroupId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['tag_groups']);
      toast.success('Tag group deleted successfully');
    },
    onError: (error: any) => {
      try {
        const parsedError = JSON.parse(error);
        toast.error(parsedError?.data.description);
      } catch (e) {
        toast.error('Unknown error');
      }
    },
  });
};

const createTagGroupRequest = async (tagGroup: TagGroupRequestPayload): Promise<TagGroup> => {
  return await axiosPost(`/tag_groups/`, tagGroup, 'v2').then((tagGroupResponse) => tagGroupResponse.data);
};

export const useCreateTagGroup = () => {
  const queryClient = useQueryClient();
  return useMutation((tagGroup: TagGroupRequestPayload) => createTagGroupRequest(tagGroup), {
    onSuccess: () => {
      queryClient.invalidateQueries(['tag_groups']);
      toast.success('Tag group created successfully');
    },
    onError: (error: any) => {
      try {
        const parsedError = JSON.parse(error);
        toast.error(parsedError?.data.description);
      } catch (e) {
        toast.error('Unknown error');
      }
    },
  });
};

const getFomTagGroups = async (): Promise<TagGroup[]> => {
  return await axiosGet(`/tag_groups/?keys=COMMUNITY,WORK,FINANCES,HEALTH,RELATIONSHIPS,EMOTIONS`, {}).then(
    (tagGroupsResponse) => tagGroupsResponse.data,
  );
};

const getSearchForSupportTags = async (): Promise<Tag[]> => {
  return await axiosGet(`/tags?tag_group_keys=SEARCH_FOR_SUPPORT`, {}, 'v3').then(
    (tagsResponse) => tagsResponse.data.data,
  );
};

export const useSearchForSupportTags = () => {
  const { data, isLoading, error, refetch } = useQuery<Tag[]>(['sfs_tags'], getSearchForSupportTags, {
    staleTime: Infinity,
  });
  return {
    data,
    isLoading,
    error,
    refetch,
  };
};

export const useFomTagGroups = () => {
  const { data, isLoading, error, refetch } = useQuery<TagGroup[]>(['tag_groups'], getFomTagGroups, {
    staleTime: Infinity,
  });

  const getById = (id: number) => {
    return data?.find((tagGroup) => tagGroup.id === id);
  };

  const getParentByParentId = (parentId: number) => {
    return data?.find((tagGroup) => tagGroup.id === parentId);
  };

  return {
    data,
    isLoading,
    error,
    refetch,
    getById,
    getParentByParentId,
  };
};
