import { useEffect, useState, useCallback } from 'react';
import { useDispatch, connect } from 'react-redux';
import debounce from '../../../components/common/debounce';
import {
  fetchEmailAvailableRequest,
  fetchMobileValidRequest,
} from '../../../components/account-login/login-redux/actions';
import { CustomInput, DropDown, ToggleButton } from '../../../components/common';
import { paramsToObjects } from '../../../components/common/create-params';
import Modal from '../../../components/common/modal';
import { useDidMountEffect } from '../../../components/custom-hook';
import { updateListenerDetailsRequest } from '../listeners-redux/actions';
import AlertBox from '../../../components/route-leaving-prompt/alert-box';
import { PeerType } from '../../../hooks/usePeers';
type props = {
  listenerDetails: any;
  emailAvailable: any;
  appSettings: any;
  tags: any;
};

const EditDetails: React.FunctionComponent<props> = ({
  listenerDetails,
  emailAvailable,
  appSettings,
  tags,
}): JSX.Element => {
  const params = paramsToObjects();
  const listenerId = params['listenerRoleId'];
  const [modal, setmodal] = useState(false);
  const [alertModal, setalertModal] = useState(false);
  const userId = params['listenerId'];
  const [userDetails, setuserDetails] = useState<any>();
  const [errorMessages, seterrorMessages] = useState<any>({});
  const [isAvailableEmail, setIsAvailableEmail] = useState<boolean>(true);
  const [timeZones, setTimeZones] = useState<any>([]);
  const [enableSaveOption, setEnableSaveOption] = useState(false);
  const [isOnloadMobileRequest, setIsOnloadMobileRequest] = useState<boolean>(true);

  const dispatch = useDispatch();
  const emailDebounce = debounce(userDetails?.email_address, 500);

  useDidMountEffect(() => {
    if (userDetails?.email_address !== listenerDetails?.email_address)
      dispatch(fetchEmailAvailableRequest({ email: userDetails?.email_address }));
  }, [emailDebounce]);

  useEffect(() => {
    if (listenerDetails) {
      setDefaultOptions();
    }
  }, [listenerDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const regexPhoneNumber = useCallback(
    (data: any) => {
      if (data?.length > 0) {
        const phone = data?.replace('+1', '');
        //@ts-ignore
        setUserDetails('mobile_phone', normalizeInput(phone));
      } else {
        setUserDetails('mobile_phone', '');
      }
      if (data.length > 9 && data.length <= 14) {
        const phone = data?.replace('+1', '');
        dispatch(fetchMobileValidRequest({ mobile_phone: phone }));
      }
    },
    [dispatch],
  );

  const setDefaultOptions = useCallback(() => {
    const {
      mobile_phone,
      first_name,
      last_name,
      display_name,
      family,
      gender,
      relationship,
      spirituality,
      city,
      state,
      street_address_1,
      street_address_2,
      zip_code,
      timezone,
      date_of_birth,
      pronoun,
      race,
      email_address,
      listener_role: { background_check_verified, required_training_complete, state: listener_state },
    } = listenerDetails;
    setuserDetails({
      mobile_phone,
      first_name,
      last_name,
      display_name,
      family,
      gender,
      relationship,
      spirituality,
      city,
      state,
      street_address_1,
      street_address_2,
      zip_code,
      timezone,
      date_of_birth,
      pronoun,
      race,
      email_address,
      listener_role: {
        background_check_verified,
        required_training_complete,
        state: listener_state,
      },
    });
    const phone = listenerDetails.mobile_phone.replace('+1', '');
    if (isOnloadMobileRequest) {
      regexPhoneNumber(listenerDetails.mobile_phone);
      setIsOnloadMobileRequest(false);
    } else {
      //@ts-ignore
      setUserDetails('mobile_phone', normalizeInput(phone));
    }
    setEnableSaveOption(false);
  }, [isOnloadMobileRequest, listenerDetails, regexPhoneNumber]);

  const normalizeInput = (value: string) => {
    // return nothing if no value
    if (!value) return value;
    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    if (cvLength > 0) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  };

  const setUserDetails = (name: string, value: string | number) => {
    setEnableSaveOption(true);
    setuserDetails((prev: any) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (appSettings) {
      const app = appSettings?.data?.app;
      if (app) {
        setTimeZones(app.supported_timezones);
      }
    }
  }, [appSettings]);

  useEffect(() => {
    if (emailAvailable && userDetails && listenerDetails) {
      setIsAvailableEmail(emailAvailable?.data);

      const { email_address } = userDetails;

      const emailRegex = /\S+@\S+\.\S+/;

      if (!emailRegex.test(email_address) || email_address === '') {
        return seterrorMessages({ email_address: 'Enter valid email address' });
      } else if (!emailAvailable?.data && listenerDetails.email_address !== email_address) {
        seterrorMessages({ email_address: 'Email already used, use different email' });
      } else if (emailAvailable?.data) {
        seterrorMessages({});
      }
    }
  }, [emailAvailable]); // eslint-disable-line react-hooks/exhaustive-deps

  const validation = () => {
    try {
      const { email_address } = userDetails;

      const emailRegex = /\S+@\S+\.\S+/;

      if (!emailRegex.test(email_address) || email_address === '') {
        return seterrorMessages({ email_address: 'Enter valid email address' });
      } else if (isAvailableEmail === false && listenerDetails.email_address !== email_address) {
        return seterrorMessages({
          email_address: 'Email already used, use different email',
        });
      }

      seterrorMessages({});

      return true;
    } catch (e) {}
  };

  const handleSave = () => {
    const payload = {
      ...userDetails,
    };
    if (!userDetails?.date_of_birth) {
      delete payload.date_of_birth;
    }
    if (listenerDetails.display_name === userDetails.display_name) {
      delete payload.display_name;
    }

    if (validation()) {
      setmodal(false);
      setalertModal(false);
      const requestPayload = {
        id: userId,
        listenerId,
        payload,
      };
      dispatch(updateListenerDetailsRequest(requestPayload));
    }
  };

  useEffect(() => {
    if (modal && listenerDetails) {
      setDefaultOptions();
    }
  }, [modal, listenerDetails, setDefaultOptions]);

  return (
    <>
      <button
        onClick={() => {
          setmodal(true);
        }}
        className=" text-center h-10 text-sm text-white px-2 bg-blue-primary w-28 rounded-sm "
      >
        Edit Details
      </button>
      <Modal
        isModel={modal}
        InnerComponent={
          <div className="inline-block align-bottom bg-white overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
            <div className="bg-white px-2 pt-3 pb-2 sm:p-6 sm:pb-4">
              <div className="flex justify-start ">
                <div>
                  <h3 className="text-2xl font-normal blue-primary  py-1">Edit Peer</h3>
                </div>
              </div>
              <div className="py-2 flex flex-start">
                <h3 className="text-lg  font-bold text-gray-dark  py-1">Details</h3>
              </div>
              <div className="grid grid-cols-3 gap-3 gap-y-5 mr-5">
                <div>
                  <CustomInput
                    inputType={'text'}
                    value={userDetails?.display_name}
                    setValue={(data) => setUserDetails('display_name', data)}
                    Label={'Display Name'}
                    Error={errorMessages.display_name?.length > 0 ? true : false}
                    ErrorMessage={errorMessages.display_name}
                    dataTestId="edit-display-name"
                  />
                </div>
                <div>
                  <CustomInput
                    inputType={'text'}
                    value={userDetails?.first_name}
                    setValue={(data) => setUserDetails('first_name', data)}
                    Label={'First Name'}
                    Error={errorMessages.first_name?.length > 0 ? true : false}
                    ErrorMessage={errorMessages.first_name}
                    dataTestId="edit-first-name"
                  />
                </div>
                <div>
                  <CustomInput
                    inputType={'text'}
                    value={userDetails?.last_name}
                    setValue={(data) => setUserDetails('last_name', data)}
                    Label={'Last Name'}
                    Error={errorMessages.last_name?.length > 0 ? true : false}
                    ErrorMessage={errorMessages.last_name}
                    dataTestId="edit-last-name"
                  />
                </div>

                <div>
                  <CustomInput
                    inputType={'text'}
                    value={userDetails?.mobile_phone}
                    setValue={(data) => setUserDetails('mobile_phone', data)}
                    Label={'Phone'}
                    Error={errorMessages.mobile_phone?.length > 0 ? true : false}
                    ErrorMessage={errorMessages.mobile_phone}
                    dataTestId="edit-phone"
                  />
                </div>
                <div>
                  <CustomInput
                    inputType={'text'}
                    value={userDetails?.email_address}
                    setValue={(data) => setUserDetails('email_address', data)}
                    Label={'Email'}
                    Error={errorMessages.email_address?.length > 0 ? true : false}
                    ErrorMessage={errorMessages.email_address}
                    dataTestId="edit-email"
                  />
                </div>
                <div>
                  <CustomInput
                    inputType={'date'}
                    value={userDetails?.date_of_birth}
                    setValue={(data) => setUserDetails('date_of_birth', data)}
                    Label={'DOB'}
                    Error={errorMessages.date_of_birth?.length > 0 ? true : false}
                    ErrorMessage={errorMessages.date_of_birth}
                    dataTestId="edit-dob"
                  />
                </div>
                <div>
                  <CustomInput
                    inputType={'text'}
                    value={userDetails?.street_address_1}
                    setValue={(data) => setUserDetails('street_address_1', data)}
                    Label={'Address Line 1'}
                    Error={errorMessages.street_address_1?.length > 0 ? true : false}
                    ErrorMessage={errorMessages.street_address_1}
                    dataTestId="edit-address-line1"
                  />
                </div>
                <div>
                  <CustomInput
                    inputType={'text'}
                    value={userDetails?.street_address_2}
                    setValue={(data) => setUserDetails('street_address_2', data)}
                    Label={'Address Line 2'}
                    Error={errorMessages.street_address_2?.length > 0 ? true : false}
                    ErrorMessage={errorMessages.street_address_2}
                    dataTestId="edit-address-line2"
                  />
                </div>
                <div>
                  <CustomInput
                    inputType={'text'}
                    value={userDetails?.city}
                    setValue={(data) => setUserDetails('city', data)}
                    Label={'City'}
                    Error={errorMessages.city?.length > 0 ? true : false}
                    ErrorMessage={errorMessages.city}
                    dataTestId="edit-city"
                  />
                </div>
                <div>
                  <CustomInput
                    inputType={'text'}
                    value={userDetails?.state}
                    setValue={(data) => setUserDetails('state', data)}
                    Label={'State'}
                    Error={errorMessages.state?.length > 0 ? true : false}
                    ErrorMessage={errorMessages.state}
                    dataTestId="edit-state"
                  />
                </div>
                <div>
                  <CustomInput
                    inputType={'text'}
                    value={userDetails?.zip_code}
                    setValue={(data) => setUserDetails('zip_code', data)}
                    Label={'Zip Code'}
                    Error={errorMessages.zip_code?.length > 0 ? true : false}
                    ErrorMessage={errorMessages.zip_code}
                    dataTestId="edit-zip-code"
                  />
                </div>
                <div>
                  <DropDown
                    Label={'Timezone'}
                    labelClass="float-left block leading-4 text-gray-dark mb-2"
                    value={userDetails?.timezone}
                    setValue={(data) => {
                      setEnableSaveOption(true);
                      setuserDetails((prev: any) => ({
                        ...prev,
                        timezone: data === '' ? null : data,
                      }));
                    }}
                    data-testid="edit-timezone"
                  >
                    <>
                      <option key={'timezone'} value={''}>
                        Select TimeZone
                      </option>
                      {timeZones?.length > 0 &&
                        timeZones?.map((timezone: string) => (
                          <option key={timezone} value={timezone}>
                            {timezone}
                          </option>
                        ))}
                    </>
                  </DropDown>
                </div>
              </div>
              <div className="flex flex-start py-2">
                <h3 className="text-lg  font-bold text-gray-dark  py-3">Matching Demographics</h3>
              </div>
              <div className="grid grid-cols-4 gap-2">
                <DropDown
                  Label={'Gender'}
                  labelClass="float-left block leading-4 text-gray-dark mb-2"
                  value={userDetails?.gender}
                  setValue={(data) => setUserDetails('gender', data)}
                  data-testid="edit-gender"
                >
                  {tags.genders?.length > 0 &&
                    tags.genders.map((item: string) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                </DropDown>

                <DropDown
                  Label={'Pronouns'}
                  labelClass="float-left block leading-4 text-gray-dark mb-2"
                  value={userDetails?.pronoun}
                  setValue={(data) => {
                    setEnableSaveOption(true);

                    setuserDetails((prev: any) => ({
                      ...prev,
                      pronoun: data === '' ? null : data,
                    }));
                  }}
                  data-testid="edit-pronouns"
                >
                  <>
                    <option key={'pronoun'} value={''}>
                      Pronouns
                    </option>
                    {tags.pronouns?.length > 0 &&
                      tags.pronouns?.map((pronoun: string) => (
                        <option key={pronoun} value={pronoun}>
                          {pronoun}
                        </option>
                      ))}
                  </>
                </DropDown>
                <DropDown
                  Label={'Race'}
                  labelClass="float-left block leading-4 text-gray-dark mb-2"
                  value={userDetails?.race}
                  setValue={(data) => {
                    setEnableSaveOption(true);
                    setuserDetails((prev: any) => ({
                      ...prev,
                      race: data === '' ? null : data,
                    }));
                  }}
                  data-testid="edit-race"
                >
                  <>
                    <option key={'none'} value={''}>
                      None
                    </option>
                    {tags.races?.length > 0 &&
                      tags.races?.map((race: string) => (
                        <option key={race} value={race}>
                          {race}
                        </option>
                      ))}
                  </>
                </DropDown>
                <DropDown
                  Label={'Family'}
                  labelClass="float-left block leading-4 text-gray-dark mb-2"
                  value={userDetails?.family}
                  setValue={(data) => {
                    setEnableSaveOption(true);

                    setuserDetails((prev: any) => ({
                      ...prev,
                      family: data === '' ? null : data,
                    }));
                  }}
                  data-testid="edit-family"
                >
                  <option key={'pronoun'} value={''}>
                    None
                  </option>
                  {tags.family?.length > 0 &&
                    tags.family?.map((family: string) => (
                      <option key={family} value={family}>
                        {family}
                      </option>
                    ))}
                </DropDown>
                <DropDown
                  Label={'Spirituality'}
                  labelClass="float-left block leading-4 text-gray-dark mb-2"
                  value={userDetails?.spirituality}
                  setValue={(data) => {
                    setEnableSaveOption(true);

                    setuserDetails((prev: any) => ({
                      ...prev,
                      spirituality: data === '' ? null : data,
                    }));
                  }}
                  data-testid="edit-spirituality"
                >
                  <option key={'spirituaily'} value={''}>
                    None
                  </option>
                  {tags.spirituaily?.length > 0 &&
                    tags.spirituaily?.map((spirituaily: string) => (
                      <option key={spirituaily} value={spirituaily}>
                        {spirituaily}
                      </option>
                    ))}
                </DropDown>
                <DropDown
                  Label={'Relationship'}
                  labelClass="float-left block leading-4 text-gray-dark mb-2"
                  value={userDetails?.relationship}
                  setValue={(data) => {
                    setEnableSaveOption(true);
                    setuserDetails((prev: any) => ({
                      ...prev,
                      relationship: data === '' ? null : data,
                    }));
                  }}
                  data-testid="edit-relationship"
                >
                  <option key={'relationship'} value={''}>
                    None
                  </option>
                  {tags.relationhip?.length > 0 &&
                    tags.relationhip?.map((relationship: string) => (
                      <option key={relationship} value={relationship}>
                        {relationship}
                      </option>
                    ))}
                </DropDown>
              </div>
              {(userDetails?.listener_role?.state === PeerType.ACTIVE_LISTENER ||
                userDetails?.listener_role?.state === PeerType.UPGRADING_PEER) && (
                <>
                  <div className="flex flex-start py-2">
                    <h3 className="text-lg  font-bold text-gray-dark  py-3">Listener Role Details</h3>
                  </div>
                  <div className="flex gap-2 flex-wrap">
                    <div className="py-2 w-1/2 flex items-center justify-between">
                      <div className="text-md text-gray-dark">Background Check Complete:</div>
                      <div className="">
                        <ToggleButton
                          id={'backgroundCheckVerified'}
                          status={userDetails?.listener_role.background_check_verified ? true : false}
                          setStatus={(data) => {
                            setuserDetails((prev: any) => ({
                              ...prev,
                              listener_role: {
                                ...prev.listener_role,
                                background_check_verified: data ? true : false,
                              },
                            }));
                            setEnableSaveOption(true);
                          }}
                          initialstatus={true}
                        ></ToggleButton>
                      </div>
                    </div>
                    <div className="py-2 w-1/2 flex items-center justify-between">
                      <div className="text-md text-gray-dark float-left">Listener Academy Complete:</div>
                      <div className="">
                        <ToggleButton
                          id={'requiredTrainingComplete'}
                          status={userDetails?.listener_role.required_training_complete ? true : false}
                          setStatus={(data) => {
                            setuserDetails((prev: any) => ({
                              ...prev,
                              listener_role: {
                                ...prev.listener_role,
                                required_training_complete: data ? true : false,
                              },
                            }));
                            setEnableSaveOption(true);
                          }}
                          initialstatus={true}
                        ></ToggleButton>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="flex justify-end ">
                <div>
                  <button
                    className={
                      ' text-center h-8 text-sm text-white px-2  w-16 rounded-sm ' +
                      (enableSaveOption ? 'bg-blue-primary' : 'bg-gray-200')
                    }
                    onClick={handleSave}
                  >
                    Save
                  </button>
                  <button
                    className=" text-center h-8 text-sm text-white px-2 bg-blue-primary w-16 rounded-sm ml-4 "
                    onClick={() => {
                      if (enableSaveOption) {
                        setalertModal(true);
                      } else {
                        setmodal(false);
                      }
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <AlertBox
              visible={alertModal}
              handlePrompt={() => {
                setDefaultOptions();
                setmodal(false);
                setalertModal(false);
              }}
              handleContinueSave={handleSave}
              closeModal={() => {
                setalertModal(false);
              }}
              confirmButtonText={'Discard Changes'}
              titleText={'Alert'}
              contentText={
                'You have unsaved changes.  If you leave this screen without saving, your changes will be lost.'
              }
              confirmSaveButtonText={'Save Changes'}
              cancelButtonText={'Cancel'}
            />
          </div>
        }
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    listenerDetails: state.Listeners?.listenersDetails?.data || null,
    emailAvailable: state.login.emailAvailable,
    appSettings: state.Tos.clientAdmin?.data || null,
  };
};

export default connect(mapStateToProps)(EditDetails);
