import * as React from 'react';
import useAuth from '../../hooks/useAuth';
import { Spinner } from '../../components/common';

const Login: React.FunctionComponent = (): JSX.Element => {
  const { keycloak, isAuthenticated } = useAuth();

  if (keycloak && !isAuthenticated) {
    keycloak.login();
  }

  return <Spinner />;
};
export default Login;
