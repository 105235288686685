import React, { useState, useCallback } from 'react';
import Header from '../../components/common/global-top-bar';
import DisplayNameRejectModal from './common/reject-message-modal';
import ReviewPageControls, { FilterOptionsType, FilterType } from './common/review-page-controls';
import { RejectModalDataType, ListenerDataType, CountResponseType } from './common/types';
import Spinner from '../../components/common/spinner';
import { DropDown } from '../../components/common';
import SwitchBar from '../../components/switch-bar';
import { HeaderOptions } from './common/listener-review-constants';
import { useHistory } from 'react-router';
import { ListenerRole, usePeers } from '../../hooks/usePeers';
import { useUpdateUser } from '../../hooks/useUsers';
import { toast } from 'react-toastify';

const displayNames: FilterOptionsType = {
  pending: 'Needs Approval',
  approved: 'Approved',
  rejected: 'Rejected',
};

type HandleSaveType = (userId: number, displayNameStatus: string) => void;

interface TableRowProps {
  displayName: string;
  proposedDisplayName: string;
  displayNameRejected: boolean;
  firstName: string;
  lastName: string;
  emailAddress: string;
  userId: number;
  listenerId: number;
  handleSave: HandleSaveType;
}

const TableRow: React.FunctionComponent<TableRowProps> = ({
  displayName,
  proposedDisplayName,
  displayNameRejected,
  firstName,
  lastName,
  emailAddress,
  userId,
  listenerId,
  handleSave,
}): JSX.Element => {
  const history = useHistory();

  const evaluateDisplayNameState = (): string => {
    if (displayNameRejected) {
      return 'rejected';
    } else if (proposedDisplayName || !displayName) {
      return 'pending';
    } else {
      return 'approved';
    }
  };

  const [displayNameApprovalStatus, setDisplayNameApprovalStatus] = useState<string>();
  const [isDirty, setIsDirty] = useState<boolean>(false);

  useState(() => {
    if (userId && !displayNameApprovalStatus) {
      setDisplayNameApprovalStatus(evaluateDisplayNameState());
    }
  });

  const handleChange = (data: string) => {
    setDisplayNameApprovalStatus(data);
    setIsDirty(true);
  };

  return (
    <tr className="text-left w-full px-7">
      <td></td>
      <td>
        <div>{proposedDisplayName || <i>No proposed display name</i>}</div>
        <div>{displayName || <i>No approved display name</i>}</div>
      </td>
      <td>
        <div className="flex">
          <div>
            <DropDown Label="" value={displayNameApprovalStatus} setValue={handleChange}>
              {Object.keys(displayNames)
                .filter((value: string) => evaluateDisplayNameState() === 'pending' || value !== 'pending')
                .map((value: string) => (
                  <option key={value} value={value}>
                    {displayNames[value]}
                  </option>
                ))}
            </DropDown>
          </div>
          <div className="pl-5">
            <button
              type="button"
              disabled={!isDirty}
              className={`${!isDirty ? 'bg-gray-200 text-black' : 'bg-bright-blue text-white'} rounded-lg py-1 px-3 w-auto`}
              onClick={() => {
                if (displayNameApprovalStatus) {
                  handleSave(userId, displayNameApprovalStatus);
                  setIsDirty(false);
                }
              }}
            >
              Save
            </button>
          </div>
        </div>
      </td>
      <td>{firstName}</td>
      <td>{lastName}</td>
      <td>{emailAddress}</td>
      <td>
        <button
          onClick={() => {
            history.push(`/listeners/profile/?listenerRoleId=${listenerId}&listenerId=${userId}`);
          }}
        >
          {userId}
        </button>
      </td>
    </tr>
  );
};

type DisplayNameReviewProps = {
  listeners: ListenerDataType[];
  listenersCount: CountResponseType;
  pending: boolean;
};

type DisplayNameFilterType = {
  page: number;
  limit: number;
  search: string;
  displayNameStatus?: string;
  hideIncompletePeers: boolean;
};
const displayNameFilterOptions = {
  ...displayNames,
  all: 'All',
};

const DisplayNameReview: React.FunctionComponent<DisplayNameReviewProps> = (props): JSX.Element => {
  const { updateUser } = useUpdateUser();
  const [filters, setFilters] = useState<DisplayNameFilterType>({
    page: 1,
    limit: 25,
    search: '',
    displayNameStatus: 'pending',
    hideIncompletePeers: true,
  });
  const { data: listeners, isLoading } = usePeers({ ...filters });
  const [messagePopUp, setMessagePopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState<number>();

  const updateFilter = useCallback(
    (filter: FilterType) => {
      setFilters((prev: DisplayNameFilterType) => {
        return {
          page: filter.page,
          limit: filter.limit,
          search: filter.search,
          displayNameStatus: filter.filterValue !== 'all' ? filter.filterValue : undefined,
          hideIncompletePeers: true,
        };
      });
    },
    [setFilters],
  );

  const getSelectedUserData = (userId: number) => {
    return listeners?.data?.find((listener: any) => {
      return listener.user.id === userId;
    });
  };

  const handleSaveRejected = (rejectModalData: RejectModalDataType) => {
    const userId = selectedUser!;
    const rejectedMessagePayload = rejectModalData.send_message ? rejectModalData : {};
    const payload = {
      display_name_rejected: true,
      ...rejectedMessagePayload,
    };
    updateUser.mutate(
      { userId, data: payload },
      {
        onSuccess: () => {
          toast.success('Profile updated successfully');
        },
      },
    );
    handleRejectModalCancel();
  };

  const handleSaveApprove = (userId: number) => {
    const selectedUserData = getSelectedUserData(userId);
    const payload = {
      display_name: selectedUserData?.user.proposed_display_name || selectedUserData?.user.display_name || '',
    };
    updateUser.mutate(
      { userId, data: payload },
      {
        onSuccess: () => {
          toast.success('Profile updated successfully');
        },
      },
    );
  };

  const handleSave: HandleSaveType = (userId, displayNameStatus) => {
    switch (displayNameStatus) {
      case 'approved':
        handleSaveApprove(userId);
        break;
      case 'rejected':
        setSelectedUser(userId);
        setMessagePopup(true);
        break;
    }
  };

  const handleRejectModalCancel = () => {
    setSelectedUser(undefined);
    setMessagePopup(false);
  };

  return (
    <div>
      {isLoading && <Spinner />}
      <Header heading={'Display Name'} />
      <SwitchBar heading={HeaderOptions} position={3} />
      <ReviewPageControls
        updateFilter={updateFilter}
        count={listeners?.count}
        filterOptions={displayNameFilterOptions}
        defaultFilterValue="pending"
      />

      <div className="max-window-height overflow-y-auto px-7">
        <div className="table-overflow">
          <table className="table-fixed table-row-format w-full relative border-collapse">
            <thead className="bg-white h-full">
              <tr className="text-left w-full px-7">
                <th className="sticky top-0 bg-white h-full mb-4 text-left w-8"></th>
                <th className="py-2 sticky top-0 bg-white h-full mb-4 w-64">Display Name</th>
                <th className="sticky top-0 bg-white h-full mb-4 text-left w-80">Action</th>

                <th className="sticky top-0 bg-white h-full mb-4 w-44">First Name</th>
                <th className="sticky top-0 bg-white h-full mb-4 w-44">Last Name</th>
                <th className="sticky top-0 bg-white h-full mb-4 w-80">Email Address</th>
                <th className="sticky top-0 bg-white h-full mb-4 w-15">Peer ID</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-2"></tr>
              {listeners &&
                listeners.count > 0 &&
                listeners.data.map((listenerData: ListenerRole) => (
                  <TableRow
                    key={listenerData?.id}
                    displayName={listenerData?.user?.display_name || ''}
                    proposedDisplayName={listenerData?.user?.proposed_display_name || ''}
                    displayNameRejected={listenerData?.user?.display_name_rejected}
                    lastName={listenerData?.user?.last_name || ''}
                    firstName={listenerData?.user?.first_name || ''}
                    emailAddress={listenerData?.user.email_address}
                    userId={listenerData?.user.id}
                    listenerId={listenerData?.id}
                    handleSave={handleSave}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <DisplayNameRejectModal
        messagePopup={messagePopUp}
        handleModalCancel={handleRejectModalCancel}
        handleModalSave={handleSaveRejected}
      />
    </div>
  );
};

export default DisplayNameReview;
