import LandingPage from '../containers/landing-page';
import Peers from '../containers/peer-listing';
import Checkins from '../containers/check-ins';
import Requests from '../containers/requests';
import Profile from '../containers/peer-profiles/profile';
import Engagement from '../containers/peer-profiles/engagement';
import Connections from '../containers/peer-profiles/connections';
import Additional from '../containers/peer-profiles/Additional';
import Log from '../containers/peer-profiles/log';
import OnCall from '../containers/peer-profiles/on-call';
import MembersProfile from '../containers/members/profile';
import Members from '../containers/members';
import MemberConnections from '../containers/members/connections';
import MembersRewards from '../containers/members/rewards';
import Comms from '../containers/members/comms';
import memberLog from '../containers/members/log';
import MemberEngagements from '../containers/members/engagement';
import Calls from '../containers/connections';
import Stories from '../containers/stories';
import Topics from '../containers/topics';
import Clients from '../containers/clients';
import Packages from '../containers/clients/packages';
import Configure from '../containers/clients/configure';
import ClientResources from '../containers/clients/resources';
import Demographics from '../containers/client-configuration/engagement-details';
import EligibilityFile from '../containers/client-configuration/eligibilty-file';
import RichTextEditor from '../components/common/rich-text-editor';
import Reporting from '../containers/clients/reporting';
import Campaigns from '../containers/templates/campaigns';
import TemplateComms from '../containers/templates/comms';
import Admins from '../containers/clients/admins';
import Tiers from '../containers/listener-tiers';
import TiersDetails from '../containers/listener-tiers/tier-details';
import Tags from '../containers/tags-management/tags';
import TagGroups from '../containers/tags-management/tag-groups';
import ProfileAudioReview from '../containers/peer-profiles/audio';
import ProfileVideoReview from '../containers/peer-profiles/video';
import Payout from '../containers/peer-profiles/payout';
import Resources from '../containers/resources';
import ResourceDetails from '../containers/resources/resource-details';
import GlobalDashboardPeers from '../containers/landing-page/global-dashboard-peers';
import GlobalDashboardTopics from '../containers/landing-page/global-dashboard-topics';
import GlobalDashboardNavigation from '../containers/landing-page/global-dashboard-navigation';
import blacklist from '../containers/user-safety/blacklist';
import CallerBlockList from '../containers/user-safety/caller-block-list';
import UserManagement from '../containers/user-safety/user-managment';
import Settings from '../containers/user-safety/settings';
import Users from '../containers/users';
import { SearchQueriesPage, SearchQueriesTestPage } from '../containers/search-queries/pages';
import PlusCarePage from '../containers/plus-care/plus-care';
import { SSOConfiguration } from '../containers/clients/sso-configuration';
import { LinksPage } from '../containers/links/links';
import { ROUTE_PATH } from './paths';
import PrivateRoute from './privateRoute';
import { publicRoutes } from './public-router';
import { Redirect, Route, Switch } from 'react-router';
import { clientAdminRoutes } from './clientadmin-router';
import { UserRole } from '../containers/navigation-bar/roles';
import MemberTOS from '../containers/clients/text-blocks/members';
import ClientAdminTOS from '../containers/clients/text-blocks/client-adminstrator';
import ListenerTOS from '../containers/clients/text-blocks/listeners';
import Files from '../containers/files';
import AudioReview from '../containers/listener-reviewers/audio-review';
import VideoReview from '../containers/listener-reviewers/video-review';
import AvatarReview from '../containers/listener-reviewers/avatar-review';
import DisplayNameReview from '../containers/listener-reviewers/display-name-review';
import AboutMeReview from '../containers/listener-reviewers/about-me-review';
import BackgroundTagApproval from '../containers/listener-reviewers/background-tag-approval';
import CallReporting from '../containers/reports/call-reports/index';
import EngagementReporting from '../containers/reports/engagement-reports';
import ClientEngagementReporting from '../containers/reports/client-engagement-reports';
import TopSearches from '../containers/top-searches';

const adminRoutes = [
  {
    path: ROUTE_PATH.DASHBOARD,
    component: LandingPage,
    id: 'Dashboard',
  },
  {
    path: ROUTE_PATH.DASHBOARD_OVERVIEW,
    component: LandingPage,
    id: 'Dashboard',
  },
  {
    path: ROUTE_PATH.DASHBOARD_ENGAGEMENT,
    component: LandingPage,
    id: 'Dashboard',
  },
  {
    path: ROUTE_PATH.SEARCH_QUERIES,
    component: SearchQueriesPage,
    id: 'SearchQueries',
  },
  {
    path: ROUTE_PATH.SEARCH_QUERIES_TEST,
    component: SearchQueriesTestPage,
    id: 'SearchQueries',
  },
  {
    path: ROUTE_PATH.LINKS,
    component: LinksPage,
    id: 'Links',
  },
  {
    path: ROUTE_PATH.PLUS_CARE,
    component: PlusCarePage,
    id: '+Care',
  },
  {
    path: ROUTE_PATH.DASHBOARD_TOPICS,
    component: GlobalDashboardTopics,
    id: 'Dashboard',
  },
  {
    path: ROUTE_PATH.DASHBOARD_PEERS,
    component: GlobalDashboardPeers,
    id: 'Dashboard',
  },
  {
    path: ROUTE_PATH.DASHBOARD_NAVIGATION,
    component: GlobalDashboardNavigation,
    id: 'Dashboard',
  },
  {
    path: ROUTE_PATH.PEERS,
    component: Peers,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.PEER_LISTENERS,
    component: Peers,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.TEMPLATES_CAMPAIGNS,
    component: Campaigns,
    id: 'Templates',
  },
  {
    path: ROUTE_PATH.TEMPLATES_COMMS,
    component: TemplateComms,
    id: 'Templates',
  },
  {
    path: ROUTE_PATH.CHECKINS,
    component: Checkins,
    id: 'Check-Ins',
  },
  {
    path: ROUTE_PATH.REQUESTS,
    component: Requests,
    id: 'Requests',
  },
  {
    path: ROUTE_PATH.DEMOGRAPHICS,
    component: Demographics,
    id: 'Demographics',
  },
  {
    path: ROUTE_PATH.PROJECT_MANAGEMENT,
    component: RichTextEditor,
    id: 'Rich Text Editor',
  },
  {
    path: ROUTE_PATH.BLACKLIST,
    component: blacklist,
    id: 'Safety',
  },
  {
    path: ROUTE_PATH.BLOCKED,
    component: CallerBlockList,
    id: 'Safety',
  },
  {
    path: ROUTE_PATH.USER_MANAGEMENT,
    component: UserManagement,
    id: 'Safety',
  },
  {
    path: ROUTE_PATH.LISTENERS_PROFILE,
    component: Profile,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.PEERS_PROFILE,
    component: Profile,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.LISTENERS_ENGAGEMENT,
    component: Engagement,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.PEERS_ENGAGEMENT,
    component: Engagement,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.LISTENERS_CONNECTION,
    component: Connections,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.LISTENERS_AVAILABLE,
    component: Additional,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.LISTENER_AUDIO_REVIEW,
    component: ProfileAudioReview,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.PEER_AUDIO_REVIEW,
    component: ProfileAudioReview,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.LISTENER_VIDEO_REVIEW,
    component: ProfileVideoReview,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.PEER_VIDEO_REVIEW,
    component: ProfileVideoReview,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.PEER_PAYOUT,
    component: Payout,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.LISTENER_PAYOUT,
    component: Payout,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.LISTENERS_LOG,
    component: Log,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.LISTENERS_ON_CALL,
    component: OnCall,
    id: 'Peers',
  },
  {
    path: ROUTE_PATH.MEMBERS,
    component: Members,
    id: 'Members',
  },
  {
    path: ROUTE_PATH.MEMBERS_PROFILE,
    component: MembersProfile,
    id: 'Members',
  },
  {
    path: ROUTE_PATH.MEMBERS_CONNECTIONS,
    component: MemberConnections,
    id: 'Members',
  },
  {
    path: ROUTE_PATH.MEMBERS_REWARDS,
    component: MembersRewards,
    id: 'Members',
  },
  {
    path: ROUTE_PATH.MEMBERS_COMMS,
    component: Comms,
    id: 'Members',
  },
  {
    path: ROUTE_PATH.MEMBERS_LOG,
    component: memberLog,
    id: 'Members',
  },
  {
    path: ROUTE_PATH.MEMBERS_ENGAGEMENT,
    component: MemberEngagements,
    id: 'Members',
  },
  {
    path: ROUTE_PATH.SETTINGS,
    component: Settings,
    id: 'Settings',
  },
  {
    path: ROUTE_PATH.CALLS,
    component: Calls,
    id: 'Calls',
  },
  {
    path: ROUTE_PATH.STORIES,
    component: Stories,
    id: 'Stories',
  },
  {
    path: ROUTE_PATH.TOPICS,
    component: Topics,
    id: 'Topics',
  },
  {
    path: ROUTE_PATH.GROUPS,
    component: Clients,
    id: 'Groups',
  },
  {
    path: ROUTE_PATH.GROUPS_PACKAGES,
    component: Packages,
    id: 'Groups',
  },
  {
    path: ROUTE_PATH.GROUPS_CONFIGURE,
    component: Configure,
    id: 'Groups',
  },
  {
    path: ROUTE_PATH.GROUPS_ELIGIBILITY,
    component: EligibilityFile,
    id: 'Groups',
  },
  {
    path: ROUTE_PATH.GROUPS_RESOURCES,
    component: ClientResources,
    id: 'Groups',
  },
  {
    path: ROUTE_PATH.GROUPS_ADMINS,
    component: Admins,
    id: 'Group Admin',
  },
  {
    path: ROUTE_PATH.GROUPS_SSO_CONFIGURATION,
    component: SSOConfiguration,
    id: 'Groups-SSO-Configuration',
  },
  {
    path: ROUTE_PATH.REPORTING,
    component: Reporting,
    id: 'Groups',
  },
  {
    path: ROUTE_PATH.TIERS,
    component: Tiers,
    id: 'Tiers',
  },
  {
    path: ROUTE_PATH.TIER_DETAILS,
    component: TiersDetails,
    id: 'Tiers',
  },
  {
    path: ROUTE_PATH.TAGS,
    component: Tags,
    id: 'Tags',
  },
  {
    path: ROUTE_PATH.TAG_GROUPS,
    component: TagGroups,
    id: 'Tags',
  },
  {
    path: ROUTE_PATH.RESOURCES,
    component: Resources,
    id: 'Resources',
  },
  {
    path: ROUTE_PATH.SYSTEM_USERS,
    component: Users,
    id: 'Users',
  },
  {
    path: ROUTE_PATH.MEMBERSTOC,
    component: MemberTOS,
    role: UserRole.admin,
    id: 'TextBlocks',
  },
  {
    path: ROUTE_PATH.CLIENTADMINTOC,
    component: ClientAdminTOS,
    role: UserRole.admin,
    id: 'TextBlocks',
  },
  {
    path: ROUTE_PATH.LISTENRFAQTOC,
    component: ListenerTOS,
    role: UserRole.admin,
    id: 'TextBlocks',
  },
  {
    path: ROUTE_PATH.FILES,
    component: Files,
    role: UserRole.admin,
    id: 'Files',
  },
  {
    path: ROUTE_PATH.AUDIO_REVIEW,
    component: AudioReview,
    role: UserRole.admin,
    id: 'Review',
  },
  {
    path: ROUTE_PATH.VIDEO_REVIEW,
    component: VideoReview,
    role: UserRole.admin,
    id: 'Review',
  },
  {
    path: ROUTE_PATH.PROFILE,
    component: AvatarReview,
    role: UserRole.admin,
    id: 'Review',
  },
  {
    path: ROUTE_PATH.ABOUT_ME,
    component: AboutMeReview,
    role: UserRole.admin,
    id: 'Review',
  },
  {
    path: ROUTE_PATH.DISPLAY_NAME,
    component: DisplayNameReview,
    role: UserRole.admin,
    id: 'Review',
  },
  {
    path: ROUTE_PATH.BACKGROUND_TAGS,
    component: BackgroundTagApproval,
    role: UserRole.admin,
    id: 'Review',
  },
  {
    path: ROUTE_PATH.RESOURCES_DETAILS,
    component: ResourceDetails,
    id: 'Resources',
  },
  { path: ROUTE_PATH.CALLS_REPORT, component: CallReporting, id: 'Reports' },
  { path: ROUTE_PATH.ENGAGEMENTS_REPORT, component: EngagementReporting, id: 'Reports' },
  { path: ROUTE_PATH.CLIENT_ENGAGEMENTS_REPORT, component: ClientEngagementReporting, id: 'Reports' },
  { path: ROUTE_PATH.TOP_SEARCHES, component: TopSearches, id: 'TopSearches' },
];

export const AdminRouter = () => {
  return (
    <Switch>
      {/* here is how you can navigate someone to where they need to go  */}
      <Route exact path="/" render={() => <Redirect to={ROUTE_PATH.DASHBOARD} />} />
      <Route exact path={ROUTE_PATH.LOGIN} render={() => <Redirect to={ROUTE_PATH.DASHBOARD} />} />
      {/* the layout of the pages are inside the private route for some reason :( */}
      {adminRoutes.map((route) => (
        <PrivateRoute exact selectedId={route.id} component={route.component} path={route.path} />
      ))}
      {clientAdminRoutes.map((route) => (
        <PrivateRoute exact selectedId={route.id} path={route.path} component={route.component} />
      ))}
      {publicRoutes.map((route) => (
        <Route path={route.path} component={route.component} />
      ))}
      <Route exact path="*" render={() => <Redirect to={ROUTE_PATH.DASHBOARD} />} />
    </Switch>
  );
};
