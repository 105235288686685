import { useState, useEffect } from 'react';
import { Button } from '@kindlyhuman/component-library';
import Modal from '../../../components/common/modal';
import { PeerOptions } from '../../../hooks/usePeers';
import { useProfileTagGroups } from '../../../hooks/useProfileTagGroups';
import { useTiers } from '../../../hooks/useTiers';

const FilterModal: React.FC<{
  filters: PeerOptions;
  setFilters: React.Dispatch<React.SetStateAction<PeerOptions>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  peerType: string;
}> = ({ filters, setFilters, isOpen, setIsOpen, peerType }) => {
  const { data: tiers } = useTiers();
  const { getTagsByKey } = useProfileTagGroups();

  const [tempFilters, setTempFilters] = useState<PeerOptions>(filters);
  const initialFilterState: PeerOptions = {
    limit: 25,
    page: 1,
    search: '',
    userType: peerType === 'peer' ? ['onboardingpeer', 'peer'] : ['onboardinglistener', 'listener'],
    isPeerActive: ['true'],
    isTestUser: ['false'],
    tierId: [],
    ageGroup: [],
    ethnicityTags: [],
    genderTags: [],
    familyTags: [],
    languageTags: [],
    relationshipTags: [],
    spiritualityTags: [],
  };

  const roleList =
    peerType === 'peer'
      ? [
          { name: 'Peer', id: 'peer' },
          { name: 'Peer Applicant', id: 'onboardingpeer' },
        ]
      : [
          { name: 'Listener', id: 'peerlistener' },
          { name: 'Listener Applicant', id: 'onboardinglistener' },
        ];
  const tierList = tiers?.map((tier) => {
    return { name: tier.name, id: tier.id };
  });
  const ageGroupList = ['20s', '30s', '40s', '50s', '60s', '70s+'];
  const genderList = getTagsByKey('GENDER');
  const ethnicityList = getTagsByKey('RACE/ETHNICITY');
  const relationshipList = getTagsByKey('RELATIONSHIP');
  const familyList = getTagsByKey('FAMILY');
  const languageList = getTagsByKey('LANGUAGE');
  const spiritualityList = getTagsByKey('SPIRITUALITY');
  const peerVisibilityList = [
    { name: 'Visible', id: 'true' },
    { name: 'Hidden', id: 'false' },
  ];
  const testUserList = [
    { name: 'Test Users', id: 'true' },
    { name: 'Non-Test Users', id: 'false' },
  ];

  const updateTempFilters = (filterType: keyof PeerOptions, value: (string | number)[]) => {
    setTempFilters((prev) => ({
      ...prev,
      [filterType]: value,
      page: 1,
    }));
  };

  const handleMultiselectChange = (filterType: keyof PeerOptions, id: string | number) => {
    let updatedValues: Array<string | number>;

    const currentValues = (tempFilters[filterType] as Array<string | number>) || [];

    if (filterType === 'isTestUser') {
      // Make the options mutually exclusive
      updatedValues = [id];
    } else {
      if (currentValues.includes(id)) {
        updatedValues = currentValues.filter((item) => item !== id);
      } else {
        updatedValues = [...currentValues, id];
      }
    }

    updateTempFilters(filterType, updatedValues);
  };

  const applyFilters = () => {
    if (tempFilters.userType?.length === 0) {
      tempFilters.userType = peerType === 'peer' ? ['onboardingpeer', 'peer'] : ['onboardinglistener', 'peerlistener'];
    }
    setFilters(tempFilters);
  };

  useEffect(() => {
    if (isOpen) {
      setTempFilters(filters);
    }
  }, [filters, isOpen]);

  return (
    <Modal
      isModel={isOpen}
      InnerComponent={
        <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-100 outline-none focus:outline-none">
          <div className="relative my-6 mx-auto max-w-5xl min-w-3xl max-h-screen overflow-y-auto">
            <div className="bg-white rounded-2xl border border-black p-8 space-y-8">
              <div className="h-full border-b-4 border-black mb-6">
                <div className="text-left w-full flex pb-2 justify-between">
                  <div className="flex items-center">
                    <div className="pr-12 text-violet-950 text-3xl font-bold">Peer Filters</div>
                  </div>
                  <div className="flex space-x-4 items-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className="h-8 text-center bg-green hover:bg-green hover:bg-opacity-50 text-black text-base font-bold font-['Manrope'] leading-normal"
                      onClick={() => {
                        applyFilters();
                        setIsOpen(false);
                        localStorage.setItem('peerListingFilters', JSON.stringify(tempFilters));
                      }}
                    >
                      Apply
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      className="h-8 text-center bg-red hover:bg-red hover:bg-opacity-50 text-black text-base font-bold font-['Manrope'] leading-normal"
                      onClick={() => {
                        setTempFilters(initialFilterState);
                      }}
                    >
                      Clear All
                    </Button>
                    <button className="px-7 py-2 text-md" type="button" onClick={() => setIsOpen(false)}>
                      X
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex flex-col space-y-12">
                  <FilterSection title="Role/Status">
                    <FilterOptions
                      filterList={roleList}
                      queryParam={tempFilters.userType || []}
                      handleChange={(id) => handleMultiselectChange('userType', id)}
                    />
                  </FilterSection>
                  <FilterSection title="Visibility">
                    <FilterOptions
                      filterList={peerVisibilityList}
                      queryParam={tempFilters.isPeerActive || []}
                      handleChange={(id) => handleMultiselectChange('isPeerActive', id)}
                    />
                  </FilterSection>
                  <FilterSection title="Test User">
                    <FilterOptions
                      filterList={testUserList}
                      queryParam={
                        tempFilters.isTestUser && tempFilters.isTestUser.length ? tempFilters.isTestUser : ['false']
                      }
                      handleChange={(id) => handleMultiselectChange('isTestUser', id)}
                    />
                  </FilterSection>
                </div>
                <FilterSection title="Tier">
                  <FilterOptions
                    filterList={tierList}
                    queryParam={tempFilters.tierId || []}
                    handleChange={(id) => handleMultiselectChange('tierId', id)}
                  />
                </FilterSection>
                <FilterSection title="Gender">
                  <FilterOptions
                    filterList={genderList}
                    queryParam={tempFilters.genderTags || []}
                    handleChange={(id) => handleMultiselectChange('genderTags', id)}
                  />
                </FilterSection>
                <FilterSection title="Age">
                  <FilterOptions
                    filterList={ageGroupList}
                    queryParam={tempFilters.ageGroup || []}
                    handleChange={(id) => handleMultiselectChange('ageGroup', id)}
                  />
                </FilterSection>
              </div>
              <div className="flex justify-between">
                <FilterSection title="Ethnicity">
                  <FilterOptions
                    filterList={ethnicityList}
                    queryParam={tempFilters.ethnicityTags || []}
                    handleChange={(id) => handleMultiselectChange('ethnicityTags', id)}
                  />
                </FilterSection>
                <FilterSection title="Relationship">
                  <FilterOptions
                    filterList={relationshipList}
                    queryParam={tempFilters.relationshipTags || []}
                    handleChange={(id) => handleMultiselectChange('relationshipTags', id)}
                  />
                </FilterSection>
                <FilterSection title="Family">
                  <FilterOptions
                    filterList={familyList}
                    queryParam={tempFilters.familyTags || []}
                    handleChange={(id) => handleMultiselectChange('familyTags', id)}
                  />
                </FilterSection>
                <FilterSection title="Language">
                  <FilterOptions
                    filterList={languageList}
                    queryParam={tempFilters.languageTags || []}
                    handleChange={(id) => handleMultiselectChange('languageTags', id)}
                  />
                </FilterSection>
              </div>
              <div className="flex justify-between">
                <FilterSection title="Spirituality">
                  <FilterOptions
                    filterList={spiritualityList}
                    queryParam={tempFilters.spiritualityTags || []}
                    handleChange={(id) => handleMultiselectChange('spiritualityTags', id)}
                  />
                </FilterSection>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

const FilterSection: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }): JSX.Element => {
  return (
    <div className="flex flex-col w-60 mx-4">
      <div className="w-40 pb-2 border-b-2 border-grey mb-2 text-left text-black text-xl font-bold font-['Montserrat']">
        {title}
      </div>
      {children}
    </div>
  );
};

const FilterOptions: React.FC<{
  filterList?: string[] | { name: string; id: number | string }[];
  queryParam?: Array<string | number>;
  handleChange: (id: string | number) => void;
}> = ({ filterList, queryParam, handleChange }) => (
  <div className="flex flex-col space-y-3">
    {filterList?.map((item) => {
      const label = typeof item === 'string' ? item : item.name;
      const value = typeof item === 'string' ? item : item.id;

      return (
        <div key={value} className="flex items-center">
          <input
            type="checkbox"
            id={label}
            name={label}
            value={value}
            checked={queryParam?.includes(value)}
            onChange={() => handleChange(value)}
            className="rounded-full border-gray-300 checked:bg-violet-950 checked:bg-opacity-10"
          />
          <label htmlFor={label} className="ml-2 text-left text-black text-xs font-bold font-['Montserrat']">
            {label}
          </label>
        </div>
      );
    })}
  </div>
);

export default FilterModal;
