import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useRef, useState } from 'react';
import { KindlyHumanTextBlue } from '../../../assets/images';
import { Spinner } from '../../../components/common';
import { useClientSummaryList } from '../../../hooks/useClients';
import {
  ClientEngagementReportData,
  useClientEngagementReport,
  useClientEngagementReportDownload,
} from '../../../hooks/useEngagementReporting';
import { EngagementReportFilters } from '../common/reports-constants';
import { toast } from 'react-toastify';

interface ClientEngagementReportPDFProps {
  filters: EngagementReportFilters;
}

export const ClientEngagementReportPDF: React.FC<ClientEngagementReportPDFProps> = ({ filters }) => {
  const { data, isFetching: isFetchingReport } = useClientEngagementReport(filters);
  const { data: clients } = useClientSummaryList({ show_all_clients: true });
  const { isFetching: isFetchingCsv, refetch: refetchCsv } = useClientEngagementReportDownload(filters, false);
  const clientName = clients?.find((client) => client.id === filters.clientId)?.name;
  const reportsRef = useRef<(HTMLDivElement | null)[]>([]);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const handleCsvDownload = () => {
    if (filters.startDate !== '' && filters.endDate !== '') {
      refetchCsv();
    }
  };

  const generatePDF = async () => {
    if (!data || !reportsRef.current.length) return;

    setIsGeneratingPDF(true);

    try {
      const pdf = new jsPDF('p', 'pt', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const margin = 40;

      for (let i = 0; i < reportsRef.current.length; i++) {
        const report = reportsRef.current[i];
        if (!report) continue;

        const canvas = await html2canvas(report, {
          scale: 2,
        });

        const imgWidth = pageWidth - margin * 2;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const imgData = canvas.toDataURL('image/jpeg', 1.0);

        if (i > 0) {
          pdf.addPage();
        }

        pdf.addImage(imgData, 'JPEG', margin, margin, imgWidth, imgHeight);
      }

      const filename = `${clientName}_client_report_${filters.startDate}_to_${filters.endDate}.pdf`;

      pdf.save(filename);
      toast.success(`${clientName} report downloaded successfully.`);
    } catch (error) {
      toast.error('Error generating PDF');
    } finally {
      setIsGeneratingPDF(false);
    }
  };

  return (
    <>
      <div className="flex gap-x-2">
        <button
          onClick={generatePDF}
          disabled={isGeneratingPDF || isFetchingReport}
          className="bg-blue-primary text-white text-sm px-4 py-1 rounded-full whitespace-nowrap"
        >
          {isGeneratingPDF ? 'Generating PDF...' : 'Download PDF'}
        </button>
        <button
          className="bg-blue-primary text-white text-sm px-4 py-1 rounded-full whitespace-nowrap"
          onClick={handleCsvDownload}
          disabled={isFetchingCsv}
        >
          Download CSV
        </button>
      </div>
      {isFetchingReport && <Spinner />}
      <div className="space-y-12">
        {data &&
          Object.entries(data.data).map(([planName, planData], index) => (
            <div key={planName} ref={(el) => (reportsRef.current[index] = el)}>
              <PlanReport
                planName={planName}
                planData={planData}
                clientName={clientName}
                dateRange={{
                  startDate: filters.startDate,
                  endDate: filters.endDate,
                }}
                index={index}
              />
            </div>
          ))}
      </div>
    </>
  );
};

interface PlanReportProps {
  planName: string;
  planData: ClientEngagementReportData;
  clientName?: string;
  dateRange: {
    startDate: string;
    endDate: string;
  };
  index: number;
}

export const PlanReport: React.FC<PlanReportProps> = ({ planName, planData, clientName, dateRange, index }) => {
  const formatDate = (date: string) => {
    const dateObj = new Date(date + 'T00:00:00');
    return dateObj.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
  };

  // CLJ: Do we want to break these out into separate components?
  return (
    <div className="py-8 max-w-4xl min-w-4xl space-y-8">
      <div className="mb-6 flex gap-2 text-xl font-semibold items-center justify-between">
        <img src={KindlyHumanTextBlue} alt="Kindly Human Logo" className="h-6" />
        <p className="">{clientName}</p>
        <p className="">{formatDate(dateRange.startDate)}</p>
      </div>

      <div className="text-2xl font-semibold blue-primary mb-2">{planName}</div>
      <div className="text-lg font-semibold blue-primary mb-2 pb-1">Overview</div>
      <div className="grid grid-cols-4 gap-2">
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">Total Eligible Members</div>
          <div className="text-sm h-16">From eligibility file</div>
          <div className="text-lg font-semibold blue-primary">{planData.total_eligible_users}</div>
        </div>
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">Total Active Members</div>
          <div className="text-sm h-16">Members who have activated their account via onboarding</div>
          <div className="text-lg font-semibold blue-primary">{planData.total_active_users}</div>
          <div className="text-sm">
            <span className="font-semibold blue-primary">
              {(planData.total_eligible_users === 0
                ? 0
                : (planData.total_active_users / planData.total_eligible_users) * 100
              ).toFixed(1)}
              %
            </span>{' '}
            of Eligible Members
          </div>
        </div>
      </div>

      <div className="text-lg font-semibold blue-primary mb-2 pb-1">Peer Experience Engagement</div>
      <div className="text-md font-semibold blue-primary mb-2 pb-1">Audio</div>
      <div className="grid grid-cols-4 gap-2">
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">Total Audio Plays</div>
          <div className="text-sm h-16">Number of audio experience plays</div>
          <div className="text-lg font-semibold blue-primary">{planData.audio_play}</div>
        </div>
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">By Unique Members</div>
          <div className="text-sm h-16">Unique members playing audio experiences</div>
          <div className="text-lg font-semibold blue-primary">{planData.audio_users}</div>
        </div>
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">Avg. Plays per Member</div>
          <div className="text-sm h-16">Average number of plays per member</div>
          <div className="text-lg font-semibold blue-primary">{planData.average_audio_plays_by_user}</div>
        </div>
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">Total Play Time</div>
          <div className="text-sm h-16">Total time spent listening to audio experiences</div>
          <div className="">
            <span className="text-lg font-semibold blue-primary">{(planData.audio_total_time / 60).toFixed(1)}</span>
            <span className="text-sm"> minutes</span>
          </div>
        </div>
      </div>

      <div className="text-md font-semibold blue-primary mb-2 pb-1">Video</div>
      <div className="grid grid-cols-4 gap-2">
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">Total Video Plays</div>
          <div className="text-sm h-16">Number of video experience plays</div>
          <div className="text-lg font-semibold blue-primary">{planData.video_play}</div>
        </div>
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">By Unique Members</div>
          <div className="text-sm h-16">Unique members playing video experiences</div>
          <div className="text-lg font-semibold blue-primary">{planData.video_users}</div>
        </div>
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">Avg. Plays per Member</div>
          <div className="text-sm h-16">Average number of plays per member</div>
          <div className="text-lg font-semibold blue-primary">{planData.average_video_plays_by_user}</div>
        </div>
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">Total Play Time</div>
          <div className="text-sm h-16">Total time spent listening to video experiences</div>
          <div className="">
            <span className="text-lg font-semibold blue-primary">{(planData.video_total_time / 60).toFixed(1)}</span>
            <span className="text-sm"> minutes</span>
          </div>
        </div>
      </div>

      <div className="text-lg font-semibold blue-primary mb-2 pb-1">Support Engagement</div>
      <div className="grid grid-cols-4 gap-2">
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">Total Presentations</div>
          <div className="text-sm h-16">Number of external support views</div>
          <div className="text-lg font-semibold blue-primary">
            {planData.client_resource_view + planData.global_resource_view + planData.provider_view}
          </div>
        </div>
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">+Care</div>
          <div className="text-sm h-16">Number of +Care provider views</div>
          <div className="text-lg font-semibold blue-primary">{planData.provider_view}</div>
        </div>
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">Program</div>
          <div className="text-sm h-16">Number of Program views</div>
          <div className="text-lg font-semibold blue-primary">{planData.client_resource_view}</div>
        </div>
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">Resource Presentations</div>
          <div className="text-sm h-16">Number of Resource views</div>
          <div className="text-lg font-semibold blue-primary">{planData.global_resource_view}</div>
        </div>
      </div>

      <div className="text-lg font-semibold blue-primary mb-2 pb-1">Peer Listener Calls</div>
      <div className="grid grid-cols-4 gap-2">
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">Total Calls</div>
          <div className="text-sm h-16">Number of calls made by members</div>
          <div className="text-lg font-semibold blue-primary">{planData.calls_completed}</div>
        </div>
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">By Unique Members</div>
          <div className="text-sm h-16">Unique Members making calls</div>
          <div className="text-lg font-semibold blue-primary">{planData.call_users}</div>
          <div className="text-sm">
            <span className="font-semibold blue-primary">
              {(planData.total_eligible_users === 0
                ? 0
                : (planData.call_users / planData.total_eligible_users) * 100
              ).toFixed(1)}
              %
            </span>{' '}
            of Eligible Members
          </div>
        </div>
        <div className="col-span-1">
          <div className="text-md font-semibold whitespace-nowrap">Avg. Calls per Member</div>
          <div className="text-sm h-16">Average number of calls per member</div>
          <div className="text-lg font-semibold blue-primary">{planData.average_calls_by_user}</div>
        </div>
        <div className="col-span-1 ">
          <div className="text-md font-semibold whitespace-nowrap">Avg. Call Duration</div>
          <div className="text-sm h-16">Average # of minutes per call</div>
          <div className="">
            <span className="text-lg font-semibold blue-primary">
              {(planData.total_call_time / 60 / planData.calls_completed || 0).toFixed(1)}
            </span>
            <span className="text-sm"> minutes</span>
          </div>
          <div className="text-sm">
            <span className=" font-semibold blue-primary">
              {((planData.total_call_time / 60 / planData.calls_completed || 0) / 60).toFixed(1)}
            </span>{' '}
            Hours of total call time
          </div>
        </div>
      </div>
    </div>
  );
};
