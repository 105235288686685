import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';

export interface ListenerRoleResponse {
  count: number;
  data: ListenerRole[];
}

export interface ListenerRole {
  about_me: string;
  about_me_rejected: boolean;
  age_group: string;
  background_tags: BackgroundTag[];
  calls_succeeded: number;
  connection_requests: number;
  group_name: string | null;
  can_take_calls: boolean;
  connection_percent: number;
  credentials_region: string;
  credentials_type: any;
  credentials_value: string;
  current_profile_photo: ProfilePhoto;
  earnings: number;
  num_experiences: number;
  num_playbacks: number;
  num_followers: number;
  id: number;
  impact_score: number;
  is_active: boolean;
  is_peer_active: boolean;
  listener_grade: string;
  listener_tags: [string, number][];
  notes: string;
  onboarding_completed: string;
  premier_listener: boolean;
  profile_photo_approved: boolean;
  profile_photo_file_url: string;
  profile_photo_original_file_path: string;
  profile_photo_process_configuration: ProfilePhotoProcessConfiguration;
  proposed_about_me: string;
  state: ListenerState;
  tier_advancement: number;
  tier_id: number;
  user: User;
  verification_type: any;
  verified_listener: boolean;
}

export type ListenerState = 'onboarding_peer' | 'rejected_peer' | 'active_peer' | 'upgrading_peer' | 'active_listener';
export const PeerType = {
  ONBOARDING_PEER: 'onboarding_peer',
  REJECTED_PEER: 'rejected_peer',
  ACTIVE_PEER: 'active_peer',
  UPGRADING_PEER: 'upgrading_peer',
  ACTIVE_LISTENER: 'active_listener',
};

export type TagStatus = 'APPROVED' | 'REJECTED' | 'UNGRADED';

export interface BackgroundTag {
  id: number;
  tag_id: number;
  created_at: Date;
  status: TagStatus;
  media: Media[];
  tag: Tag;
}

export interface Media {
  file_key: string;
  file_url: string;
  file_name?: string;
}

export interface Tag {
  id: number;
  name: string;
}

export interface ProfilePhoto {
  id: number;
  status: string;
  file_url: string;
  original_file_path: string;
  process_configuration: ProfilePhotoProcessConfiguration;
  square_url: string;
}

export interface ProfilePhotoProcessConfiguration {
  offset: any;
  rotation: any;
}

export interface User {
  created_at: string;
  date_of_birth: string;
  display_name: string;
  display_name_rejected: boolean;
  email_address: string;
  first_name: string;
  id: number;
  last_name: string;
  proposed_display_name: string;
  test_user: boolean;
}

export interface PeerOptions {
  aboutMeStatus?: string;
  activePeers?: boolean;
  ageGroup?: string[];
  backgroundTagApproved?: boolean;
  displayNameStatus?: string;
  ethnicityTags?: number[];
  familyTags?: number[];
  genderTags?: number[];
  hasBackgroundTags?: boolean;
  hideIncompletePeers?: boolean;
  isPeerActive?: string[];
  isTestUser?: string[];
  languageTags?: number[];
  limit?: number;
  orderBy?: string;
  page?: number;
  profilePhotoApproved?: boolean;
  relationshipTags?: number[];
  search?: string;
  spiritualityTags?: number[];
  tierId?: number[];
  userType?: string[];
}

const buildQueryParams = (peerOptions: PeerOptions): any => {
  let params: any = {};

  const mapOptionToParam = {
    about_me_status: peerOptions.aboutMeStatus ? peerOptions.aboutMeStatus : undefined,
    age_group: peerOptions.ageGroup?.length ? peerOptions.ageGroup.join(',') : undefined,
    active_peers:
      peerOptions.isPeerActive?.length === 1 &&
      (peerOptions.isPeerActive[0] === 'true' || peerOptions.isPeerActive[0] === 'false')
        ? peerOptions.isPeerActive[0]
        : undefined,
    display_name_status: peerOptions.displayNameStatus ? peerOptions.displayNameStatus : undefined,
    ethnicity_tags: peerOptions.ethnicityTags?.length ? peerOptions.ethnicityTags.join(',') : undefined,
    family_tags: peerOptions.familyTags?.length ? peerOptions.familyTags.join(',') : undefined,
    gender_tags: peerOptions.genderTags?.length ? peerOptions.genderTags.join(',') : undefined,
    has_background_tags: peerOptions.hasBackgroundTags ? peerOptions.hasBackgroundTags : undefined,
    hide_incomplete_peers:
      peerOptions.hideIncompletePeers === true ? true : peerOptions.hideIncompletePeers === false ? false : undefined,
    is_background_tag_approved:
      peerOptions.backgroundTagApproved === true
        ? true
        : peerOptions.backgroundTagApproved === false
          ? false
          : undefined,
    is_test_user:
      peerOptions.isTestUser?.length === 1 && peerOptions.isTestUser[0] === 'true'
        ? peerOptions.isTestUser[0]
        : 'false',
    language_tags: peerOptions.languageTags?.length ? peerOptions.languageTags.join(',') : undefined,
    limit: peerOptions.limit,
    order_by: peerOptions.orderBy
      ? peerOptions.orderBy
      : // if hasBackgroundTags option is set, we can't select an orderBy
        peerOptions.hasBackgroundTags !== undefined
        ? undefined
        : 'created_at',
    page: peerOptions.page,
    profile_photo_approved:
      peerOptions.profilePhotoApproved === true ? true : peerOptions.profilePhotoApproved === false ? false : undefined,
    relationship_tags: peerOptions.relationshipTags?.length ? peerOptions.relationshipTags.join(',') : undefined,
    search: peerOptions.search,
    spirituality_tags: peerOptions.spiritualityTags?.length ? peerOptions.spiritualityTags.join(',') : undefined,
    tier_id: peerOptions.tierId?.length ? peerOptions.tierId.join(',') : undefined,
    user_type: peerOptions.userType?.length ? peerOptions.userType.join(',') : undefined,
  };

  Object.entries(mapOptionToParam).forEach(([key, value]) => {
    if (value !== undefined && value !== '') {
      params[key] = value;
    }
  });

  return params;
};

const getPeers = async (peerOptions: PeerOptions): Promise<ListenerRoleResponse> => {
  const params = buildQueryParams(peerOptions);

  return await axiosGet(
    '/listeners/',
    {
      ...params,
    },
    'v3',
  ).then((listenerRoleResponse) => listenerRoleResponse.data);
};

export const usePeers = (peerOptions: PeerOptions) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<ListenerRoleResponse>(
    ['peers', ...Object.values(peerOptions)],
    () => getPeers(peerOptions),
  );
  return { data, isLoading, error, refetch, isFetching };
};

const getListenerProfile = async (listenerId: number): Promise<ListenerRole[]> => {
  return await axiosGet(`/listeners/${listenerId}/profile`, {}, 'v2').then((listenerRole) => listenerRole.data);
};

export const useListenerProfile = (listenerId: number) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<ListenerRole[]>(
    ['listenerProfile', listenerId],
    () => getListenerProfile(listenerId),
  );

  const listenerProfile = data ? data[0] : undefined;

  return { data: listenerProfile, isLoading, error, refetch, isFetching };
};
