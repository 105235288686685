import React from 'react';
import useAuth from '../../hooks/useAuth';
import { SBLogo } from '../../assets/images';
import { Button } from '@kindlyhuman/component-library';

const NotifyMessage: React.FunctionComponent = (): JSX.Element => {
  const webAppUrl = process.env.REACT_APP_MWA_LINK;
  const listenerUrl = process.env.REACT_APP_LISTENER_LINK;
  const { logout, user } = useAuth();

  const isListener = user?.listener_role && !user?.caller_role;

  return (
    <div>
      <div className="flex h-screen bg-gray-100">
        <div className="w-full max-w-xl m-auto bg-white  py-10 px-5">
          <div className="flex justify-center">
            <img className="=w-3/4" src={SBLogo} alt="login" />
          </div>
          <div className="w-full flex flex-col text-center mt-6 gap-y-6">
            <p>Your account does not have access.</p>
            <p>It appears you are a {isListener ? 'listener' : 'member'} trying to log in as an admin.</p>
            <p>
              Please click the button below to be redirected to our {isListener ? 'peer web app' : 'member web app'}.
            </p>
            <a href={isListener ? listenerUrl : webAppUrl}>
              <Button data-testid="redirect-button" variant="primary">
                {isListener ? 'Peer Web App' : 'Member Web App'}
              </Button>
            </a>
            <div className="flex flex-col justify-center items-center gap-1 text-sm">
              <Button
                variant="secondary"
                data-testid="logout-button"
                className="flex items-center font-bold leading-tight"
                onClick={logout}
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotifyMessage;
