import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosDelete, axiosGet, axiosPost, axiosPut } from '../api/axios';
import { toast } from 'react-toastify';

export interface SearchQuery {
  id: number;
  query: string;
  created_at: string;
  score_results?: number[];
  client: Client;
}

export interface Client {
  name: string;
}

export interface TopSearch {
  id: number;
  client_ids: number[];
  query_text: string;
  created_at: string;
}

export interface TopSearchResponse {
  count: number;
  data: TopSearch[];
}

export const fetchSearchQueries = async (testUsers: boolean = false): Promise<SearchQuery[]> => {
  const searchParams = testUsers ? { is_test_user: 'true' } : {};
  const { data } = await axiosGet('/search', searchParams, 'v3');
  return data;
};

export const useSearchQueries = (testUsers: boolean = false) => {
  return useQuery<SearchQuery[]>(['searchQueries', testUsers], () => fetchSearchQueries(testUsers));
};

const getTopSearches = async (): Promise<TopSearchResponse> => {
  return await axiosGet('/search/top_searches', {}, 'v3').then((response) => response.data);
};

export const useTopSearches = () => {
  return useQuery<TopSearchResponse>(['topSearches'], () => getTopSearches());
};

const postTopSearchRequest = async (clientIds: number[], queryText: string): Promise<TopSearch> => {
  return await axiosPost('/search/top_searches', { client_ids: clientIds, query_text: queryText }, 'v3').then(
    (response) => response.data,
  );
};

interface CreateTopSearchPayload {
  query_text: string;
  client_ids: number[];
}

export const usePostTopSearch = () => {
  const queryClient = useQueryClient();
  return useMutation<TopSearch, Error, CreateTopSearchPayload>(
    ['postTopSearch'],
    (payload) => postTopSearchRequest(payload.client_ids, payload.query_text),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['topSearches']);
      },
      onError: (error: any) => {
        const errorMessage = error.response?.data?.message || 'Unknown error';
        toast.error(errorMessage);
      },
    },
  );
};

const deleteTopSearchRequest = async (id: number): Promise<void> => {
  return await axiosDelete(`/search/top_searches/${id}`, {}, 'v3').then((response) => response.data);
};

export const useDeleteTopSearch = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, number>(['deleteTopSearch'], (id) => deleteTopSearchRequest(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['topSearches']);
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message || 'Unkown error';
      toast.error(errorMessage);
    },
  });
};

const updateTopSearchRequest = async (id: number, clientIds: number[], queryText: string): Promise<TopSearch> => {
  return await axiosPut(`/search/top_searches/${id}`, { client_ids: clientIds, query_text: queryText }, 'v3').then(
    (response) => response.data,
  );
};

interface UpdateTopSearchPayload {
  id: number;
  query_text: string;
  client_ids: number[];
}

export const useUpdateTopSearch = () => {
  const queryClient = useQueryClient();
  return useMutation<TopSearch, Error, UpdateTopSearchPayload>(
    ['updateTopSearch'],
    (payload) => updateTopSearchRequest(payload.id, payload.client_ids, payload.query_text),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['topSearches']);
      },
      onError: (error: any) => {
        const errorMessage = error.response?.data?.message || 'Unknown error';
        toast.error(errorMessage);
      },
    },
  );
};
