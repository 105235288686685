import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';
import { toast } from 'react-toastify';

export interface EngagementSummaryReportResponse {
  count: number;
  data: EngagementSummaryReport[];
}

export interface EngagementDetailsReportResponse {
  count: number;
  data: EngagementDetail[];
}

export interface EngagementSummaryReport {
  date?: string;
  audio_play: number;
  calls_completed: number;
  client_resource_engagement: number;
  client_resource_impression: number;
  client_resource_view: number;
  engagements: number;
  global_resource_engagement: number;
  global_resource_impression: number;
  global_resource_view: number;
  provider_engagement: number;
  provider_impression: number;
  provider_view: number;
  video_play: number;
}

export type EngagementEvent =
  | 'call_completed'
  | 'client_resource_view'
  | 'client_resource_impression'
  | 'global_resource_view'
  | 'global_resource_impression'
  | 'provider_view'
  | 'provider_impression'
  | 'video_play'
  | 'audio_play';

export interface EngagementDetail {
  call_id?: number;
  caller_role_id: number;
  client_id: number;
  client_name: string;
  created_at: string;
  engagement_name: string;
  engagement_type: EngagementEvent;
  id: number;
  listener_role_id: number;
  listener_user_id: number;
  listener_name: string;
  listener_topic_tags: string[];
  member_name: string;
  meta_data: MetaData;
  provider_name: string;
}

export interface MetaData {
  experience_id?: number;
  playback_location?: string;
  resource_name?: string;
  call_duration?: number;
  call_id?: number;
  call_type?: string;
  listener_name?: string;
  listener_role_user_id?: number;
  listener_topic_tag_ids?: number[];
}

export interface ClientEngagementReport {
  count: number;
  data: {
    [planName: string]: ClientEngagementReportData;
  };
}

export interface ClientEngagementReportData {
  audio_play: number;
  audio_total_time: number;
  audio_users: number;
  average_audio_plays_by_user: number;
  average_calls_by_user: number;
  average_video_plays_by_user: number;
  call_users: number;
  calls_completed: number;
  client_resource_engagement: number;
  client_resource_impression: number;
  client_resource_view: number;
  external_support: number;
  external_support_users: number;
  global_resource_engagement: number;
  global_resource_impression: number;
  global_resource_view: number;
  provider_engagement: number;
  provider_impression: number;
  provider_view: number;
  total_active_users: number;
  total_call_time: number;
  total_eligible_users: number;
  total_engaged_users: number;
  video_play: number;
  video_total_time: number;
  video_users: number;
}

interface EngagementReportRequestFilters {
  startDate: string;
  endDate: string;
  clientId?: number;
  testData?: boolean;
  timezone?: string;
}

interface QueryParams {
  start_date: string;
  end_date: string;
  output_format: string;
  client_id?: number;
  include_test_users?: boolean;
  timezone?: string;
}

const getEngagementReport = async (filters: EngagementReportRequestFilters) => {
  const queryParams: QueryParams = {
    start_date: filters.startDate,
    end_date: filters.endDate,
    output_format: 'json',
  };

  if (filters.clientId !== undefined) {
    queryParams.client_id = filters.clientId;
  }

  if (filters.testData === true) {
    queryParams.include_test_users = filters.testData;
  }

  const response = await axiosGet('/reporting/engagement_events', queryParams, 'v3');
  return response.data;
};

export const useEngagementReport = (filters: EngagementReportRequestFilters) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery(
    ['reporting', 'engagementReportJson', filters],
    () => getEngagementReport(filters),
    {
      refetchOnWindowFocus: false,
    },
  );
  return { data, isLoading, error, refetch, isFetching };
};

const getEngagementSummaryReport = async (
  filters: EngagementReportRequestFilters,
): Promise<EngagementSummaryReportResponse> => {
  const queryParams: QueryParams = {
    start_date: filters.startDate,
    end_date: filters.endDate,
    output_format: 'json',
  };

  if (filters.clientId !== undefined) {
    queryParams.client_id = filters.clientId;
  }

  if (filters.testData === true) {
    queryParams.include_test_users = filters.testData;
  }

  const response = await axiosGet('/reporting/engagement_summary', queryParams, 'v3');
  return response.data;
};

export const useEngagementSummaryReport = (filters: EngagementReportRequestFilters) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<EngagementSummaryReportResponse>(
    ['reporting', 'engagementSummaryReportJson', filters],
    () => getEngagementSummaryReport(filters),
    {
      refetchOnWindowFocus: false,
    },
  );
  return { data, isLoading, error, refetch, isFetching };
};

const getClientEngagementReport = async (filters: EngagementReportRequestFilters): Promise<ClientEngagementReport> => {
  const queryParams: QueryParams = {
    start_date: filters.startDate,
    end_date: filters.endDate,
    output_format: 'json',
    timezone: filters.timezone,
  };

  if (filters.clientId !== undefined) {
    queryParams.client_id = filters.clientId;
  }

  if (filters.testData === true) {
    queryParams.include_test_users = filters.testData;
  }

  const response = await axiosGet('/reporting/client_engagement_report', queryParams, 'v3');
  return response.data;
};

export const useClientEngagementReport = (filters: EngagementReportRequestFilters) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<ClientEngagementReport>(
    ['reporting', 'clientEngagementReportJson', filters],
    () => getClientEngagementReport(filters),
    {
      enabled: filters.clientId !== undefined,
    },
  );
  return { data, isLoading, error, refetch, isFetching };
};

// CLJ: Do we want to use separate hooks for each report, or stick with this hook factory?
const getEngagementReportDownload = async (
  endpoint: string,
  filters: EngagementReportRequestFilters,
): Promise<{ blob: Blob; filename: string }> => {
  const queryParams: QueryParams = {
    start_date: filters.startDate,
    end_date: filters.endDate,
    output_format: 'csv',
  };

  if (filters.clientId !== undefined) {
    queryParams.client_id = filters.clientId;
  }

  if (filters.testData === true) {
    queryParams.include_test_users = filters.testData;
  }

  const response = await axiosGet(endpoint, queryParams, 'v3');
  const contentDisposition = response.headers['content-disposition'];
  const filenameMatch = contentDisposition?.match(/filename=(.*\.csv)/);
  const filename = filenameMatch![1];

  return {
    blob: new Blob([response.data], { type: 'text/csv' }),
    filename,
  };
};

const createEngagementReportDownloadHook = (endpoint: string, queryKey: string, successMessage: string) => {
  return (filters: EngagementReportRequestFilters, enabled: boolean) => {
    return useQuery<{ blob: Blob; filename: string }>(
      ['reporting', queryKey, filters],
      () => getEngagementReportDownload(endpoint, filters),
      {
        enabled,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          const downloadLink = document.createElement('a');
          const url = URL.createObjectURL(data.blob);
          downloadLink.href = url;
          downloadLink.download = data.filename;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(url);
          toast.success(successMessage);
        },
      },
    );
  };
};

export const useEngagementReportDownload = createEngagementReportDownloadHook(
  '/reporting/engagement_events',
  'engagementReportCsv',
  'Engagement report downloaded successfully.',
);

export const useEngagementSummaryReportDownload = createEngagementReportDownloadHook(
  '/reporting/engagement_summary',
  'engagementSummaryReportCsv',
  'Engagement summary report downloaded successfully.',
);

export const useClientEngagementReportDownload = createEngagementReportDownloadHook(
  '/reporting/client_engagement_report',
  'clientEngagementReportCsv',
  'Client engagement report downloaded successfully.',
);
