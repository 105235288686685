import { getStoredFilters, HeaderOptions, updateStoredFilters } from '../common/reports-constants';
import Header from '../../../components/common/global-top-bar';
import SwitchBar from '../../../components/switch-bar';
import { useState } from 'react';
import { EngagementReportFilters } from '../common/reports-constants';
import { Toggle } from '@kindlyhuman/component-library';
import { useClientSummaryList } from '../../../hooks/useClients';
import { ClientEngagementReportPDF } from './client-engagement-report';

const ClientEngagementReporting: React.FC = () => {
  const today = new Date();
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const [filters, setFilters] = useState<EngagementReportFilters>(() => {
    const defaultFilters: EngagementReportFilters = {
      startDate: firstDayOfCurrentMonth.toISOString().split('T')[0],
      endDate: lastDayOfCurrentMonth.toISOString().split('T')[0],
      clientId: undefined,
      testData: false,
      timezone: userTimezone,
    };
    return getStoredFilters(defaultFilters, 'clientEngagementFilters');
  });

  return (
    <div>
      <Header heading={'Client Engagement Reporting'} />
      <SwitchBar heading={HeaderOptions} position={2} />
      <Filters filters={filters} setFilters={setFilters} />
      <div>
        <div className="max-window-height-connections overflow-y-auto px-7 pt-7 font-['Montserrat']">
          <ClientEngagementReportPDF filters={filters} />
        </div>
      </div>
    </div>
  );
};

interface FilterProps {
  filters: EngagementReportFilters;
  setFilters: (filters: EngagementReportFilters) => void;
}

const Filters: React.FC<FilterProps> = ({ filters, setFilters }) => {
  const { data: clients } = useClientSummaryList({ show_all_clients: true });

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const [year, month] = value.split('-').map((num) => parseInt(num));
    const startDate = new Date(year, month - 1, 1).toISOString().split('T')[0];
    const endDate = new Date(year, month, 0).toISOString().split('T')[0];

    setFilters({
      ...filters,
      startDate,
      endDate,
    });
    updateStoredFilters('startDate', startDate, 'clientEngagementFilters');
    updateStoredFilters('endDate', endDate, 'clientEngagementFilters');
  };

  return (
    <div className="w-full border-b gray-border-line flex items-center bg-gray-background-light px-4 h-10 space-x-2">
      <div className="flex items-center py-4 gray-background-dark"></div>
      <div className="flex items-center space-x-2">
        <label className="whitespace-nowrap text-sm">Month:</label>
        <input
          type="month"
          name="month"
          value={filters.startDate.slice(0, 7)}
          onChange={handleDateChange}
          className="p-0.5 w-32 border rounded text-xs"
        />
        <label className="flex items-center whitespace-nowrap text-sm">Client:</label>
        <select
          className="w-full bg-white text-sm px-2"
          value={filters.clientId}
          onChange={(e) => {
            if (e.target.value === '') {
              return;
            }
            setFilters({ ...filters, clientId: parseInt(e.target.value) });
          }}
        >
          <option value="">Select Client</option>
          {clients
            ?.filter((client) => client.packages.length > 0)
            .map((client) => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
        </select>
      </div>
      <div className="flex gap-x-2 text-sm items-center">
        <div className="whitespace-nowrap">Include Test Users</div>
        <Toggle
          checked={filters.testData}
          onChange={() => {
            setFilters({ ...filters, testData: !filters.testData });
          }}
        />
      </div>
    </div>
  );
};

export default ClientEngagementReporting;
