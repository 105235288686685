import { useState } from 'react';
import Modal from '../../components/common/modal';
import { Tag } from '../../hooks/useTags';

interface TagPillProps {
  tag: Tag;
  checked: boolean;
  setCheckedTags: React.Dispatch<React.SetStateAction<number[]>>;
}

const TagPill: React.FC<TagPillProps> = ({ tag, checked, setCheckedTags }) => {
  const handleClick = (id: number, isSelecting: boolean) => {
    if (isSelecting) {
      setCheckedTags((prev) => [...prev, id]);
    } else {
      setCheckedTags((prev) => prev.filter((x) => x !== id));
    }
  };
  return (
    <button
      key={tag.id}
      className={` ${
        checked ? ' bg-blue-primary  text-white' : ' bg-gray-background-light '
      }  rounded-md text-sm font-bold ml-2 mt-2`}
      onClick={() => {
        handleClick(tag.id, !checked);
      }}
    >
      <div className="flex px-3 py-2">
        <div className="grow  pr-2">{tag.name}</div>
      </div>
    </button>
  );
};

interface ResourceTagModalProps {
  tags: Tag[];
  selectedTagIds?: number[];
  onChange: (selectedTagIds: number[]) => void;
  onExit: () => void;
}

export const ResourceTagsEditModal: React.FC<ResourceTagModalProps> = ({ onChange, onExit, tags, selectedTagIds }) => {
  const [checkedTagIds, setCheckedTagIds] = useState<number[]>(selectedTagIds || []);

  return (
    <>
      <Modal
        isModel={true}
        InnerComponent={
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="py-16 inline-block align-bottom bg-white overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex justify-start">
                <h3 className="text-2xl font-normal blue-primary py-1">Add Resource Tags</h3>
              </div>
            </div>
            {tags.map((tag, _) => (
              <TagPill
                key={tag.id}
                tag={tag}
                checked={!!checkedTagIds?.includes(tag.id)}
                setCheckedTags={setCheckedTagIds}
              />
            ))}
            <div className="px-4 py-3 gap-2 flex justify-end items-center">
              <button
                type="button"
                className="text-center h-8 text-sm text-white px-2 bg-blue-primary w-16 rounded-sm ml-4 "
                onClick={() => onChange(checkedTagIds)}
              >
                Save
              </button>
              <button
                type="button"
                className="w-16 h-8 inline-flex items-center rounded-sm px-2 border text-center text-sm bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => onExit()}
              >
                Close
              </button>
            </div>
          </div>
        }
      />
    </>
  );
};
