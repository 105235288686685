import { useState } from 'react';
import { SearchQuery, useSearchQueries } from '../../hooks/useSearch';
import moment from 'moment';
import { Paginator } from '../../components/common/paginator';
import { Toggle } from '@kindlyhuman/component-library';

export const AllSearches = () => {
  const [testUsers, setTestUsers] = useState(false);
  const { data: searchQueries } = useSearchQueries(testUsers);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 100;

  if (!searchQueries) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <h3 className="text-2xl font-semibold mx-8 mt-4">Total Queries: {searchQueries.length}</h3>
      <div className="flex items-center space-x-8">
        <div className="max-w-full w-1/2">
          <Paginator
            currentPage={currentPage}
            count={searchQueries.length}
            limit={pageSize}
            handlePageChange={(newPage: number) => setCurrentPage(newPage)}
          />
        </div>
        <div className="flex flex-row gap-x-2">
          <div>Test Users</div>
          <Toggle checked={testUsers} onChange={() => setTestUsers(!testUsers)} />
        </div>
      </div>
      <div className="px-8">
        <table className="table-fixed table-row-format mt-4">
          <thead className="border-b-2 border-black">
            <tr>
              <td className="w-1/12 font-bold">Id</td>
              <td className="w-1/12 font-bold">Created At</td>
              <td className="w-7/12 font-bold">Query</td>
              <td className="w-2/12 font-bold">Scores</td>
              <td className="w-1/12 font-bold">Group</td>
            </tr>
          </thead>
          <tbody>
            {searchQueries
              .sort((search1, search2) => {
                return moment(search2.created_at).diff(search1.created_at);
              })
              .slice((currentPage - 1) * pageSize, currentPage * pageSize + pageSize)
              .map((query, index) => {
                return (
                  <tr key={index} className="py-2 border-b border-gray-200">
                    <td className="align-top min-w-20">{query.id}</td>
                    <td className="align-top min-w-20">{moment(query.created_at).format('MM/DD/YYYY')}</td>
                    <td className="align-top px-2 min-w-98 font-semibold">{query.query}</td>
                    <td className="align-top min-w-52">
                      {query.score_results?.map((result, idx) => (
                        <span key={result}>
                          {result}
                          {query.score_results?.length === idx + 1 ? '' : ', '}{' '}
                        </span>
                      ))}
                    </td>
                    <td className="align-top min-w-32">{query.client?.name || ''}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};
