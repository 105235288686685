import React, { useState, useMemo } from 'react';
import Header from '../../components/common/global-top-bar';
import Spinner from '../../components/common/spinner';
import Searchbar from '../../components/common/searchbar';
import { listenerTypes } from '../peer-profiles/listener-constants';
import SwitchBar from '../../components/switch-bar';
import { HeaderOptions } from './common/listener-review-constants';
import ProfileGradeCard from '../peer-profiles/common/profile-grade-card';
import { PeerOptions, usePeers } from '../../hooks/usePeers';
import { Paginator } from '../../components/common/paginator';
import { SystemMessage, useSystemMessages } from '../../hooks/useComms';

const AvatarReview: React.FunctionComponent = (): JSX.Element => {
  const [filters, setFilters] = useState<PeerOptions>({
    page: 1,
    limit: 25,
    search: '',
    profilePhotoApproved: false,
    hideIncompletePeers: true,
  });
  const adjustedFilters = useMemo(() => {
    const { userType } = filters;
    return userType?.includes('active_peers') ? { ...filters, activePeers: true } : filters;
  }, [filters]);
  const { data: listeners, isLoading } = usePeers(adjustedFilters);
  const { data: systemMessages } = useSystemMessages();
  const avatarRejectedSystemMessage = systemMessages?.filter(
    (message: SystemMessage) => message.group?.key === 'listener_profile_rejected',
  );

  return (
    <div>
      {isLoading && <Spinner />}
      <Header heading={'Avatars'} />
      <SwitchBar heading={HeaderOptions} position={2} />
      <Filters count={listeners?.count} filters={filters} setFilters={setFilters} />
      <div className="max-window-height  overflow-y-auto  px-7 ">
        {listeners &&
          listeners.count > 0 &&
          listeners.data?.map((ListenerDetails: any) => (
            <ProfileGradeCard
              key={ListenerDetails?.id}
              currentPhotoUrl={ListenerDetails?.current_profile_photo?.square_url}
              proposedPhotoUrl={ListenerDetails?.proposed_profile_photo?.square_url}
              profilePicVerified={!!ListenerDetails?.current_profile_photo}
              lastName={ListenerDetails?.user?.last_name || ''}
              firstName={ListenerDetails?.user?.first_name || ''}
              comms={avatarRejectedSystemMessage}
              peerState={ListenerDetails?.state}
              listenerId={ListenerDetails?.id}
            />
          ))}
      </div>
    </div>
  );
};

type FilterProps = {
  count: number | undefined;
  filters: PeerOptions;
  setFilters: React.Dispatch<React.SetStateAction<PeerOptions>>;
};

const Filters: React.FunctionComponent<FilterProps> = ({ count, filters, setFilters }): JSX.Element => {
  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7  h-10 ">
      <div className="w-1/4">
        <div className="w-full h-8">
          <Searchbar
            search={(data) => {
              setFilters((prev: PeerOptions) => {
                return { ...prev, page: 1, search: data };
              });
            }}
          />
        </div>
      </div>
      <div className="w-1/3">
        <Paginator
          count={count!}
          limit={filters.limit || 25}
          currentPage={filters.page || 1}
          handlePageChange={(page) => {
            setFilters((prev: PeerOptions) => {
              return { ...prev, page };
            });
          }}
        />
      </div>
      <div className="w-1/3">
        <div className=" flex justify-end">
          <div className="flex justify-between items-center py-4  gray-background-dark">
            {Object.keys(listenerTypes).map((item) => (
              <button
                className={
                  'text-sm px-5 py-1 ' +
                  (filters.userType?.includes(listenerTypes[item as keyof typeof listenerTypes]) ||
                  (filters.userType === undefined && item === 'All')
                    ? 'bg-blue-primary text-white'
                    : 'bg-gray-background-dark text-gray-dark')
                }
                onClick={() => {
                  setFilters((prev: PeerOptions) => {
                    return item === 'Peer Listeners' || item === 'Peers'
                      ? {
                          ...prev,
                          userType: [listenerTypes[item as keyof typeof listenerTypes]],
                          page: 1,
                        }
                      : item === 'Active'
                        ? {
                            ...prev,
                            page: 1,
                            userType: [listenerTypes[item as keyof typeof listenerTypes]],
                          }
                        : item === 'All'
                          ? {
                              ...prev,
                              page: 1,
                              userType: undefined,
                            }
                          : {
                              ...prev,
                              page: 1,
                              userType: [],
                            };
                  });
                }}
              >
                {item}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarReview;
