import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { axiosGet } from '../api/axios';

export interface CallOutcomesReportJsonResponse {
  count: number;
  data: CallOutcomesReport[];
}

export interface CallSummaryReportJsonResponse {
  count: number;
  data: CallSummaryReport[];
}

export type CallOutcome = 'success' | 'member_failed' | 'listener_failed';
export type CallFailReason =
  | 'member_cancelled'
  | 'member_rescheduled'
  | 'member_missed'
  | 'member_voicemail'
  | 'listener_missed'
  | 'listener_passed'
  | 'listener_timeout'
  | 'listener_voicemail'
  | 'short_call'
  | 'unknown';

export interface CallOutcomesReport {
  call_id: number;
  call_id_fmt: string;
  call_start: string;
  caller_role_id: number;
  client_id: number;
  client_name: string;
  duration: number;
  fail_reason: CallFailReason;
  is_crisis: boolean;
  is_crisis_fmt: string;
  is_scheduled: boolean;
  is_scheduled_fmt: string;
  listener_name: string;
  listener_role: ListenerRole;
  listener_role_id: number;
  listener_topic_tag_ids: number[];
  listener_topic_tags: string[];
  member_name: string;
  member_tag_group_ids: number[];
  member_tag_groups: string[];
  outcome: CallOutcome;
}

export interface CallSummaryReport {
  date: string;
  successful_call_average_minutes: number;
  total_calls: number;
  total_failed_calls: number;
  total_listener_failed: number;
  total_member_failed: number;
  total_now_calls: number;
  total_scheduled_calls: number;
  total_successful_calls: number;
}

export interface ListenerRole {
  id: number;
  user: User;
}

export interface User {
  id: number;
}

interface CallReportRequestFilters {
  startDate: string;
  endDate: string;
  listenerRoleId?: number;
  clientId?: number;
}
interface QueryParams {
  start_date: string;
  end_date: string;
  output_format: string;
  listener_role_id?: number;
  client_id?: number;
}

const getCallSummaryReport = async (filters: CallReportRequestFilters): Promise<CallSummaryReportJsonResponse> => {
  const queryParams: QueryParams = {
    start_date: filters.startDate,
    end_date: filters.endDate,
    output_format: 'json',
  };

  if (filters.listenerRoleId !== undefined) {
    queryParams.listener_role_id = filters.listenerRoleId;
  }

  if (filters.clientId !== undefined) {
    queryParams.client_id = filters.clientId;
  }

  const response = await axiosGet('/reporting/call_summary', queryParams, 'v3');
  return response.data;
};

export const useCallSummaryReport = (filters: CallReportRequestFilters) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<CallSummaryReportJsonResponse>(
    ['reporting', 'callReportSummaryJson', filters],
    () => getCallSummaryReport(filters),
    {
      refetchOnWindowFocus: false,
    },
  );
  return { data, isLoading, error, refetch, isFetching };
};

const getCallOutcomesReport = async (filters: CallReportRequestFilters): Promise<CallOutcomesReportJsonResponse> => {
  const queryParams: QueryParams = {
    start_date: filters.startDate,
    end_date: filters.endDate,
    output_format: 'json',
  };

  if (filters.listenerRoleId !== undefined) {
    queryParams.listener_role_id = filters.listenerRoleId;
  }

  if (filters.clientId !== undefined) {
    queryParams.client_id = filters.clientId;
  }

  const response = await axiosGet('/reporting/call_outcomes', queryParams, 'v3');
  return response.data;
};

export const useCallOutcomesReport = (filters: CallReportRequestFilters) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<CallOutcomesReportJsonResponse>(
    ['reporting', 'callReportOutcomeJson', filters],
    () => getCallOutcomesReport(filters),
    {
      refetchOnWindowFocus: false,
    },
  );
  return { data, isLoading, error, refetch, isFetching };
};

// CLJ: Do we want to use separate hooks for each report, or stick with this hook factory?
const getCallReportDownload = async (
  endpoint: string,
  filters: CallReportRequestFilters,
): Promise<{ blob: Blob; filename: string }> => {
  const queryParams: QueryParams = {
    start_date: filters.startDate,
    end_date: filters.endDate,
    output_format: 'csv',
  };

  if (filters.listenerRoleId !== undefined) {
    queryParams.listener_role_id = filters.listenerRoleId;
  }

  if (filters.clientId !== undefined) {
    queryParams.client_id = filters.clientId;
  }

  const response = await axiosGet(endpoint, queryParams, 'v3');
  const contentDisposition = response.headers['content-disposition'];
  const filenameMatch = contentDisposition?.match(/filename=(.*\.csv)/);
  const filename = filenameMatch![1];

  return {
    blob: new Blob([response.data], { type: 'text/csv' }),
    filename,
  };
};

const createCallReportDownloadHook = (endpoint: string, queryKey: string, successMessage: string) => {
  return (filters: CallReportRequestFilters, enabled: boolean) => {
    return useQuery<{ blob: Blob; filename: string }>(
      ['reporting', queryKey, filters],
      () => getCallReportDownload(endpoint, filters),
      {
        enabled,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          const downloadLink = document.createElement('a');
          const url = URL.createObjectURL(data.blob);
          downloadLink.href = url;
          downloadLink.download = data.filename;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(url);
          toast.success(successMessage);
        },
      },
    );
  };
};

export const useCallSummaryReportDownload = createCallReportDownloadHook(
  '/reporting/call_summary',
  'callReportSummaryCsv',
  'Call summary report downloaded successfully.',
);

export const useCallOutcomesReportDownload = createCallReportDownloadHook(
  '/reporting/call_outcomes',
  'callReportOutcomeCsv',
  'Call outcomes report downloaded successfully.',
);
