import React, { useEffect, useState, useMemo, createRef } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../components/common/global-top-bar';
import { CustomInput, ToggleButton, UseFormDropDown } from '../../components/common';
import SwitchBar from '../../components/switch-bar';
import { HeaderOptions } from './clients-constants';
import SaveButton from './switch-bar-button';
import HeadBack from './redirect';
// @ts-ignore
import { convertToRaw, EditorState, ContentState } from 'draft-js';
// @ts-ignore
import draftToHtml from 'draftjs-to-html';
//@ts-ignore
import htmlToDraft from 'html-to-draftjs';
import Spinner from '../../components/common/spinner';
import RichTextEditor from '../../components/common/rich-text-editor';
import { toast } from 'react-toastify';
import RouteLeavingGuard from '../../components/route-leaving-prompt';
import { ROUTE_PATH } from '../../routes/paths';
import Modal from '../../components/common/modal';
import { useClient, useCreateClient, useUpdateClient } from '../../hooks/useClients';
import { Button } from '@kindlyhuman/component-library';
import { useSyncHubspot } from '../../hooks/useClients';
import { TagExtended, useTags } from '../../hooks/useTags';
import { useForm, Controller } from 'react-hook-form';

const clientStatus = [
  { label: 'INACTIVE', key: 'inactive' },
  { label: 'SETUP', key: 'setup' },
  { label: 'LIVE', key: 'active' },
];

const Configure: React.FunctionComponent = (): JSX.Element => {
  const history = useHistory();
  const mutateSyncHubspot = useSyncHubspot();
  const initialClientId = localStorage.getItem('clientid');
  const [clientId, setClientId] = useState<string | null>(initialClientId);
  const { data: client, isFetching: clientFetching, refetch } = useClient(clientId ? parseInt(clientId) : undefined);
  const { createClient } = useCreateClient();
  const { updateClient } = useUpdateClient(parseInt(clientId!));
  const { data: tags, isLoading: tagsLoading } = useTags({ include_hidden: true });

  const [saveLocationPath, setSaveLocationPath] = useState<string>('');
  const [tagsModal, setTagsModal] = useState(false);

  const fileInputGroupGraphic: React.RefObject<HTMLInputElement> = createRef();
  const fileInputGroupLogo: React.RefObject<HTMLInputElement> = createRef();
  const fileInputGroupOverlayLogo: React.RefObject<HTMLInputElement> = createRef();
  const fileInputPromotionImage: React.RefObject<HTMLInputElement> = createRef();
  const fileInputPromotionVideo: React.RefObject<HTMLInputElement> = createRef();
  const fileInputWelcomeImage: React.RefObject<HTMLInputElement> = createRef();
  const fileInputWelcomeVideo: React.RefObject<HTMLInputElement> = createRef();
  const fileInputBackGroundLogo: React.RefObject<HTMLInputElement> = createRef();
  const fileInputVideoHostImage: React.RefObject<HTMLInputElement> = createRef();
  const fileInputPoweredByHeader: React.RefObject<HTMLInputElement> = createRef();
  const fileInputGroupBanner: React.RefObject<HTMLInputElement> = createRef();

  const defaultClient = useMemo(
    () => ({
      active: client?.status || 'setup',
      backgroundLogoImage: client?.logo_background_file_url || null,
      clientCode: client?.client_code || undefined,
      clientName: client?.name || '',
      contractDate: client?.contract_date || null,
      contractIncludesComms: client?.contract_includes_comms || false,
      crmSync: client?.crm_sync || false,
      enableEligibility: !!!client?.eligibility_config?.hide_client_admin_eligibility,
      goLiveDate: client?.go_live_date || null,
      groupBannerEnabled: client?.group_banner_enabled || false,
      groupBannerImage: client?.group_banner_file_url || null,
      groupGraphic: client?.logo_file_url || null,
      groupLogoImage: client?.comms_logo_file_url || undefined,
      groupLogoOverlayImage: client?.logo_overlay_file_url || null,
      industryVerticalCategory: client?.industry_vertical_category || '',
      internalNotes: client?.notes || '',
      isTest: client?.is_test || false,
      landingPromoEnabled: client?.promo_configuration?.enabled || false,
      memberSelfEnroll: client?.member_self_enrollment || false,
      poweredByHeaderEnabled: client?.powered_by_header_enabled || false,
      poweredByHeaderImage: client?.powered_by_header_file_url || null,
      promotionImage: client?.promo_configuration?.image_url || null,
      promotionType: client?.promo_configuration?.type || 'image',
      promotionVideo: client?.promo_configuration?.video_url || null,
      resellerName: client?.reseller || '',
      richText: client?.landing_page_content
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(htmlToDraft(client?.landing_page_content).contentBlocks),
          )
        : EditorState.createEmpty(),
      tagsArray:
        tags?.map((item: any) => ({
          ...item,
          hasTag: client?.allowed_listener_tags?.includes(item.id) || false,
        })) || [],
      videoConfigurationTitle: client?.video_configuration?.title || '',
      videoHostImage: client?.video_configuration?.author_image_url || null,
      videoHostName: client?.video_configuration?.author_name || '',
      welcomeImage: client?.video_configuration?.image_url || null,
      welcomeVideo: client?.video_configuration?.video_url || null,
      welcomeVideoEnabled: client?.video_configuration?.enabled || false,
    }),
    [client, tags],
  );

  const {
    control,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: useMemo(() => {
      if (client) {
        return defaultClient;
      }
    }, [client]),
  });

  const syncHubspot = (clientId: number) => {
    mutateSyncHubspot.mutate(clientId, {
      onSuccess: () => {
        toast.success('Hubspot Synced Successfully');
      },
      onError: (error: any) => {
        toast.error(error.response.data.description);
      },
    });
  };

  type imageUploadConfigKey =
    | 'backgroundLogo'
    | 'groupBannerImage'
    | 'groupGraphic'
    | 'groupLogo'
    | 'groupLogoOverlayImage'
    | 'poweredByHeaderImage'
    | 'promotionImage'
    | 'promotionVideo'
    | 'videoHostImage'
    | 'welcomeImage'
    | 'welcomeVideo';

  const imageUploadConfig: Record<
    imageUploadConfigKey,
    {
      formField: string;
      dimensions: { width: number; height: number } | null;
    }
  > = {
    backgroundLogo: {
      formField: 'backgroundLogoImage',
      dimensions: { width: 1280, height: 720 },
    },
    groupBannerImage: { formField: 'groupBannerImage', dimensions: null },
    groupGraphic: { formField: 'groupGraphic', dimensions: { width: 1280, height: 720 } },
    groupLogo: {
      formField: 'groupLogoImage',
      dimensions: { width: 1280, height: 720 },
    },
    groupLogoOverlayImage: {
      formField: 'groupLogoOverlayImage',
      dimensions: { width: 1280, height: 720 },
    },
    poweredByHeaderImage: { formField: 'poweredByHeaderImage', dimensions: null },
    promotionImage: {
      formField: 'promotionImage',
      dimensions: { width: 1280, height: 720 },
    },
    promotionVideo: { formField: 'promotionVideo', dimensions: null },
    videoHostImage: { formField: 'videoHostImage', dimensions: { width: 720, height: 720 } },
    welcomeImage: { formField: 'welcomeImage', dimensions: { width: 1280, height: 720 } },
    welcomeVideo: { formField: 'welcomeVideo', dimensions: null },
  };

  const inputRefs: Record<imageUploadConfigKey, React.RefObject<HTMLInputElement>> = {
    backgroundLogo: fileInputBackGroundLogo,
    groupBannerImage: fileInputGroupBanner,
    groupGraphic: fileInputGroupGraphic,
    groupLogo: fileInputGroupLogo,
    groupLogoOverlayImage: fileInputGroupOverlayLogo,
    poweredByHeaderImage: fileInputPoweredByHeader,
    promotionImage: fileInputPromotionImage,
    promotionVideo: fileInputPromotionVideo,
    videoHostImage: fileInputVideoHostImage,
    welcomeImage: fileInputWelcomeImage,
    welcomeVideo: fileInputWelcomeVideo,
  };

  const onFileChange = async (event: any, name: imageUploadConfigKey) => {
    try {
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        const config = imageUploadConfig[name];
        const { dimensions, formField } = config;

        reader.onload = (e: any) => {
          // We handle image and video uploads separately
          if (e.target.result.startsWith('data:image')) {
            const image = new Image();

            image.onload = () => {
              const { width, height } = image;

              if (dimensions && (width !== dimensions.width || height !== dimensions.height)) {
                toast.error(`Height and Width must be ${dimensions.width} x ${dimensions.height}.`);
                return;
              }
              setValue(formField as keyof typeof defaultClient, e.target.result, { shouldDirty: true });
            };
            image.src = e.target.result;
          } else {
            setValue(formField as keyof typeof defaultClient, e.target.result, { shouldDirty: true });
          }
        };

        reader.readAsDataURL(event.target.files[0]);
      }
    } catch (error) {
      console.error('err--', error);
    }
  };

  const handleImageUpload = (name: imageUploadConfigKey) => {
    inputRefs[name]?.current?.click();
  };

  const handleTags = (hasTag: boolean, id: number) => {
    const updatedTags = watch('tagsArray').map((item: TagExtended) => {
      if (item.id === id) {
        return { ...item, hasTag };
      } else {
        return item;
      }
    });
    setValue('tagsArray', updatedTags, { shouldDirty: true });
  };

  const handleSave = (data: any) => {
    if (data.welcomeVideoEnabled && (!data.welcomeVideo || !data.welcomeImage)) {
      toast.error('Please add welcome video & image');
      return;
    }
    const allowedTags = data.tagsArray.filter((item: TagExtended) => item.hasTag).map((item: any) => item.id);
    const payload = {
      allowed_listener_tags: allowedTags,
      client_code: data.clientCode || undefined,
      comms_logo_file_url: data.groupLogoImage,
      contract_date: data.contractDate,
      contract_includes_comms: data.contractIncludesComms,
      crm_sync: data.crmSync,
      eligibility_config: {
        hide_client_admin_eligibility: !data.enableEligibility,
      },
      go_live_date: data.goLiveDate,
      group_banner_enabled: data.groupBannerEnabled,
      group_banner_file_url: data.groupBannerImage,
      industry_vertical_category: data.industryVerticalCategory,
      is_test: data.isTest,
      landing_page_content: data.richText ? draftToHtml(convertToRaw(data.richText.getCurrentContent())) : undefined,
      logo_background_file_url: data.backgroundLogoImage,
      logo_file_url: data.groupGraphic,
      logo_overlay_file_url: data.groupLogoOverlayImage,
      member_self_enrollment: data.memberSelfEnroll,
      name: data.clientName?.trim(),
      notes: data.internalNotes,
      powered_by_header_enabled: data.poweredByHeaderEnabled,
      powered_by_header_file_url: data.poweredByHeaderImage,
      promo_configuration: {
        enabled: data.landingPromoEnabled,
        image_url: data.promotionImage,
        type: data.promotionType,
        video_url: data.promotionVideo,
      },
      reseller: data.resellerName?.trim(),
      status: data.active,
      video_configuration: {
        author_image_url: data.videoHostImage,
        author_name: data.videoHostName?.trim(),
        enabled: data.welcomeVideoEnabled,
        image_url: data.welcomeImage,
        title: data.videoConfigurationTitle?.trim(),
        video_url: data.welcomeVideo,
      },
    };
    if (clientId) {
      updateClient.mutate(payload, {
        onSuccess: () => {
          if (saveLocationPath !== '') {
            history.push(saveLocationPath);
          }
          refetch().then(() => {
            reset(defaultClient);
          });
        },
        onError: (error: any) => {
          toast.error(error.response.data.description);
        },
      });
    } else {
      createClient.mutate(payload, {
        onSuccess: (response) => {
          if (response && response.id) {
            localStorage.setItem('clientid', response.id.toString());
            setClientId(response.id.toString());
          }
          if (saveLocationPath !== '') {
            history.push(saveLocationPath);
          }
          reset(getValues());
        },
        onError: (error: any) => {
          toast.error(error.response.data.description);
        },
      });
    }
  };

  useEffect(() => {
    if (saveLocationPath!) {
      history.push(saveLocationPath);
    }
    if (client || tags) {
      reset(defaultClient);
    }
  }, [client, tags]);

  return (
    <div>
      {(clientFetching || tagsLoading) && <Spinner />}
      <Header heading={<HeadBack clientName={watch('clientName')} />} />
      <SwitchBar
        heading={HeaderOptions}
        position={1}
        isDisabled={clientId ? false : true}
        optionalButton={
          <div className="w-1/12">
            <SaveButton onSave={handleSubmit(handleSave)} disable={!isDirty} />
          </div>
        }
      />
      <div className="max-window-height pb-32 pt-4 overflow-y-auto">
        <div className="flex justify-between">
          <div className="w-1/2 px-7 flex gap-4">
            <div className="py-4 w-1/2">
              <CustomInput
                Label={'GROUP NAME'}
                labelClass="font-bold"
                inputType={'text'}
                register={{
                  ...register('clientName', {
                    required: true,
                  }),
                }}
                Error={errors.clientName}
                ErrorMessage={'Field is Required'}
              />
            </div>
            <div className="py-4 w-1/2">
              <CustomInput
                Label={'RESELLER NAME'}
                labelClass="font-bold"
                inputType={'text'}
                register={{
                  ...register('resellerName', {
                    required: true,
                  }),
                }}
                Error={errors.resellerName}
                ErrorMessage={'Field is Required'}
              />
            </div>
          </div>
          <div className="w-1/2 px-7">
            <p className="py-2 font-bold text-gray-dark">STATUS</p>
            <div className="w-full flex justify-start gap-x-4">
              {clientStatus.map((item: any) => (
                <Controller
                  key={item.key}
                  name="active"
                  control={control}
                  render={({ field }) => (
                    <button
                      className={
                        'px-5 py-1 text-white rounded-full font-medium' +
                        (item.key === field.value
                          ? item.label === 'SETUP'
                            ? ' stacked-bar-orange-primary '
                            : item.label === 'INACTIVE'
                              ? ' stacked-bar-red-primary '
                              : ' stacked-bar-green-primary '
                          : ' bg-gray-300 ')
                      }
                      onClick={() => {
                        field.onChange(item.key);
                      }}
                    >
                      {item.label}
                    </button>
                  )}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="w-1/2 px-7 flex gap-4">
          <div className="py-4 w-1/2">
            <CustomInput
              Label={'CONTRACT DATE'}
              labelClass="font-bold"
              inputType={'date'}
              register={{
                ...register('contractDate', {
                  required: true,
                }),
              }}
              Error={errors.contractDate}
              ErrorMessage={'Field is Required'}
            />
          </div>
          <div className="py-4 w-1/2">
            <CustomInput
              Label={'GO LIVE DATE'}
              labelClass="font-bold"
              inputType={'date'}
              register={{
                ...register('goLiveDate', {
                  required: true,
                }),
              }}
              Error={errors.goLiveDate}
              ErrorMessage={'Field is Required'}
            />
          </div>
        </div>
        <div className="w-1/2 px-7 flex gap-4">
          <div className="py-4 w-1/2">
            <CustomInput
              Label={'GROUP CODE'}
              labelClass="font-bold"
              inputType={'text'}
              register={{
                ...register('clientCode'),
              }}
            />
          </div>
          <div className="py-4 w-1/2">
            <CustomInput
              Label={'Industry / Vertical'}
              labelClass="font-bold"
              inputType={'text'}
              register={{
                ...register('industryVerticalCategory'),
              }}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="w-1/2 px-7">
            <p className="py-2 font-bold text-gray-dark">GLOBAL GRAPHICS</p>
            <hr className="bg-gray-dark"></hr>
            <p className="pt-1 font-normal text-gray-dark">GROUP GRAPHIC</p>
            <p className="pt-1 py-2 font-normal text-gray-dark">Displays as the provider of the benefit</p>
            <div className="w-full flex">
              <div
                className={`${
                  !watch('groupGraphic') ? 'cursor-pointer' : ''
                } bg-gray-background-light rounded-sm  w-full aspect-ratio-container`}
                onClick={() => handleImageUpload('groupGraphic')}
              >
                {watch('groupGraphic') ? (
                  <div className="aspect-ratio-div">
                    <img
                      className=" aspect-ratio-container-img "
                      src={watch('groupGraphic') || ''}
                      alt="clientsImage"
                    />
                  </div>
                ) : (
                  <div className="h-56 flex justify-center items-center">
                    <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center ">
                      +
                    </button>
                    <input
                      ref={fileInputGroupGraphic}
                      id="file-upload"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => onFileChange(e, 'groupGraphic')}
                      onClick={() => {
                        if (fileInputGroupGraphic.current) {
                          fileInputGroupGraphic.current.value = '';
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="pl-4">
                <p className="pt-4 font-normal text-gray-dark">1280 x 720 px</p>
                <div className="w-full aspect-ratio-container pt-2">
                  {watch('groupGraphic') && (
                    <button
                      className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                      onClick={() => {
                        setValue('groupGraphic', '', { shouldDirty: true });
                      }}
                    >
                      REMOVE
                    </button>
                  )}
                </div>
              </div>
            </div>
            <p className="pt-10 font-normal text-gray-dark">GROUP LOGO</p>
            <p className="pt-1 py-2 font-normal text-gray-dark">
              Displays in comms and MWA tiles for group affiliated content
            </p>
            <div className="w-full flex">
              <div
                className={`${
                  watch('groupLogoImage') ? '' : 'cursor-pointer'
                } bg-gray-background-light  rounded-sm  w-full  aspect-ratio-container`}
                onClick={() => handleImageUpload('groupLogo')}
              >
                {watch('groupLogoImage') ? (
                  <div className="aspect-ratio-div">
                    <img
                      className=" aspect-ratio-container-img "
                      src={watch('groupLogoImage') || ''}
                      alt="groupLogoImage"
                    />
                  </div>
                ) : (
                  <div className="h-56 flex justify-center items-center">
                    <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                      +
                    </button>
                    <input
                      ref={fileInputGroupLogo}
                      id="file-upload-group-logo"
                      type="file"
                      className="hidden"
                      onChange={(e) => onFileChange(e, 'groupLogo')}
                      accept="image/*"
                      onClick={() => {
                        if (fileInputGroupLogo.current) {
                          fileInputGroupLogo.current.value = '';
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="pl-4">
                <p className="pt-4 font-normal text-gray-dark">1280 x 720 px</p>
                <div className="w-full aspect-ratio-container pt-2">
                  {watch('groupLogoImage') && (
                    <div className="w-full aspect-ratio-container ">
                      <button
                        className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                        onClick={() => {
                          setValue('groupLogoImage', '', { shouldDirty: true });
                        }}
                      >
                        REMOVE
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2 px-7 ">
            <div className="flex justify-between">
              <p className="py-2  text-gray-dark text-left">For Testing:</p>
              <ToggleButton
                id={'isTest'}
                setStatus={(e) => {
                  setValue('isTest', e, { shouldDirty: true });
                }}
                status={watch('isTest')}
              />
            </div>
            <div className="flex justify-between">
              <p className="py-2  text-gray-dark text-left">Enable Client Admin Eligibility Access</p>
              <ToggleButton
                id={'EligibilityAccess'}
                setStatus={(e) => {
                  setValue('enableEligibility', e, { shouldDirty: true });
                }}
                status={watch('enableEligibility')}
              />
            </div>
            <div className="flex justify-between pt-2">
              <p className="py-2  text-gray-dark text-left">Enable Client for Member Self Enroll</p>
              <ToggleButton
                id={'self-enroll'}
                setStatus={(e) => {
                  setValue('memberSelfEnroll', e, { shouldDirty: true });
                }}
                status={watch('memberSelfEnroll')}
              />
            </div>
            <div className="flex justify-between pt-2">
              <p className="py-2  text-gray-dark text-left">Enable CRM Sync</p>
              <ToggleButton
                id={'crm-sync'}
                setStatus={(e) => {
                  setValue('crmSync', e, { shouldDirty: true });
                }}
                status={watch('crmSync')}
              />
            </div>
            <div className="flex justify-between pt-2">
              <p className="py-2  text-gray-dark text-left">Contract Includes Comms</p>
              <ToggleButton
                id={'contract-includes-comms'}
                setStatus={(e) => {
                  setValue('contractIncludesComms', e, { shouldDirty: true });
                }}
                status={watch('contractIncludesComms')}
              />
            </div>
            <div className="flex justify-between">
              <p className="py-2 font-bold text-gray-dark text-left">REQUIRED LISTENER TAGS</p>
              <button
                className="py-2 font-bold text-right text-blue-primary "
                onClick={() => {
                  setTagsModal(true);
                }}
              >
                + Add
              </button>
            </div>
            <hr className="bg-gray-dark"></hr>
            <Controller
              name="tagsArray"
              control={control}
              render={({ field }) => (
                <div className="w-full flex-wrap space-x-2 space-y-2 text-sm -ml-2 ">
                  {field.value?.length > 0 && field.value.filter((tag: TagExtended) => tag.hasTag).length > 0 ? (
                    field.value
                      .filter((tag: any) => tag.hasTag)
                      .map((item: TagExtended, index: number) => {
                        return (
                          <button
                            key={item.id}
                            className={`bg-citrus text-white rounded-md text-sm font-bold ${
                              index === 0 ? 'ml-2 mt-2' : ''
                            }`}
                            onClick={() => {
                              const updatedTags = field.value.map((tag: TagExtended) =>
                                tag.id === item.id ? { ...tag, hasTag: false } : tag,
                              );
                              field.onChange(updatedTags);
                            }}
                          >
                            <div className="flex px-3 py-2">
                              <div className="grow pr-2">{item.name}</div>
                              <div className="grow-0">x</div>
                            </div>
                          </button>
                        );
                      })
                  ) : (
                    <p className="m-2"> No Listener Tags </p>
                  )}
                </div>
              )}
            />
            <p className="py-2 pt-4 font-bold text-gray-dark text-left">Peer Group Link</p>
            <hr className="bg-gray-dark"></hr>
            <div
              title="copy"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_LISTENER_LINK}/onboarding/client-route/${clientId}/${encodeURI(watch('clientName'))}`,
                );
                toast.success('Link Copied');
              }}
              className="w-full pt-4 flex-wrap space-x-2 space-y-2 cursor-pointer"
            >
              {process.env.REACT_APP_LISTENER_LINK}/onboarding/client-route/{clientId}/{encodeURI(watch('clientName'))}
            </div>
            {watch('crmSync') && (
              <div>
                <p className="py-2 pt-4 font-bold text-gray-dark text-left">Sync with Hubspot</p>
                <hr className="bg-gray-dark"></hr>
                <Button className="mt-4" variant="primary" onClick={() => syncHubspot(Number(clientId))}>
                  Sync
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="w-full px-7 mt-5">
          <p className="py-2 font-bold text-gray-dark">LANDING PAGE</p>
          <hr className="bg-gray-dark"></hr>
          <div className="flex w-full">
            <div className="w-1/2">
              <p className="pt-4 font-medium text-gray-dark">LANDING HEADER BACKGROUND</p>
              <p className="py-1 pb-4 font-medium text-gray-dark">Displays at the top of the landing page</p>
              <div className="flex">
                <div
                  className={`${
                    watch('backgroundLogoImage') ? '' : 'cursor-pointer'
                  } bg-gray-background-light  rounded-sm  w-full    aspect-ratio-container`}
                  onClick={() => handleImageUpload('backgroundLogo')}
                >
                  {watch('backgroundLogoImage') ? (
                    <div className="aspect-ratio-div">
                      <img
                        className=" aspect-ratio-container-img "
                        src={watch('backgroundLogoImage') || ''}
                        alt="BackgroundLogoImage"
                      />
                    </div>
                  ) : (
                    <div className="h-56 flex justify-center items-center">
                      <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                        +
                      </button>
                      <input
                        ref={fileInputBackGroundLogo}
                        id="file-upload-group-logo"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => onFileChange(e, 'backgroundLogo')}
                        onClick={() => {
                          if (fileInputBackGroundLogo.current) {
                            fileInputBackGroundLogo.current.value = '';
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="pl-4">
                  <p className="pt-4 font-normal text-gray-dark">1280 x 720 px</p>
                  {watch('backgroundLogoImage') && (
                    <div className=" w-full  aspect-ratio-container ">
                      <button
                        className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                        onClick={() => {
                          setValue('backgroundLogoImage', '', { shouldDirty: true });
                        }}
                      >
                        REMOVE
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <p className="pt-4 font-medium text-gray-dark">LANDING HEADER LOGO</p>
              <p className="pt-1 pb-4 font-medium text-gray-dark">Displays on top of the Header Background</p>
              <div className="flex">
                <div
                  className={`${
                    watch('groupLogoOverlayImage') ? '' : 'cursor-pointer'
                  } bg-gray-background-light  rounded-sm  w-full    aspect-ratio-container`}
                  onClick={() => handleImageUpload('groupLogoOverlayImage')}
                >
                  {watch('groupLogoOverlayImage') ? (
                    <div className="aspect-ratio-div">
                      <img
                        className=" aspect-ratio-container-img "
                        src={watch('groupLogoOverlayImage') || ''}
                        alt="groupLogoOverlayImage"
                      />
                    </div>
                  ) : (
                    <div className="h-56 flex justify-center items-center">
                      <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                        +
                      </button>
                      <input
                        ref={fileInputGroupOverlayLogo}
                        id="file-upload-group-overlay-logo"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => onFileChange(e, 'groupLogoOverlayImage')}
                        onClick={() => {
                          if (fileInputGroupOverlayLogo.current) {
                            fileInputGroupOverlayLogo.current.value = '';
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="pl-4">
                  <p className="pt-4 font-normal text-gray-dark">1280 x 720 px</p>
                  <div className="w-full aspect-ratio-container pt-2">
                    {watch('groupLogoOverlayImage') && (
                      <div className=" w-full aspect-ratio-container ">
                        <button
                          className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                          onClick={() => {
                            setValue('groupLogoOverlayImage', '', { shouldDirty: true });
                          }}
                        >
                          REMOVE
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-between items-center mt-6">
            <p className="pt-4 font-bold text-gray-dark">LANDING PROMO</p>
            <div>
              <ToggleButton
                id={'landingPromoEnabledToggle'}
                setStatus={(e) => {
                  setValue('landingPromoEnabled', e, { shouldDirty: true });
                }}
                status={watch('landingPromoEnabled')}
              />
            </div>
          </div>
          <hr className="bg-gray-dark"></hr>
          <div
            className={
              !watch('landingPromoEnabled') ? ' bg-white opacity-50 pointer-events-none cursor-not-allowed ' : ''
            }
          >
            <div className="w-full flex justify-between mt-6">
              <div className="w-1/2">
                <UseFormDropDown label={'LANDING PROMO TYPE'}>
                  <select className="select-box w-full" defaultValue="image" {...register('promotionType')}>
                    <option value={'image'}>Image</option>
                    <option value={'video'}>Video</option>
                  </select>
                </UseFormDropDown>
              </div>
            </div>
            <div className="flex w-full ">
              <div className="w-1/2">
                <p className="pt-4 font-medium text-gray-dark">
                  {watch('promotionType') === 'video' ? 'VIDEO THUMBNAIL' : 'LANDING PROMO IMAGE'}
                </p>
                <p className="py-1 pb-4 font-medium text-gray-dark">Displays on the Landing Page</p>
                <div className="flex">
                  <div
                    className={`${
                      watch('promotionImage') ? '' : 'cursor-pointer'
                    } bg-gray-background-light  rounded-sm  w-full    aspect-ratio-container`}
                    onClick={() => handleImageUpload('promotionImage')}
                  >
                    {watch('promotionImage') ? (
                      <div className="aspect-ratio-div">
                        <img
                          className=" aspect-ratio-container-img "
                          src={watch('promotionImage') || ''}
                          alt="PromotionImage"
                        />
                      </div>
                    ) : (
                      <div className="h-56 flex justify-center items-center">
                        <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                          +
                        </button>
                        <input
                          ref={fileInputPromotionImage}
                          id="file-upload-group-promotion-image"
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={(e) => onFileChange(e, 'promotionImage')}
                          onClick={() => {
                            if (fileInputPromotionImage.current) {
                              fileInputPromotionImage.current.value = '';
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="pl-4">
                    <p className="pt-4 font-normal text-gray-dark">1280 x 720 px</p>
                    {watch('promotionImage') && (
                      <div className=" w-full aspect-ratio-container ">
                        <button
                          className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                          onClick={() => {
                            setValue('promotionImage', '', { shouldDirty: true });
                          }}
                        >
                          REMOVE
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {watch('promotionType') === 'video' && (
                <div className="w-1/2">
                  <p className="pt-4 font-medium text-gray-dark">LANDING PROMO VIDEO</p>
                  <p className="py-1 pb-4 font-medium text-gray-dark">Displays on the Landing Page</p>
                  <div className="flex">
                    <div
                      className={`${
                        watch('promotionVideo') ? '' : 'cursor-pointer'
                      } bg-gray-background-light rounded-sm w-full aspect-ratio-container `}
                      onClick={() => handleImageUpload('promotionVideo')}
                    >
                      {watch('promotionVideo') ? (
                        <div>
                          <video width="100%" controls>
                            <source src={watch('promotionVideo') || ''} />
                            Your browser does not support HTML5 video.
                          </video>
                        </div>
                      ) : (
                        <div className="h-56 flex justify-center items-center ">
                          <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                            +
                          </button>
                          <input
                            ref={fileInputPromotionVideo}
                            id="file-upload-promotion-overlay-video"
                            accept="video/mp4,video/x-m4v,video/*"
                            type="file"
                            className="hidden"
                            onChange={(e) => onFileChange(e, 'promotionVideo')}
                            onClick={() => {
                              if (fileInputPromotionVideo.current) {
                                fileInputPromotionVideo.current.value = '';
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="pl-4">
                      {watch('promotionVideo') && (
                        <div className=" w-full aspect-ratio-container ">
                          <button
                            className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                            onClick={() => {
                              setValue('promotionVideo', '', { shouldDirty: true });
                            }}
                          >
                            REMOVE
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-4 w-full">
            <p className="pt-4 font-bold text-gray-dark">LANDING PAGE MESSAGE</p>
            <div className="mt-4">
              <RichTextEditor
                RichText={watch('richText')}
                setRichText={(value) => setValue('richText', value, { shouldDirty: true })}
              />
            </div>
          </div>
        </div>
        <div className="w-full mt-8 px-7">
          <div className="w-full flex justify-between items-center">
            <p className="pt-4 font-bold text-gray-dark">WELCOME VIDEO</p>
            <div>
              <ToggleButton
                id={'welcomeVideoEnabled'}
                setStatus={(e) => {
                  setValue('welcomeVideoEnabled', e, { shouldDirty: true });
                }}
                status={watch('welcomeVideoEnabled')}
              />
            </div>
          </div>
          <hr className="bg-gray-dark"></hr>
          <div className="relative">
            {!watch('welcomeVideoEnabled') && <div className="w-full h-full absolute z-50 bg-white opacity-50"></div>}
            <div className="w-full flex">
              <div className="w-1/2">
                <p className="pt-4 pb-4 font-medium text-gray-dark">WELCOME VIDEO THUMBNAIL</p>
                <div className="flex">
                  <div
                    className={`${
                      watch('welcomeImage') ? '' : 'cursor-pointer'
                    } bg-gray-background-light  rounded-sm  w-full aspect-ratio-container`}
                    onClick={() => handleImageUpload('welcomeImage')}
                  >
                    {watch('welcomeImage') ? (
                      <div className="aspect-ratio-div">
                        <img
                          className=" aspect-ratio-container-img "
                          src={watch('welcomeImage') || ''}
                          alt="welcomeImage"
                        />
                      </div>
                    ) : (
                      <div className="h-56 flex justify-center items-center">
                        <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                          +
                        </button>
                        <input
                          ref={fileInputWelcomeImage}
                          id="file-upload-group-promotion-image"
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={(e) => onFileChange(e, 'welcomeImage')}
                          onClick={() => {
                            if (fileInputWelcomeImage.current) {
                              fileInputWelcomeImage.current.value = '';
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="pl-4">
                    <p className="pt-4 font-normal text-gray-dark">1280 x 720 px</p>
                    {watch('welcomeImage') && (
                      <div className=" w-full aspect-ratio-container ">
                        <button
                          className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                          onClick={() => {
                            setValue('welcomeImage', '', { shouldDirty: true });
                          }}
                        >
                          REMOVE
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <p className="pt-4 pb-4 font-medium text-gray-dark">WELCOME VIDEO FILE</p>
                <div className="flex">
                  <div
                    className={`${
                      watch('welcomeVideo') ? '' : 'cursor-pointer'
                    } bg-gray-background-light  rounded-sm  w-full  aspect-ratio-container`}
                    onClick={() => handleImageUpload('welcomeVideo')}
                  >
                    {watch('welcomeVideo') ? (
                      <div className="">
                        <video width="100%" controls>
                          <source src={watch('welcomeVideo') || ''} />
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                    ) : (
                      <div className="h-56  w-full flex justify-center items-center">
                        <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                          +
                        </button>
                        <input
                          ref={fileInputWelcomeVideo}
                          id="file-upload-promotion-overlay-video"
                          accept="video/mp4,video/x-m4v,video/*"
                          type="file"
                          className="hidden"
                          onChange={(e) => onFileChange(e, 'welcomeVideo')}
                          onClick={() => {
                            if (fileInputWelcomeVideo.current) {
                              fileInputWelcomeVideo.current.value = '';
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="pl-4">
                    {watch('welcomeVideo') && (
                      <div className=" w-full    aspect-ratio-container ">
                        <button
                          className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                          onClick={() => {
                            setValue('welcomeVideo', '', { shouldDirty: true });
                          }}
                        >
                          REMOVE
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex">
              <div className="w-1/2">
                <p className="pt-4 pb-4 font-medium text-gray-dark">VIDEO HOST IMAGE</p>
                <div className="flex">
                  <div
                    className={`${
                      watch('videoHostImage') ? '' : 'cursor-pointer'
                    } bg-gray-background-light rounded-sm w-56 h-56 aspect-ratio-container`}
                    onClick={() => handleImageUpload('videoHostImage')}
                  >
                    {watch('videoHostImage') ? (
                      <div className="aspect-ratio-div">
                        <img
                          className=" aspect-ratio-container-img "
                          src={watch('videoHostImage') || ''}
                          alt="groupLogoOverlayImage"
                        />
                      </div>
                    ) : (
                      <div className="h-56 flex justify-center items-center">
                        <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                          +
                        </button>
                        <input
                          ref={fileInputVideoHostImage}
                          id="file-upload-group-author-logo"
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={(e) => onFileChange(e, 'videoHostImage')}
                          onClick={() => {
                            if (fileInputVideoHostImage.current) {
                              fileInputVideoHostImage.current.value = '';
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="pl-4">
                    <p className="pt-4 font-normal text-gray-dark">720 x 720 px</p>
                    {watch('videoHostImage') && (
                      <div className=" w-full aspect-ratio-container ">
                        <button
                          className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                          onClick={() => {
                            setValue('videoHostImage', '', { shouldDirty: true });
                          }}
                        >
                          REMOVE
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <div className="mt-14">
                  <CustomInput
                    inputType={'text'}
                    register={{
                      ...register('videoHostName'),
                    }}
                    Label={'VIDEO HOST NAME'}
                    labelClass="font-medium"
                    disabled={!watch('welcomeVideoEnabled')}
                  />
                </div>
                <div className="mt-4">
                  <CustomInput
                    inputType={'text'}
                    register={{
                      ...register('videoConfigurationTitle'),
                    }}
                    disabled={!watch('welcomeVideoEnabled')}
                    Label={'VIDEO HOST TITLE'}
                    labelClass="font-medium"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-4 px-7">
          <div className="w-full flex justify-between items-center mt-6 ">
            <p className="pt-4 font-bold text-gray-dark">POWERED BY HEADER </p>
            <div>
              <ToggleButton
                id={'PoweredByToggle'}
                setStatus={(e) => {
                  setValue('poweredByHeaderEnabled', e, { shouldDirty: true });
                }}
                status={watch('poweredByHeaderEnabled')}
              />
            </div>
          </div>
          <hr className="bg-gray-dark"></hr>
          <div
            className={`pt-4 ${!watch('poweredByHeaderEnabled') ? 'bg-white opacity-50 pointer-events-none cursor-not-allowed' : ''}`}
          >
            <p className="py-2 text-red">
              Disclaimer: The logo this image will replace is 180px by 36px, a 5:1 ratio. Upload an image with those
              dimensions for best results
            </p>
            <p className="pt-1 font-normal text-gray-dark">HEADER IMAGE</p>
            <p className="pt-1 py-2 font-normal text-gray-dark">Replaces the Kindly Human Header when active</p>
            <div className="w-1/2 flex">
              <div
                className={`${
                  !watch('poweredByHeaderImage') ? 'cursor-pointer bg-gray-background-light' : ''
                } rounded-sm w-full h-56 relative`}
                onClick={() => handleImageUpload('poweredByHeaderImage')}
              >
                {watch('poweredByHeaderImage') ? (
                  <div className="w-full h-full">
                    <img
                      className="w-auto h-56 object-cover"
                      src={watch('poweredByHeaderImage') || ''}
                      alt="poweredByHeaderImage"
                    />
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-full">
                    <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                      +
                    </button>
                    <input
                      ref={fileInputPoweredByHeader}
                      id="file-upload"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => onFileChange(e, 'poweredByHeaderImage')}
                      onClick={() => {
                        if (fileInputPoweredByHeader.current) {
                          fileInputPoweredByHeader.current.value = '';
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="pl-4">
                <div className="w-full pt-2">
                  {watch('poweredByHeaderImage') && (
                    <button
                      className="bg-gray-300 rounded-full text-center text-sm text-white p-2 w-full my-2"
                      onClick={() => {
                        console.log('remove');
                        setValue('poweredByHeaderImage', '', { shouldDirty: true });
                      }}
                    >
                      REMOVE
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-4 px-7">
          <div className="w-full flex justify-between items-center mt-6 ">
            <p className="pt-4 font-bold text-gray-dark">Group Banner </p>
            <div>
              <ToggleButton
                id={'GroupBannerToggle'}
                setStatus={(e) => {
                  setValue('groupBannerEnabled', e, { shouldDirty: true });
                }}
                status={watch('groupBannerEnabled')}
              />
            </div>
          </div>
          <hr className="bg-gray-dark"></hr>
          <div
            className={`pt-4 ${!watch('groupBannerEnabled') ? 'bg-white opacity-50 pointer-events-none cursor-not-allowed' : ''}`}
          >
            <p className="py-2 text-red">
              Disclaimer: The banner logo dimensions are not validated. the image ratio on the banner is 3:1. Upload an
              image with that ratio for best results
            </p>
            <p className="py-3 font-normal text-gray-dark">Group Banner Image</p>
            <div className="w-1/2 flex">
              <div
                className={`${
                  !watch('groupBannerImage') ? 'cursor-pointer bg-gray-background-light' : ''
                } rounded-sm w-full h-56 relative`}
                onClick={() => handleImageUpload('groupBannerImage')}
              >
                {watch('groupBannerImage') ? (
                  <div className="w-full h-full">
                    <img
                      className="w-auto h-56 object-cover"
                      src={watch('groupBannerImage') || ''}
                      alt="groupBannerImage"
                    />
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-full">
                    <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                      +
                    </button>
                    <input
                      ref={fileInputGroupBanner}
                      id="file-upload"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => onFileChange(e, 'groupBannerImage')}
                      onClick={() => {
                        if (fileInputGroupBanner.current) {
                          fileInputGroupBanner.current.value = '';
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="pl-4">
                <div className="w-full pt-2">
                  {watch('groupBannerImage') && (
                    <button
                      className="bg-gray-300 rounded-full text-center text-sm text-white p-2 w-full my-2"
                      onClick={() => {
                        setValue('groupBannerImage', '', { shouldDirty: true });
                      }}
                    >
                      REMOVE
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-4 px-7">
          <p className="pt-4 font-bold text-gray-dark">INTERNAL NOTES</p>
          <hr className="bg-gray-dark"></hr>
          <div className="w-full pt-4">
            <textarea
              value={watch('internalNotes')}
              className="py-2 px-3 w-full rounded-md  h-28 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
              onChange={(e) => {
                setValue('internalNotes', e.target.value, { shouldDirty: true });
              }}
            />
          </div>
        </div>
      </div>
      <RouteLeavingGuard
        when={isDirty}
        navigate={(path: string) => history.push(path)}
        shouldBlockNavigation={(location: any) => {
          if (location.pathname !== ROUTE_PATH.GROUPS_CONFIGURE) {
            return true;
          }
          return false;
        }}
        titleText={'Alert'}
        contentText={'You have unsaved changes. Do you want to leave this page and lose your changes?'}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Disregard changes'}
        confirmSaveButtonText="Continue and Save changes"
        handleContinueSaveChange={(path) => {
          setSaveLocationPath(path);
          handleSubmit(handleSave)();
        }}
      />
      <Modal
        isModel={tagsModal}
        InnerComponent={
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden border-4 shadow-xl transform transition-all sm:my-32 sm:align-middle sm:max-w-4xl sm:w-full">
            <div className="bg-white w-full px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="flex justify-between sticky top-0 z-50 bg-white border-b-4 pb-4 mb-4">
                    <h3 className="text-lg leading-6  text-gray-900 font-bold mb-3" id="modal-title">
                      Add Listener Tags
                    </h3>
                    <button
                      type="button"
                      className={'px-5 py-1 text-white rounded-full font-medium bg-gray-400 '}
                      onClick={() => {
                        setTagsModal(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                  <div className="w-full flex-wrap space-x-2 space-y-2 text-sm -ml-2 overflow-auto max-h-96">
                    {watch('tagsArray')?.length > 0 &&
                      watch('tagsArray')
                        .sort((a: any, b: any) => a.name.localeCompare(b.name))
                        .map((item: any, index: number) => {
                          return (
                            <button
                              key={`status_${index}`}
                              className={`${
                                item.hasTag ? ' bg-citrus ' : ' bg-gray-background-light '
                              } text-black rounded-md text-sm font-bold ${index === 0 ? ' ml-2 mt-2 ' : ' '}`}
                              onClick={() => {
                                handleTags(!item.hasTag, item.id);
                              }}
                            >
                              <div className="flex px-3 py-2">
                                <div className="grow  pr-2">{item.name}</div>
                              </div>
                            </button>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Configure;
