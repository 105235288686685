import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';
import { toast } from 'react-toastify';

export interface GlobalDashboardResponse {
  active_groups: number;
  activated_members: number;
  engaged_members: number;
  total_members: number;
  connected_members: number;
  calls_count: number;
  callers_count: number;
  failed_calls: number;
  passed_calls: number;
  escalated_calls: number;
  calls_scheduled: number;
  all_calls_length: number; // length in seconds
  crisis_elevation_count: number;
  reflection_reminders_sent: number;
  reflections_responded: number;
  reflections_positive: number;
  reflections_neutral: number;
  reflections_negative: number;
  experience_listen_count: number;
  experience_favorite_count: number;
}

export interface AudioCountResponse {
  count: number;
}

const getReportingOverview = async (clientId?: number, packageId?: number): Promise<GlobalDashboardResponse> => {
  return await axiosGet('/reporting/summary', { client_id: clientId, package_id: packageId }, 'v3').then(
    (reportingResponse) => reportingResponse.data,
  );
};

const getReportingWithDateSearch = async (
  startDate: string,
  endDate: string,
  clientId?: number,
  packageId?: number,
): Promise<GlobalDashboardResponse> => {
  return await axiosGet(
    '/reporting/rollup_search',
    { client_id: clientId, package_id: packageId, start_date: startDate, end_date: endDate },
    'v3',
  ).then((reportingResponse) => reportingResponse.data);
};

const getListenerAudioCount = async (data: any): Promise<AudioCountResponse> => {
  return await axiosGet('/listeners/audio/count', data, 'v3').then((reportingResponse) => reportingResponse.data);
};

export const useReportingOverview = (clientId?: number, packageId?: number) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<GlobalDashboardResponse>(
    ['reporting', clientId, packageId],
    () => getReportingOverview(clientId, packageId),
    { refetchOnWindowFocus: false },
  );
  return { data, isLoading, error, refetch, isFetching };
};

export const useReportingWithDateSearch = (
  startDate: string,
  endDate: string,
  clientId?: number,
  packageId?: number,
) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<GlobalDashboardResponse>(
    ['reporting', 'reportingWithDates', startDate, endDate, clientId, packageId],
    () => getReportingWithDateSearch(startDate, endDate, clientId, packageId),
    { refetchOnWindowFocus: false },
  );
  return { data, isLoading, error, refetch, isFetching };
};

const getInvoiceReport = async (startDate: string, endDate: string): Promise<Blob> => {
  const response = await axiosGet('/reporting/invoice_report', { start_date: startDate, end_date: endDate }, 'v3');
  return response.data;
};

export const useInvoiceReport = (startDate: string, endDate: string, enabled: boolean) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<Blob>(
    ['reporting', 'invoiceReport', startDate, endDate],
    () => getInvoiceReport(startDate, endDate),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const today = new Date();
        const todayString = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        // Create a download link
        var downloadLink = document.createElement('a');
        var blob = new Blob(['\ufeff', data]);
        var url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `invoice_report_from_${todayString}_for_${startDate}_to_${endDate}.csv`;
        // Append the link to the body, click it, and then remove it
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        toast.success(`File ${downloadLink.download} downloaded successfully.`);
      },
    },
  );
  return { data, isLoading, error, refetch, isFetching };
};

export const useAudioCount = () => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<AudioCountResponse>(
    ['reporting'],
    () =>
      getListenerAudioCount({
        grade: 'approved',
        include_disabled: 'false',
      }),
    { refetchOnWindowFocus: false },
  );
  return { data, isLoading, error, refetch, isFetching };
};
